import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { ResultObj } from "../../types/remote";

import { changeLoadScreen } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { Kampagne } from "../../types/remote/Kampagne";
import { updateResultCode } from "../content";
import { Firma } from "../../types/remote/Firma";
import { JobStatus } from "../../types/remote/JobStatus";
import { Emailzugang } from "../../types/remote/Emailzugang";
import { Emailvorlage } from "../../types/remote/Emailvorlage";

export interface LoadKampagneAction {
  type: Actions.CONTENT_LOAD_CAMPAGNE;
  data: Kampagne[] | undefined;
}

export const loadKampagne = (fi: Firma) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Kampagne[]>>(`${config.backendUrl}/v1/kampagne/firma/${fi.FID}`);
      dispatch(changeLoadScreen(false));
      dispatch<LoadKampagneAction>({
        data: response.data.data,
        type: Actions.CONTENT_LOAD_CAMPAGNE,
      });
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const mutateKampagne = (k: Kampagne) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response =
        k.KAPID === -1
          ? await mainaxios.post<ResultObj<Kampagne>>(config.backendUrl + "/v1/kampagne", k)
          : await mainaxios.put<ResultObj<Kampagne>>(config.backendUrl + "/v1/kampagne", k);
      dispatch(changeLoadScreen(false));

      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(-1));
      resolveFailure(dispatch, exx);
    }
  };
};

export const changeJobStatus = (k: Kampagne, js: JobStatus) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<Kampagne>>(config.backendUrl + "/v1/kampagne/status", { KAPID: k.KAPID, status: js });
      dispatch(changeLoadScreen(false));

      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(-1));
      resolveFailure(dispatch, exx);
    }
  };
};

export const sendTestmail = (emailzugang: Emailzugang, emailaddress: string, emailvorlage: Emailvorlage) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<string>>(`${config.backendUrl}/v1/kampagne/sendTestMail`, {
        receipient: emailaddress,
        emailzugang: emailzugang,
        emailvorlage: emailvorlage,
      });
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
      dispatch(updateResultCode(-1));
    }
  };
};
