import { Actions } from "..";
import { Page } from "../../types/local/pages";
import { MessageData } from "../../types/local/messageData";

export interface RouteAction {
  type: Actions.ROUTE;
  data: Page;
}

export interface LoadscreenAction {
  type: Actions.CHANGE_LOAD_SCREEN;
  data: boolean;
}

export interface ShowMessageAction {
  type: Actions.SHOW_MESSAGE;
  data: MessageData | undefined;
}

export const changePage = (page: Page): RouteAction => {
  window.scrollTo(0, 0);
  return { type: Actions.ROUTE, data: page };
};

export const changeLoadScreen = (showVal: boolean): LoadscreenAction => {
  return { type: Actions.CHANGE_LOAD_SCREEN, data: showVal };
};

export const showMessage = (message: MessageData): ShowMessageAction => {
  return { type: Actions.SHOW_MESSAGE, data: message };
};
