import { DropdownValue } from "../types/local/DropdownValue";
import datetime from "date-and-time";
import { Dataset } from "../types/remote";
import { Faxstatus } from "../types/remote/Faxstatus";
import { JobStatus } from "../types/remote/JobStatus";
import { Emailzugang } from "../types/remote/Emailzugang";

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Helper
const StringIsNumber = (value: any) => isNaN(Number(value)) === false;

// Turn enum into array
export function ToArray(enumme: any): DropdownValue[] {
  try {
    let ddVal: DropdownValue[] = [];
    Object.keys(enumme)
      .filter(StringIsNumber)
      .map((key) => {
        ddVal.push({ value: parseInt(key), label: enumme[key] });
        return enumme[key];
      });
    return ddVal;
  } catch (ex) {
    return [];
  }
}

export function getStatusName(job: JobStatus): string {
  return JobStatus[job];
}

export function parseDate(date: Date, pattern: string = "YYYY/MM/DD HH:mm:ss") {
  return datetime.format(date, "YYYY/MM/DD HH:mm:ss");
}

export function getEmptyDataset(): Dataset {
  return {
    KID: 0,
    Email: "",
    Firmenname: "",
    linkid: "",
    Fax: "",
    Ort: "",
    Tel: "",
    Anrede: undefined,
    Vorname: "",
    Nachname: "",
    strasse: "",
    plz: "",
    land: undefined,
    Internet: "",
    Bemerkung: "",
    Faxstatus: Faxstatus.AKTIV,
  };
}

export function isPop3Active(emailzugang: Emailzugang): boolean {
  if (emailzugang.Pophost && emailzugang.Popport && emailzugang.Popport > 0 && emailzugang.Poplogin && emailzugang.Poppassword) return true;
  return false;
}
