import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { uploadImport, updateResultCode } from "../../../actions";
import { Button } from "primereact/button";
import { Firma } from "../../../types/remote/Firma";

interface ImportDialogProps {
  open: boolean;
  close: Function;
  content: ContentState;
  updateResultCode: typeof updateResultCode;
  uploadImport: Function;
}

interface ImportDialogState {
  changeExisting: boolean;
  filepath: string;
}

export class _ImportDialog extends React.Component<ImportDialogProps, ImportDialogState> {
  constructor(props: ImportDialogProps) {
    super(props);

    this.state = { changeExisting: false, filepath: "" };
  }

  componentDidUpdate(prevProps: ImportDialogProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ changeExisting: false, filepath: "" });
    }
  }

  isValid() {
    if (this.state.filepath.length > 0) {
      return true;
    }
    return false;
  }

  uploadForm(company?: Firma) {
    var formData = new FormData();
    var imagefile: any = document.querySelector("#filename");
    if (imagefile) formData.append("import", imagefile.files[0]);
    //formData.append("firma", `{"FID":${company.FID}}`);
    formData.append("changeExisting", this.state.changeExisting ? "true" : "false");
    if (company) formData.append("FID", company.FID.toString());
    this.props.uploadImport(formData);
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />
              <Button
                disabled={!this.isValid()}
                label="Hochladen"
                icon="pi pi-check"
                type="submit"
                onClick={() => {
                  this.uploadForm(this.props.content.selectedCompany);

                  this.props.close();
                }}
              />
            </div>
          }
          header="Neuer Datenimport"
          visible={this.props.open}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-6 labelcenter">
                Bestehende Datensätze ändern?
              </label>

              <div className="field col-12 md:col-6">
                <InputSwitch
                  checked={this.state.changeExisting}
                  onChange={(e: any) => this.setState({ ...this.state, changeExisting: e.value })}
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="filename" className="field col-12 md:col-2 labelcenter">
                CSV-Datei
              </label>
              <div className="field col-12 md:col-10">
                <input
                  className={`${this.state.filepath.length <= 1 ? "p-invalid" : ""} w-full`}
                  onChange={(e) => this.setState({ ...this.state, filepath: e.target.value })}
                  value={this.state.filepath}
                  id="filename"
                  type="file"
                  name="template"
                  accept=".csv"
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const ImportDialog = connect(mapStateToProps, { uploadImport, updateResultCode })(_ImportDialog);
