import React from "react";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { addVorgang, changeVorgang, setSelectedCompany } from "../../../actions";
import { Button } from "primereact/button";
import { Vorgang } from "../../../types/remote/Vorgang";
import { generateVorgang } from "../../../helper/vorgangHelper";
import { CTextarea } from "../../parts/CustomTextarea";
import { Dropdown } from "primereact/dropdown";

interface VorgangDialogProps {
  vorgang?: Vorgang;
  close: Function;
  content: ContentState;
  addVorgang: Function;
  changeVorgang: Function;
  setSelectedCompany: typeof setSelectedCompany;
}

interface VorgangDialogState {
  vorgang: Vorgang;
}

export class _VorgangDialog extends React.Component<VorgangDialogProps, VorgangDialogState> {
  constructor(props: VorgangDialogProps) {
    super(props);

    this.state = { vorgang: generateVorgang() };
  }

  componentDidUpdate(prevProps: VorgangDialogProps) {
    //On Open
    if (!prevProps.vorgang && this.props.vorgang) {
      //Copy Object
      this.setState({ vorgang: { ...this.props.vorgang } });
    }

    //Close only if everything works fine :)
    if (prevProps.content.lastResultCode !== this.props.content.lastResultCode && this.props.content.lastResultCode.rc === 0) {
      this.props.close();
    }

    //Reset on close
    if (prevProps.vorgang && !this.props.vorgang) {
      this.setState({ vorgang: generateVorgang() });
    }
  }

  isValid() {
    if (this.state.vorgang.Text.length > 0 && this.props.content.selectedCompany) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />
              <Button
                disabled={!this.isValid()}
                label={this.state.vorgang.VID === -1 ? "Anlegen" : "Ändern"}
                icon="pi pi-check"
                onClick={async () => {
                  if (this.state.vorgang.VID === -1) {
                    const v: Vorgang = { ...this.state.vorgang, FID: this.props.content.selectedCompany };
                    await this.props.addVorgang(this.props.content.dataset, v);
                  } else {
                    const vorgang = generateVorgang();
                    vorgang.VID = this.state.vorgang.VID;
                    vorgang.Text = this.state.vorgang.Text;
                    vorgang.vorgangstyp = this.state.vorgang.vorgangstyp;
                    vorgang.FID = this.state.vorgang.FID;

                    await this.props.changeVorgang(vorgang);
                  }
                  this.props.close();
                }}
              />
            </div>
          }
          header={this.state.vorgang.VID === -1 ? "Vorgang anlegen" : `Vorgang ändern`}
          visible={this.props.vorgang !== undefined}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                Firma
              </label>

              <div className="field col-12 md:col-7">
                <Dropdown
                  value={this.props.content.selectedCompany}
                  onChange={(e) => {
                    this.props.setSelectedCompany(e.value);
                  }}
                  options={this.props.content.firmen}
                  optionLabel="Kurzform"
                  placeholder="Firma auswählen"
                  className="w-full md:w-14rem"
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                Vorgang Text
              </label>

              <div className="field col-12 md:col-7">
                <CTextarea
                  maxLength={300}
                  rows={5}
                  cols={30}
                  showLine={true}
                  value={this.state.vorgang.Text}
                  disabled={false}
                  onChange={(e: any) => {
                    this.setState({ ...this.state, vorgang: { ...this.state.vorgang, Text: e } });
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const VorgangDialog = connect(mapStateToProps, { addVorgang, changeVorgang, setSelectedCompany })(_VorgangDialog);
