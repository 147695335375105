import React from "react";

import { InputTextarea } from "primereact/inputtextarea";

interface CTextareaProps {
  onChange: Function;
  rows: number;
  cols: number;
  value?: string;
  maxLength: number;
  disabled: boolean;
  showLine?: boolean;
}

interface CTextareaState {
  len: number;
}

export class CTextarea extends React.Component<CTextareaProps, CTextareaState> {
  constructor(props: CTextareaProps) {
    super(props);
    this.state = { len: this.props.value ? this.props.value.length : 0 };
  }

  render() {
    return (
      <div>
        <InputTextarea
          rows={this.props.rows}
          cols={30}
          disabled={this.props.disabled}
          value={this.props.value}
          onChange={(e: any) => {
            if (this.props.maxLength >= e.target.value.length) {
              //this.setState( {val: e.target.value });
              this.props.onChange(e.target.value);
            }
            this.setState({ len: this.props.value ? this.props.value.length : 0 });
          }}
        />
        {this.props.showLine ? (
          <p>{`Sie können noch ${this.props.maxLength - this.state.len} Zeichen eingeben.`}</p>
        ) : null}
      </div>
    );
  }
}
