export const kampagne = [
  {
    KAPID: 7,
    finished: "1613029376533",
    delayBetweenMailsInSec: 30,
    amountOfMailsInOneRun: 8,
    emailvorlagen: [
      {
        EVID: 23,
        filepath: "files/emailvorlagen/6NFQ6CHFP6K4.eml",
        Bezeichnung: "Rundschreibenvorlage 1",
        Created: "0",
        Creator: 0,
        FID: 4,
      },
      {
        EVID: 24,
        filepath: "files/emailvorlagen/JN6TD1NU0E81.eml",
        Bezeichnung: "Rundschreibenvorlage 2",
        Created: "0",
        Creator: 0,
        FID: 4,
      },
    ],
    firma: {
      FID: 4,
      Anzeigename: "info@mh-schleswig.de",
      Kurzform: "MHS_Hamburg",
      UID: 1,
      Created: "0",
      abmeldelink: "http://unsubscribe.maschinenhandel-schleswig-holstein.de/<LINKID>",
      emailzugaenge: [
        {
          Email: "info@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "info@maschinenhandel-schleswig-holstein.de",
          Password: "MA4xh);_\\Lyr",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "news@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "news@maschinenhandel-schleswig-holstein.de",
          Password: "2mwy8ttN$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl1@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "nl1@maschinenhandel-schleswig-holstein.de",
          Password: "PjawZ$M2$$$22",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "nl@maschinenhandel-schleswig-holstein.de",
          Password: "9#*hXbco",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "ps@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "ps@maschinenhandel-schleswig-holstein.de",
          Password: "YX53wKTs111$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "sammler@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "sammler@maschinenhandel-schleswig-holstein.de",
          Password: "<3{F$(-SwqfZ",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
      ],
    },
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694775648886",
    },
    created: "1612869438555",
    name: "Erste MHS Kampagne",
    status: 5,
    lastKID: 88303,
  },
  {
    KAPID: 6,
    finished: "1613029376533",
    delayBetweenMailsInSec: 30,
    amountOfMailsInOneRun: 8,
    emailvorlagen: [
      {
        EVID: 23,
        filepath: "files/emailvorlagen/6NFQ6CHFP6K4.eml",
        Bezeichnung: "Rundschreibenvorlage 1",
        Created: "0",
        Creator: 0,
        FID: 4,
      },
      {
        EVID: 24,
        filepath: "files/emailvorlagen/JN6TD1NU0E81.eml",
        Bezeichnung: "Rundschreibenvorlage 2",
        Created: "0",
        Creator: 0,
        FID: 4,
      },
    ],
    firma: {
      FID: 4,
      Anzeigename: "info@mh-schleswig.de",
      Kurzform: "MHS_Hamburg",
      UID: 1,
      Created: "0",
      abmeldelink: "http://unsubscribe.maschinenhandel-schleswig-holstein.de/<LINKID>",
      emailzugaenge: [
        {
          Email: "info@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "info@maschinenhandel-schleswig-holstein.de",
          Password: "MA4xh);_\\Lyr",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "news@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "news@maschinenhandel-schleswig-holstein.de",
          Password: "2mwy8ttN$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl1@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "nl1@maschinenhandel-schleswig-holstein.de",
          Password: "PjawZ$M2$$$22",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "nl@maschinenhandel-schleswig-holstein.de",
          Password: "9#*hXbco",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "ps@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "ps@maschinenhandel-schleswig-holstein.de",
          Password: "YX53wKTs111$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "sammler@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "sammler@maschinenhandel-schleswig-holstein.de",
          Password: "<3{F$(-SwqfZ",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
      ],
    },
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694775648886",
    },
    created: "1612869438555",
    name: "1234",
    status: 0,
    lastKID: 88303,
  },
  {
    KAPID: 5,
    finished: "1613029376533",
    delayBetweenMailsInSec: 30,
    amountOfMailsInOneRun: 8,
    emailvorlagen: [
      {
        EVID: 23,
        filepath: "files/emailvorlagen/6NFQ6CHFP6K4.eml",
        Bezeichnung: "Rundschreibenvorlage 1",
        Created: "0",
        Creator: 0,
        FID: 4,
      },
      {
        EVID: 24,
        filepath: "files/emailvorlagen/JN6TD1NU0E81.eml",
        Bezeichnung: "Rundschreibenvorlage 2",
        Created: "0",
        Creator: 0,
        FID: 4,
      },
    ],
    firma: {
      FID: 4,
      Anzeigename: "info@mh-schleswig.de",
      Kurzform: "MHS_Hamburg",
      UID: 1,
      Created: "0",
      abmeldelink: "http://unsubscribe.maschinenhandel-schleswig-holstein.de/<LINKID>",
      emailzugaenge: [
        {
          Email: "info@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "info@maschinenhandel-schleswig-holstein.de",
          Password: "MA4xh);_\\Lyr",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "news@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "news@maschinenhandel-schleswig-holstein.de",
          Password: "2mwy8ttN$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl1@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "nl1@maschinenhandel-schleswig-holstein.de",
          Password: "PjawZ$M2$$$22",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "nl@maschinenhandel-schleswig-holstein.de",
          Password: "9#*hXbco",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "ps@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "ps@maschinenhandel-schleswig-holstein.de",
          Password: "YX53wKTs111$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "sammler@maschinenhandel-schleswig-holstein.de",
          FID: 4,
          Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
          Loginname: "sammler@maschinenhandel-schleswig-holstein.de",
          Password: "<3{F$(-SwqfZ",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
          Pophost: "",
          Popport: 0,
        },
      ],
    },
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694775648886",
    },
    created: "1612869438555",
    name: "hzhth MHS Kampagne",
    status: 1,
    lastKID: 88303,
  },
  {
    KAPID: 8,
    finished: "1613758037475",
    delayBetweenMailsInSec: 30,
    amountOfMailsInOneRun: 5,
    emailvorlagen: [
      {
        EVID: 25,
        filepath: "files/emailvorlagen/VLJWN8GAGH7E.eml",
        Bezeichnung: "Rundschreibenvorlage 1",
        Created: "0",
        Creator: 0,
        FID: 8,
      },
      {
        EVID: 27,
        filepath: "files/emailvorlagen/TDD16DQRW57Y.eml",
        Bezeichnung: "Rundschreibenvorlage 3",
        Created: "0",
        Creator: 0,
        FID: 8,
      },
    ],
    firma: {
      FID: 8,
      Anzeigename: "info@mhs-group-germany.com",
      Kurzform: "MHS_Group_Germany",
      UID: 587,
      Created: "0",
      abmeldelink: "http://unsubscribe.mhs-group-germany.de/<LINKID>",
      emailzugaenge: [
        {
          Email: "ankauf@mhs-group-germany.de",
          FID: 8,
          Hostadresse: "smtp.ionos.de",
          Loginname: "ankauf@mhs-group-germany.de",
          Password: "TuI2*TDx$$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "news@mhs-group-germany.de",
          FID: 8,
          Hostadresse: "smtp.ionos.de",
          Loginname: "news@mhs-group-germany.de",
          Password: "Jf%DDm1p$$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl1@mhs-group-germany.de",
          FID: 8,
          Hostadresse: "smtp.ionos.de",
          Loginname: "nl1@mhs-group-germany.de",
          Password: "iP*Smx3L$$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl2@mhs-group-germany.de",
          FID: 8,
          Hostadresse: "smtp.ionos.de",
          Loginname: "nl2@mhs-group-germany.de",
          Password: "KSJ51Qp1$$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "nl@mhs-group-germany.de",
          FID: 8,
          Hostadresse: "smtp.ionos.de",
          Loginname: "nl@mhs-group-germany.de",
          Password: "9K!6vGl!aa",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
          Pophost: "",
          Popport: 0,
        },
        {
          Email: "ps@mhs-group-germany.de",
          FID: 8,
          Hostadresse: "smtp.ionos.de",
          Loginname: "ps@mhs-group-germany.de",
          Password: "IZLLbT8f$$$",
          Port: 465,
          Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
          Pophost: "",
          Popport: 0,
        },
      ],
    },
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694775648886",
    },
    created: "1613511721317",
    name: "Zweite MHS Kampagne",
    status: 5,
    lastKID: 88304,
  },
];
