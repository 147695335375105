import { Dialog } from "primereact/dialog";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { updateFirma, updateResultCode, addFirma } from "../../../actions";
import { Button } from "primereact/button";
import { Firma } from "../../../types/remote/Firma";
import { generateCompany } from "../../../helper/firmenHelper";
import { InputText } from "primereact/inputtext";
import { checkEmail, checkInternet } from "../../../helper/validate";
import { ValidEnum } from "../../../types/local/ValidEnum";

interface FirmenDialogProps {
  firma?: Firma;
  close: Function;
  content: ContentState;
  updateResultCode: typeof updateResultCode;
  updateFirma: Function;
  addFirma: Function;
}

interface FirmenDialogState {
  firma: Firma;
}

export class _FirmenDialog extends React.Component<FirmenDialogProps, FirmenDialogState> {
  constructor(props: FirmenDialogProps) {
    super(props);

    this.state = { firma: generateCompany() };
  }

  componentDidUpdate(prevProps: FirmenDialogProps) {
    if (!prevProps.firma && this.props.firma) {
      this.setState({ ...this.state, firma: { ...this.props.firma } });
    }
  }

  isValid() {
    if (
      this.state.firma.Anzeigename.length > 0 &&
      this.state.firma.Kurzform.length > 0 &&
      (checkInternet(this.state.firma.abmeldelink) === ValidEnum.VALID ||
        checkInternet(this.state.firma.abmeldelink) === ValidEnum.NOT_SET) &&
      (checkEmail(this.state.firma.bounceaddress) === ValidEnum.VALID ||
        checkEmail(this.state.firma.bounceaddress) === ValidEnum.NOT_SET) &&
      (checkEmail(this.state.firma.replytoadddress) === ValidEnum.VALID ||
        checkEmail(this.state.firma.replytoadddress) === ValidEnum.NOT_SET)
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />

              <Button
                disabled={!this.isValid()}
                label="Speichern"
                icon="pi pi-check"
                type="submit"
                onClick={() => {
                  if (this.state.firma.FID >= 0) {
                    this.props.updateFirma(this.state.firma);
                  } else {
                    this.props.addFirma(this.state.firma);
                  }
                  this.props.close();
                }}
              />
            </div>
          }
          header={`Firma ${this.props.firma && this.props.firma.FID >= 0 ? "ändern" : "anlegen"}`}
          visible={typeof this.props.firma !== "undefined"}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Firmenname
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.firma.Anzeigename}
                  maxLength={50}
                  onChange={(e: any) => {
                    const temp = this.state.firma;
                    temp.Anzeigename = e.target.value;
                    this.setState({ ...this.state, firma: temp });
                  }}
                  className={`${this.state.firma.Anzeigename.length <= 0 ? "p-invalid" : ""}  w-full`}
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Kurzform
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.firma.Kurzform}
                  maxLength={10}
                  onChange={(e: any) => {
                    const temp = this.state.firma;
                    temp.Kurzform = e.target.value;
                    this.setState({ ...this.state, firma: temp });
                  }}
                  className={`${this.state.firma.Kurzform.length <= 0 ? "p-invalid" : ""} w-full`}
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Abmeldelink
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.firma.abmeldelink}
                  maxLength={100}
                  onChange={(e: any) => {
                    const temp = this.state.firma;
                    temp.abmeldelink = e.target.value;
                    this.setState({ ...this.state, firma: temp });
                  }}
                  className={`${checkInternet(this.state.firma.abmeldelink) === ValidEnum.INVALID ? "p-invalid" : ""} w-full`}
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Bounce-Email
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.firma.bounceaddress}
                  maxLength={100}
                  onChange={(e: any) => {
                    const temp = this.state.firma;
                    temp.bounceaddress = e.target.value;
                    this.setState({ ...this.state, firma: temp });
                  }}
                  className={`${checkEmail(this.state.firma.bounceaddress) === ValidEnum.INVALID ? "p-invalid" : ""} w-full`}
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                ReplyTo-Email
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.firma.replytoadddress}
                  maxLength={100}
                  onChange={(e: any) => {
                    const temp = this.state.firma;
                    temp.replytoadddress = e.target.value;
                    this.setState({ ...this.state, firma: temp });
                  }}
                  className={`${checkEmail(this.state.firma.replytoadddress) === ValidEnum.INVALID ? "p-invalid" : ""} w-full`}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const FirmenDialog = connect(mapStateToProps, { updateFirma, updateResultCode, addFirma })(_FirmenDialog);
