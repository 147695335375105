export const Laenderliste = [
  "	Deutschland",
  "	Österreich",
  "	Schweiz",
  "	Afghanistan",
  "	Ägypten",
  "	Åland",
  "	Albanien",
  "	Algerien",
  "	Amerikanische Jungferninseln",
  "	Amerikanisch-Samoa",
  "	Andorra",
  "	Angola",
  "	Anguilla",
  "	Antarktika",
  "	Antigua und Barbuda",
  "	Äquatorialguinea",
  "	Argentinien",
  "	Armenien",
  "	Aruba",
  "	Aserbaidschan",
  "	Äthiopien",
  "	Australien",
  "	Bahamas",
  "	Bahrain",
  "	Bangladesch",
  "	Barbados",
  "	Bassas da India",
  "	Belarus",
  "	Belgien",
  "	Belize",
  "	Benin",
  "	Bermuda",
  "	Bhutan",
  "	Bolivien",
  "	Bosnien und Herzegowina",
  "	Botsuana",
  "	Bouvetinsel",
  "	Brasilien",
  "	Britische Jungferninseln",
  "	Britisches Territorium im Indischen Ozean",
  "	Brunei Darussalam",
  "	Bulgarien",
  "	Burkina Faso",
  "	Burundi",
  "	Cabo Verde",
  "	Chile",
  "	China",
  "	Clipperton",
  "	Cookinseln",
  "	Costa Rica",
  "	Côte dIvore",
  "	Dänemark",
  "	Dominica",
  "	Dominikanische Republik",
  "	Dschibuti",
  "	Ecuador",
  "	El Salvador",
  "	Eritrea",
  "	Estland",
  "	Eswatini",
  "	Europa",
  "	Falklandinseln",
  "	Färöer",
  "	Fidschi",
  "	Finnland",
  "	Frankreich",
  "	Frankreich (metropolitanes)",
  "	Französische Süd- und Antarktisgebiete",
  "	Französisch-Guayana",
  "	Französisch-Polynesien",
  "	Gabun",
  "	Gambia",
  "	Gazastreifen",
  "	Georgien",
  "	Ghana",
  "	Gibraltar",
  "	Glorieuses",
  "	Grenada",
  "	Griechenland",
  "	Grönland",
  "	Großbritannien",
  "	Guadeloupe",
  "	Guam",
  "	Guatemala",
  "	Guernsey",
  "	Guinea",
  "	Guinea-Bissau",
  "	Guyana",
  "	Haiti",
  "	Heard und McDonaldinseln",
  "	Honduras",
  "	Hongkong",
  "	Indien",
  "	Indonesien",
  "	Insel Man",
  "	Irak",
  "	Iran",
  "	Irland",
  "	Island",
  "	Israel",
  "	Italien",
  "	Jamaika",
  "	Japan",
  "	Jemen",
  "	Jersey",
  "	Jordanien",
  "	Juan de Nova",
  "	Kaimaninseln",
  "	Kambodscha",
  "	Kamerun",
  "	Kanada",
  "	Kasachstan",
  "	Katar",
  "	Kenia",
  "	Kirgisistan",
  "	Kiribati",
  "	Kleinere Amerikanische Überseeinseln",
  "	Kokosinseln (Keelinginseln)",
  "	Kolumbien",
  "	Komoren",
  "	Kongo",
  "	Kongo",
  "	Korea",
  "	Korea",
  "	Kroatien",
  "	Kuba",
  "	Kuwait",
  "	Laos",
  "	Lesotho",
  "	Lettland",
  "	Libanon",
  "	Liberia",
  "	Libyen",
  "	Liechtenstein",
  "	Litauen",
  "	Luxemburg",
  "	Macau",
  "	Madagaskar",
  "	Malawi",
  "	Malaysia",
  "	Malediven",
  "	Mali",
  "	Malta",
  "	Marokko",
  "	Marshallinseln",
  "	Martinique",
  "	Mauretanien",
  "	Mauritius",
  "	Mayotte",
  "	Mexiko",
  "	Mikronesien",
  "	Moldau",
  "	Monaco",
  "	Mongolei",
  "	Montenegro",
  "	Montserrat",
  "	Mosambik",
  "	Myanmar",
  "	Namibia",
  "	Nauru",
  "	Nepal",
  "	Neukaledonien",
  "	Neuseeland",
  "	Nicaragua",
  "	Niederlande",
  "	Niederländische Antillen",
  "	Niger",
  "	Nigeria",
  "	Niue",
  "	Nördliche Marianen",
  "	Nordmazedonien",
  "	Norfolkinsel",
  "	Norwegen",
  "	Oman",
  "	Pakistan",
  "	Palau",
  "	Panama",
  "	Papua-Neuguinea",
  "	Paraguay",
  "	Peru",
  "	Philippinen",
  "	Pitcairninseln",
  "	Polen",
  "	Portugal",
  "	Puerto Rico",
  "	Réunion",
  "	Ruanda",
  "	Rumänien",
  "	Russische Föderation",
  "	Saint-Martin",
  "	Salomonen",
  "	Sambia",
  "	Samoa",
  "	San Marino",
  "	São Tomé und Príncipe",
  "	Saudi-Arabien",
  "	Schweden",
  "	Senegal",
  "	Serbien",
  "	Serbien und Montenegro",
  "	Seychellen",
  "	Sierra Leone",
  "	Simbabwe",
  "	Singapur",
  "	Slowakei",
  "	Slowenien",
  "	Somalia",
  "	Spanien",
  "	Spitzbergen",
  "	Sri Lanka",
  "	St. Barthélemy",
  "	St. Helena",
  "	St. Kitts und Nevis",
  "	St. Lucia",
  "	St. Pierre und Miquelon",
  "	St. Vincent und die Grenadinen",
  "	Südafrika",
  "	Sudan",
  "	Südgeorgien und die Südlichen Sandwichinseln",
  "	Südsudan",
  "	Suriname",
  "	Syrien",
  "	Tadschikistan",
  "	Taiwan",
  "	Tansania",
  "	Thailand",
  "	Timor-Leste",
  "	Togo",
  "	Tokelau",
  "	Tonga",
  "	Trinidad und Tobago",
  "	Tromelin",
  "	Tschad",
  "	Tschechische Republik",
  "	Tunesien",
  "	Türkei",
  "	Turkmenistan",
  "	Turks- und Caicosinseln",
  "	Tuvalu",
  "	Uganda",
  "	Ukraine",
  "	Ungarn",
  "	Uruguay",
  "	Usbekistan",
  "	Vanuatu",
  "	Vatikanstadt",
  "	Venezuela",
  "	Vereinigte Arabische Emirate",
  "	Vereinigte Staaten",
  "	Vietnam",
  "	Wallis und Futuna",
  "	Weihnachtsinsel",
  "	Westjordanland",
  "	Westsahara",
  "	Zentralafrikanische Republik",
  "	Zypern",
];
