export interface MessageData {
  severity: MessageType;
  summary: string;
  detail: string;
}

export enum MessageType {
  SUCCESS = "success",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}
