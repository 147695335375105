import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadKampagne, changeJobStatus } from "../../../actions";
import { KampagneState } from "../../../reducers/kampagne/kampReducer";
import { Kampagne } from "../../../types/remote/Kampagne";
import { JobStatus } from "../../../types/remote/JobStatus";
import { Emailvorlage } from "../../../types/remote/Emailvorlage";
import { KampagneDialog } from "../../parts/KampagneDialog/KampagneDialog";
import { createKampagne } from "../../../helper/kampagneHelper";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { ResultToast } from "../../parts/ResultToast/ResultToast";
import { getStatusName } from "../../../helper/helper";
import { ProgressBar } from "primereact/progressbar";
import datetime from "date-and-time";
import { getName } from "../../../helper/benutzerHelper";

interface KampagneUebersichtProps {
  content: ContentState;
  kampagne: KampagneState;
  loadKampagne: Function;
  changeJobStatus: Function;
}

interface KampagneUebersichtState {
  selectedKampagne?: Kampagne;
}

class _KampagneUebersicht extends React.Component<KampagneUebersichtProps, KampagneUebersichtState> {
  constructor(props: KampagneUebersichtProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.content.selectedCompany) {
      this.props.loadKampagne(this.props.content.selectedCompany);
    }
  }

  getRundschreiben(evs: Emailvorlage[]) {
    const ev: any = [];
    evs.forEach((e) => {
      ev.push(<p key={e.EVID}>{e.Bezeichnung}</p>);
    });
    return ev;
  }

  //<Column style={{ width: "15%" }} field='action' header=''></Column>
  render() {
    return (
      <div className="card">
        <ResultToast successAction={() => this.props.loadKampagne(this.props.content.selectedCompany)} />
        <KampagneDialog
          kampagne={this.state.selectedKampagne}
          close={() => this.setState({ ...this.state, selectedKampagne: undefined })}
        />
        <HeaderMenu
          changeCompanyAction={() => this.props.loadKampagne(this.props.content.selectedCompany)}
          action={() => this.setState({ ...this.state, selectedKampagne: createKampagne(this.props.content.selectedCompany) })}
        />
        <DataTable
          sortField="KAPID"
          sortOrder={-1}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={
            this.props.kampagne && this.props.kampagne.kampagnen && this.props.kampagne.kampagnen.length > 0
              ? this.props.kampagne.kampagnen.map((element: Kampagne) => {
                  const temp: any = {};

                  //calculate progress
                  let progress = 0;
                  if (element.status === JobStatus.DONE) {
                    progress = 100;
                  } else if (element.statistic.sum > 0) {
                    progress = Math.round(((element.statistic.failed + element.statistic.successful) / element.statistic.sum) * 100);
                  }
                  temp.progress = <ProgressBar value={progress} />;

                  //check statistics
                  temp.statistic = (
                    <div>
                      <b>Erfolgreich gesendet: </b> {element.statistic.successful}
                      <br />
                      <b>Fehler beim Senden: </b> {element.statistic.failed}
                      <br />
                      <b>Summe an Empfänger: </b> {element.statistic.sum}
                    </div>
                  );

                  temp.created = element.created ? datetime.format(new Date(parseInt(element.created)), "DD.MM.YYYY HH:mm") : "unbekannt";
                  temp.finished =
                    element.finished && element.finished !== "0"
                      ? datetime.format(new Date(parseInt(element.finished)), "DD.MM.YYYY HH:mm")
                      : "unbekannt";

                  temp.info = (
                    <div>
                      <b>Name: </b> {element.name}
                      <br />
                      <b>Erstellt: </b> {temp.created}
                      <br />
                      <b>Fertig: </b> {temp.finished}
                      <br />
                      <b>Ersteller: </b> {getName(element.creator)}
                    </div>
                  );

                  return {
                    KAPID: element.KAPID,
                    info: temp.info,
                    emlen: this.getRundschreiben(element.emailvorlagen),
                    stats: temp.statistic,
                    progress: temp.progress,
                    st: getStatusName(element.status),
                    func: (
                      <div className="buttonSpacer">
                        {element.status === JobStatus.RUNNING ||
                        element.status === JobStatus.RUNNING_TEST ||
                        element.status === JobStatus.PAUSED ? (
                          <div className="p-1">
                            <Button
                              onClick={() => {
                                if (this.props.content.selectedCompany) this.props.loadKampagne(this.props.content.selectedCompany);
                              }}
                              tooltip="Refresh"
                              icon="pi pi-refresh"
                              className="p-button-rounded p-button-info"
                            />
                          </div>
                        ) : undefined}
                        {element.status === JobStatus.RUNNING || element.status === JobStatus.RUNNING_TEST ? (
                          <div className="p-1">
                            <Button
                              onClick={() => {
                                this.props.changeJobStatus(element, JobStatus.PAUSED);
                              }}
                              tooltip="Pause kampaign"
                              icon="pi pi-pause"
                              className="p-button-rounded p-button-warning"
                            />
                          </div>
                        ) : undefined}
                        {element.status === 0 || element.status === 2 ? (
                          <div className="p-1">
                            <Button
                              onClick={() => {
                                this.props.changeJobStatus(element, JobStatus.RUNNING);
                              }}
                              tooltip="Start kampaign"
                              icon="pi pi-play"
                              className="p-button-rounded p-button-warning"
                            />{" "}
                          </div>
                        ) : undefined}

                        {element.status === 0 || element.status === 2 ? (
                          <div className="p-1">
                            <Button
                              onClick={() => {
                                this.props.changeJobStatus(element, JobStatus.RUNNING_TEST);
                              }}
                              tooltip="Test only"
                              icon="pi pi-bolt"
                              className="p-button-rounded p-button-warning"
                            />{" "}
                          </div>
                        ) : undefined}
                        {element.status <= 3 ? (
                          <div className="p-1">
                            <Button
                              onClick={() => {
                                this.setState({ ...this.state, selectedKampagne: element });
                              }}
                              tooltip="Edit kampaign"
                              icon="pi pi-pencil"
                              className="p-button-rounded p-button-success "
                            />
                          </div>
                        ) : undefined}
                      </div>
                    ),
                  };
                })
              : []
          }
        >
          <Column style={{ width: "10%" }} field="KAPID" header="KAPID"></Column>
          <Column field="info" header="Info"></Column>
          <Column field="emlen" header="Emailvorlagen"></Column>
          <Column field="stats" header="Statistik"></Column>
          <Column field="progress" header="Fortschritt"></Column>
          <Column field="st" header="Status"></Column>
          <Column style={{ width: "100px" }} field="func" header="Aktionen"></Column>
        </DataTable>
        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content, kampagne }: StoreState): { content: ContentState; kampagne: KampagneState } => {
  return { content, kampagne };
};

export const KampagneUebersicht = connect(mapStateToProps, {
  loadKampagne,
  changeJobStatus,
})(_KampagneUebersicht);
