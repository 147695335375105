export const collections = [
  {
    cid: 15,
    name: "1und1",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 0,
    status: 1,
    counter: 112,
  },
  {
    cid: 2,
    name: "AlteListe (Fr. Krumnauer)",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 8777,
  },
  {
    cid: 3,
    name: "Emailsuche (Studenten)",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 22288,
  },
  {
    cid: 7,
    name: "Homepage-Einträge",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 14,
  },
  {
    cid: 13,
    name: "Maschinensucheranfragen",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 3757,
  },
  {
    cid: 8,
    name: "Metallbau-CD",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 3031,
  },
  {
    cid: 1,
    name: "Mittelstand",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 23432,
  },
  {
    cid: 6,
    name: "Österreich",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 2949,
  },
  {
    cid: 5,
    name: "Schweiz",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 3309,
  },
  {
    cid: 12,
    name: "Suche-Ina",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 588,
  },
  {
    cid: 11,
    name: "Suche-Ulrike",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 2720,
  },
  {
    cid: 34,
    name: "Suche_290822_TinaSchuengel",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1661795480580,
    status: 1,
    counter: 2108,
  },
  {
    cid: 29,
    name: "SUCHE_JANA_SUSANNE_YANIN",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1614669731937,
    status: 1,
    counter: 23165,
  },
  {
    cid: 27,
    name: "TestSET",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1612851615410,
    status: 1,
    counter: 4,
  },
  {
    cid: 4,
    name: "VDMA",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1610041250925,
    status: 1,
    counter: 1231,
  },
  {
    cid: 28,
    name: "Wissing",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1612873619795,
    status: 1,
    counter: 25573,
  },
  {
    cid: 33,
    name: "WLW_KUNSTSTOFFSPRITZEREI_CH_AT_YF",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1625758994035,
    status: 1,
    counter: 0,
  },
  {
    cid: 30,
    name: "WLW_Maschinenbau_CH_AT",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1625757346789,
    status: 1,
    counter: 0,
  },
  {
    cid: 31,
    name: "WLW_Maschinenbau_SF",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1625758534198,
    status: 1,
    counter: 0,
  },
  {
    cid: 32,
    name: "WLW_STAHL_ANLAGENBAU_YF",
    creator: {
      lid: 1,
      Vorname: "Ralph",
      Nachname: "Pohl",
      Email: "rpohl@mh-schleswig.de",
      admin: 1,
      creator: 0,
      created: "",
      lastlogin: "1694768720528",
    },
    created: 1625758830196,
    status: 1,
    counter: 0,
  },
];
