import React from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface ConfirmationDialogProps {
  header?: string;
  content: string;
  onClickYes: Function;
  onClickNo?: Function;
  yesCaption?: string;
  noCaption?: string;
  visible: boolean;
}

export class ConfirmationDialog extends React.Component<ConfirmationDialogProps> {
  renderFooter() {
    return (
      <div>
        <Button
          label={typeof this.props.yesCaption !== "undefined" ? this.props.yesCaption : "Ja"}
          icon="pi pi-check"
          onClick={() => {
            this.props.onClickYes();
          }}
          autoFocus
        />
        {typeof this.props.noCaption !== "undefined" ? (
          <Button
            label={this.props.noCaption}
            icon="pi pi-times"
            onClick={() => {
              if (typeof this.props.onClickNo !== "undefined") {
                this.props.onClickNo();
              }
            }}
            className="p-button-text"
          />
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <Dialog
        header={typeof this.props.header !== "undefined" ? this.props.header : "Information"}
        visible={this.props.visible}
        modal
        style={{ width: "50%" }}
        footer={this.renderFooter()}
        onHide={() => {
          if (typeof this.props.onClickNo !== "undefined") {
            this.props.onClickNo();
          } else {
            this.props.onClickYes();
          }
        }}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
          <span>{this.props.content}</span>
        </div>
      </Dialog>
    );
  }
}
