import React from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadImports, setSelectedCompany } from "../../../actions";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Firma } from "../../../types/remote/Firma";
import { Collection } from "../../../types/remote";
import { ListBox } from "primereact/listbox";
import { NLStatusEnum } from "../../../types/remote/NLStatus";
import { ToArray } from "../../../helper/helper";
import { startExport } from "../../../actions/content/exports";
import { ExportOptions } from "../../../types/remote/ExportOptions";

interface ExportProps {
  content: ContentState;
  loadImports: Function;
  setSelectedCompany: typeof setSelectedCompany;
  startExport: Function;
}

interface ExportState {
  selectedCompany?: Firma;
  selectedCollection?: Collection;
  selectedStatus: [];
}

class _Export extends React.Component<ExportProps, ExportState> {
  constructor(props: ExportProps) {
    super(props);
    this.state = { selectedStatus: [] };
  }

  componentDidMount() {
    this.setState({ selectedStatus: [] });
  }

  isValid() {
    if (this.state.selectedCompany && this.state.selectedStatus.length > 0) {
      return true;
    }

    return false;
  }

  async startExport() {
    if (this.state.selectedCompany && this.state.selectedStatus.length > 0) {
      const exportDescription: ExportOptions = {
        company: this.state.selectedCompany,
        status: this.state.selectedStatus,
        collection: this.state.selectedCollection,
      };
      try {
        await this.props.startExport(exportDescription);
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  render() {
    return (
      <div className="card">
        <HeaderMenu />
        <Fieldset legend="Export - Einstellungen">
          <p className="m-0">
            <b>Für welche Firma sollen die Daten exportiert werden?</b>
          </p>
          <br />
          <Dropdown
            value={this.state.selectedCompany}
            onChange={(e) => {
              this.setState({ ...this.state, selectedCompany: e.value });
            }}
            options={this.props.content.firmen}
            optionLabel="Kurzform"
            placeholder="Firma auswählen"
            className="w-full md:w-14rem"
          />
          <br />
          <br />
          <p className="m-0">
            <b>Welche Collection soll exportiert werden? (optional)</b>
          </p>
          <Dropdown
            value={this.state.selectedCollection}
            onChange={(e) => {
              this.setState({ ...this.state, selectedCollection: e.value });
            }}
            options={this.props.content.collections}
            optionLabel="name"
            placeholder="Collection auswählen"
            className="w-full md:w-14rem"
          />
          <br />
          <br />
          <p className="m-0">
            <b>Welche NLStatus sollen exportiert werden?</b>
          </p>
          <ListBox
            multiple
            value={this.state.selectedStatus}
            onChange={(e) => this.setState({ ...this.state, selectedStatus: e.value })}
            options={ToArray(NLStatusEnum)}
            className="w-full md:w-14rem"
          />
          <br />
          <br />
          <Button
            disabled={!this.isValid()}
            onClick={() => {
              this.startExport();
            }}
            label="Export starten"
            icon="pi pi-download"
            className="p-button-rounded p-button-info"
          />
        </Fieldset>
        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const Export = connect(mapStateToProps, {
  loadImports,
  setSelectedCompany,
  startExport,
})(_Export);
