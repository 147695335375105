import { Dialog } from "primereact/dialog";

import { InputText } from "primereact/inputtext";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { InputNumber } from "primereact/inputnumber";
import { addEmailzugang, updateEmailzugang } from "../../../actions";
import { Button } from "primereact/button";
import { Emailzugang } from "../../../types/remote/Emailzugang";
import { generateCompany, generateEmailzugang } from "../../../helper/firmenHelper";
import { checkEmail, checkHostName, checkTextLen } from "../../../helper/validate";
import { ValidEnum } from "../../../types/local/ValidEnum";
import { Password } from "primereact/password";
import { InputSwitch } from "primereact/inputswitch";
import { TabView, TabPanel } from "primereact/tabview";

interface EmailzugangDialogProps {
  emailzugang?: Emailzugang;
  close: Function;
  content: ContentState;
  addEmailzugang: Function;
  updateEmailzugang: Function;
}

interface EmailzugangDialogState {
  emailzugang: Emailzugang;
  weitererZugang: boolean;
}

export class _EmailzugangDialog extends React.Component<EmailzugangDialogProps, EmailzugangDialogState> {
  constructor(props: EmailzugangDialogProps) {
    super(props);

    this.state = { emailzugang: generateEmailzugang(generateCompany()), weitererZugang: false };
  }

  componentDidUpdate(prevProps: EmailzugangDialogProps) {
    //On Open
    if (!prevProps.emailzugang && this.props.emailzugang) {
      //Copy Object
      this.setState({ emailzugang: { ...this.props.emailzugang } });
    }

    //Close only if everything works fine :)
    if (prevProps.content.lastResultCode !== this.props.content.lastResultCode && this.props.content.lastResultCode.rc === 0) {
      //Close only, if not a next emailzugang should be create
      if (!this.state.weitererZugang) this.props.close();
      //
      else {
        this.setState({
          weitererZugang: false,
          emailzugang: { ...this.state.emailzugang, Password: "", Email: "", Loginname: "", initial: true, Poplogin: "", Poppassword: "" },
        });
      }
    }

    //Reset on close
    if (prevProps.emailzugang && !this.props.emailzugang) {
      this.setState({ emailzugang: generateEmailzugang(generateCompany()) });
    }
  }

  isValid() {
    if (
      this.state.emailzugang.Anzeigename.length > 0 &&
      checkHostName(this.state.emailzugang.Hostadresse) === ValidEnum.VALID &&
      checkTextLen(this.state.emailzugang.Loginname, 50) === ValidEnum.VALID &&
      checkTextLen(this.state.emailzugang.Password, 50) === ValidEnum.VALID &&
      checkEmail(this.state.emailzugang.Email) === ValidEnum.VALID &&
      checkHostName(this.state.emailzugang.Pophost) !== ValidEnum.INVALID &&
      checkTextLen(this.state.emailzugang.Pophost, 50) !== ValidEnum.INVALID &&
      checkTextLen(this.state.emailzugang.Poppassword, 50) !== ValidEnum.INVALID
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />
              <Button
                disabled={!this.isValid()}
                label={this.state.emailzugang.initial ? "Anlegen" : "Ändern"}
                icon="pi pi-check"
                onClick={() => {
                  if (this.state.emailzugang.initial) {
                    this.props.addEmailzugang(this.state.emailzugang);
                  } else {
                    this.props.updateEmailzugang(this.state.emailzugang);
                  }
                }}
              />
            </div>
          }
          header={this.state.emailzugang.initial ? "Emailzugang anlegen" : `Emailzuang ändern`}
          visible={this.props.emailzugang !== undefined}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                Emailadresse
              </label>

              <div className="field col-12 md:col-7">
                <InputText
                  value={this.state.emailzugang.Email}
                  type="text"
                  onChange={(e: any) => {
                    this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Email: e.target.value } });
                  }}
                  className={`${checkEmail(this.state.emailzugang.Email) !== ValidEnum.VALID ? "p-invalid" : ""} w-full`}
                  id="kampagne-name"
                  maxLength={50}
                  placeholder="Emailadresse"
                />
              </div>
            </div>
          </div>
          <TabView>
            <TabPanel header="SMTP">
              <div>
                <div className="field grid">
                  <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                    Anzeigename
                  </label>

                  <div className="field col-12 md:col-7">
                    <InputText
                      value={this.state.emailzugang.Anzeigename}
                      type="text"
                      onChange={(e: any) => {
                        this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Anzeigename: e.target.value } });
                      }}
                      className={`${checkTextLen(this.state.emailzugang.Anzeigename, 50) !== ValidEnum.VALID ? "p-invalid" : ""} w-full`}
                      id="kampagne-name"
                      maxLength={50}
                      placeholder="Anzeigename"
                    />
                  </div>
                </div>

                <div className="field grid">
                  <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                    Server
                  </label>

                  <div className="field col-12 md:col-7">
                    <InputText
                      value={this.state.emailzugang.Hostadresse}
                      type="text"
                      onChange={(e: any) => {
                        this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Hostadresse: e.target.value } });
                      }}
                      className={` ${checkHostName(this.state.emailzugang.Hostadresse) !== ValidEnum.VALID ? "p-invalid" : ""} w-full`}
                      id="kampagne-name"
                      maxLength={50}
                      placeholder="Server URL"
                    />
                  </div>
                </div>

                <div className="field grid">
                  <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                    Port
                  </label>

                  <div className="field col-12 md:col-7">
                    <InputNumber
                      value={this.state.emailzugang.Port}
                      onChange={(e: any) => {
                        this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Port: e.value } });
                      }}
                      mode="decimal"
                      className="w-full"
                      showButtons
                      min={1}
                      max={65500}
                    />
                  </div>
                </div>

                <div className="field grid">
                  <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                    Loginname
                  </label>

                  <div className="field col-12 md:col-7">
                    <InputText
                      value={this.state.emailzugang.Loginname}
                      type="text"
                      onChange={(e: any) => {
                        this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Loginname: e.target.value } });
                      }}
                      className={`${checkTextLen(this.state.emailzugang.Loginname, 50) !== ValidEnum.VALID ? "p-invalid" : ""} w-full`}
                      id="kampagne-name"
                      maxLength={50}
                      placeholder="Loginname"
                    />
                  </div>
                </div>

                <div className="field grid">
                  <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                    Password
                  </label>

                  <div className="field col-12 md:col-7">
                    <Password
                      value={this.state.emailzugang.Password}
                      maxLength={50}
                      toggleMask
                      feedback={false}
                      onChange={(e: any) => {
                        this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Password: e.target.value } });
                      }}
                      className={`${checkTextLen(this.state.emailzugang.Password, 50) !== ValidEnum.VALID ? "p-invalid" : ""}`}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="IMAP (opt.)">
              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Server
                </label>

                <div className="field col-12 md:col-7">
                  <InputText
                    value={this.state.emailzugang.Pophost ? this.state.emailzugang.Pophost : ""}
                    type="text"
                    onChange={(e: any) => {
                      this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Pophost: e.target.value } });
                    }}
                    className={` ${checkHostName(this.state.emailzugang.Pophost) === ValidEnum.INVALID ? "p-invalid" : ""} w-full`}
                    id="kampagne-name"
                    maxLength={50}
                    placeholder="Server URL"
                  />
                </div>
              </div>

              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Port
                </label>

                <div className="field col-12 md:col-7">
                  <InputNumber
                    value={this.state.emailzugang.Popport ? this.state.emailzugang.Popport : 110}
                    onChange={(e: any) => {
                      this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Popport: e.value } });
                    }}
                    mode="decimal"
                    className="w-full"
                    showButtons
                    min={1}
                    max={65500}
                  />
                </div>
              </div>

              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Loginname
                </label>

                <div className="field col-12 md:col-7">
                  <InputText
                    value={this.state.emailzugang.Poplogin ? this.state.emailzugang.Poplogin : ""}
                    type="text"
                    onChange={(e: any) => {
                      this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Poplogin: e.target.value } });
                    }}
                    className={`${checkTextLen(this.state.emailzugang.Poplogin, 50) === ValidEnum.INVALID ? "p-invalid" : ""} w-full`}
                    id="kampagne-name"
                    maxLength={50}
                    placeholder="Loginname"
                  />
                </div>
              </div>

              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Password
                </label>

                <div className="field col-12 md:col-7">
                  <Password
                    value={this.state.emailzugang.Poppassword ? this.state.emailzugang.Poppassword : ""}
                    maxLength={50}
                    toggleMask
                    feedback={false}
                    onChange={(e: any) => {
                      this.setState({ ...this.state, emailzugang: { ...this.state.emailzugang, Poppassword: e.target.value } });
                    }}
                    className={`${checkTextLen(this.state.emailzugang.Poppassword, 50) === ValidEnum.INVALID ? "p-invalid" : ""}`}
                  />
                </div>
              </div>
            </TabPanel>
          </TabView>

          {this.state.emailzugang.initial ? (
            <div className="field grid" style={{ marginTop: "15px" }}>
              <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                Weiteren Emailzugang hinzufügen?
              </label>

              <div className="field col-12 md:col-7">
                <InputSwitch checked={this.state.weitererZugang} onChange={(e) => this.setState({ ...this.state, weitererZugang: true })} />
              </div>
            </div>
          ) : undefined}
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const EmailzugangDialog = connect(mapStateToProps, { addEmailzugang, updateEmailzugang })(_EmailzugangDialog);
