import { Firma } from "../types/remote/Firma";
import { JobStatus } from "../types/remote/JobStatus";
import { Kampagne } from "../types/remote/Kampagne";

export function createKampagne(firma?: Firma): Kampagne {
  let fi: Firma | undefined = undefined;

  if (!firma) {
    fi = { Anzeigename: "", FID: -1, Kurzform: "", emailzugaenge: [] };
  } else {
    fi = firma;
  }

  return {
    KAPID: -1,
    firma: fi,
    created: new Date().getTime().toString(),
    emailvorlagen: [],
    lastKID: 0,
    amountOfMailsInOneRun: 30,
    delayBetweenMailsInSec: 60,
    status: JobStatus.ADDED,
    name: `Weitere Kampagne ${fi.Kurzform}`,
    statistic: { failed: 0, sum: 0, successful: 0 },
  };
}
