import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { addChangeUser, updateResultCode } from "../../../actions";
import { Button } from "primereact/button";
import { Benutzer } from "../../../types/remote";
import { generateEmptyBenutzer } from "../../../helper/benutzerHelper";
import { checkEmail } from "../../../helper/validate";
import { ValidEnum } from "../../../types/local/ValidEnum";
import { Password } from "primereact/password";
import { InputSwitch } from "primereact/inputswitch";

interface BenutzerDialogProps {
  benutzer?: Benutzer;
  close: Function;
  content: ContentState;
  updateResultCode: typeof updateResultCode;
  addChangeUser: Function;
}

interface BenutzerDialogState {
  benutzer: Benutzer;
}

export class _BenutzerDialog extends React.Component<BenutzerDialogProps, BenutzerDialogState> {
  constructor(props: BenutzerDialogProps) {
    super(props);

    this.state = { benutzer: generateEmptyBenutzer() };
  }

  isValid() {
    if (
      this.state.benutzer.Vorname.length > 0 &&
      this.state.benutzer.Nachname.length > 0 &&
      checkEmail(this.state.benutzer.Email) === ValidEnum.VALID &&
      (this.state.benutzer.password || this.state.benutzer.lid >= 0)
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: BenutzerDialogProps) {
    if (!prevProps.benutzer && this.props.benutzer) {
      this.setState({ ...this.state, benutzer: this.props.benutzer });
    }
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />

              <Button
                disabled={!this.isValid()}
                label="Speichern"
                icon="pi pi-check"
                type="submit"
                onClick={() => {
                  this.props.addChangeUser(this.state.benutzer);
                  this.props.close();
                }}
              />
            </div>
          }
          header={this.state.benutzer.lid > 0 ? "Benutzer ändern" : "Benutzer anlegen"}
          visible={typeof this.props.benutzer !== "undefined"}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Vorname
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.benutzer.Vorname}
                  type="text"
                  onChange={(e: any) => {
                    const temp = this.state.benutzer;
                    temp.Vorname = e.target.value;
                    this.setState({ ...this.state, benutzer: temp });
                  }}
                  className={`${this.state.benutzer.Vorname.length <= 1 ? "p-invalid" : ""} w-full`}
                  maxLength={50}
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Nachname
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.benutzer.Nachname}
                  type="text"
                  onChange={(e: any) => {
                    const temp = this.state.benutzer;
                    temp.Nachname = e.target.value;
                    this.setState({ ...this.state, benutzer: temp });
                  }}
                  className={`${this.state.benutzer.Nachname.length <= 1 ? "p-invalid" : ""} w-full`}
                  maxLength={50}
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Email
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.benutzer.Email}
                  type="text"
                  onChange={(e: any) => {
                    const temp = this.state.benutzer;
                    temp.Email = e.target.value;
                    this.setState({ ...this.state, benutzer: temp });
                  }}
                  className={`${checkEmail(this.state.benutzer.Email) !== ValidEnum.VALID ? "p-invalid" : ""} w-full`}
                  maxLength={50}
                />
              </div>
            </div>

            {this.state.benutzer.lid >= 0 ? (
              <div />
            ) : (
              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                  Passwort
                </label>

                <div className="field col-12 md:col-10">
                  <Password
                    value={this.state.benutzer.password}
                    maxLength={50}
                    onChange={(e: any) => {
                      const temp = this.state.benutzer;
                      temp.password = e.target.value;
                      this.setState({ ...this.state, benutzer: temp });
                    }}
                    className={`${!this.state.benutzer.password || this.state.benutzer.password.length <= 1 ? "p-invalid" : ""}`}
                    toggleMask
                  />
                </div>
              </div>
            )}

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Admin?
              </label>

              <div className="field col-12 md:col-10">
                <InputSwitch
                  checked={this.state.benutzer.admin === 1 ? true : false}
                  onChange={(e: any) => {
                    const temp = this.state.benutzer;
                    temp.admin = e.value ? 1 : 0;
                    this.setState({ ...this.state, benutzer: temp });
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const BenutzerDialog = connect(mapStateToProps, { addChangeUser, updateResultCode })(_BenutzerDialog);
