import React from "react";
import "./style.css";

export class LoadingDialog extends React.Component {
  render() {
    return (
      <div>
        <div className='loadingdialog-container'>
          <div className='lds-dual-ring'></div>
          <p className='loadingdialog-caption'>Bitte warten...</p>
        </div>
        <div className='loadingdialog-background'></div>
      </div>
    );
  }
}
