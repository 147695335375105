import React from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Emailzugang } from "../../../types/remote/Emailzugang";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { startEmailcheckerJob, cancelEmailcheckerJob, getEmailcheckerJob, resetEmailCheckerStatus } from "../../../actions";
import { JobStatus } from "../../../types/remote/JobStatus";
import { ProgressBar } from "primereact/progressbar";
import { Checkbox } from "primereact/checkbox";

interface EmailcheckerDialogProps {
  startEmailcheckerJob: Function;
  cancelEmailcheckerJob: Function;
  getEmailcheckerJob: Function;
  resetEmailCheckerStatus: typeof resetEmailCheckerStatus;
  emailzugang?: Emailzugang;
  onHide: Function;
  content: ContentState;
}

interface EmailcheckerDialogState {
  started: boolean;
  delete: boolean;
}

export class _EmailcheckerDialog extends React.Component<EmailcheckerDialogProps, EmailcheckerDialogState> {
  private interval: any;

  constructor(props: EmailcheckerDialogProps) {
    super(props);
    this.state = { started: false, delete: false };
  }

  componentDidMount() {
    this.props.resetEmailCheckerStatus();
    this.setState({ ...this.state, started: false, delete: false });
  }

  renderFooter() {
    return (
      <div>
        {!this.state.started ? (
          <div className="flex flex-wrap justify-content-center gap-3">
            <div className="flex align-items-center">
              <Checkbox
                inputId="ingredient1"
                name="pizza"
                value="Cheese"
                onChange={() => {
                  this.setState({ ...this.state, delete: !this.state.delete });
                }}
                checked={this.state.delete}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Delete mails
              </label>
            </div>

            <Button
              label={"Start Emailcheck"}
              icon="pi pi-check"
              disabled={this.state.started}
              onClick={() => {
                this.props.startEmailcheckerJob(this.props.emailzugang, this.state.delete);
                this.setState({ ...this.state, started: true });
              }}
              autoFocus
            />
          </div>
        ) : null}
        {this.state.started ? (
          <Button
            label={"CANCEL"}
            icon="pi pi-times"
            onClick={() => {
              this.close();
            }}
            className="p-button-text"
          />
        ) : null}
      </div>
    );
  }

  close = () => {
    if (this.props.content.EMAILCheckerStatus && this.props.content.EMAILCheckerStatus.status === JobStatus.RUNNING)
      this.props.cancelEmailcheckerJob();
    this.props.resetEmailCheckerStatus();
    this.props.onHide();
    this.setState({ ...this.state, started: false, delete: false });
  };

  componentDidUpdate(prevProps: EmailcheckerDialogProps, prevState: EmailcheckerDialogState) {
    if (!prevState.started && this.state.started && !this.interval) {
      this.interval = setInterval(async () => {
        await this.props.getEmailcheckerJob();
      }, 1000);
    }

    if (
      this.interval &&
      ((this.props.content.EMAILCheckerStatus && this.props.content.EMAILCheckerStatus.status !== JobStatus.RUNNING) ||
        (!this.props.emailzugang && prevProps.emailzugang))
    ) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  render() {
    return (
      <Dialog
        header={"Email-Postfach checker"}
        visible={this.props.emailzugang ? true : false}
        modal
        style={{ width: "50%" }}
        footer={this.renderFooter()}
        onHide={() => {
          this.close();
        }}
      >
        <div className="confirmation-content">
          {this.state.started ? (
            <div>
              {(this.props.content.EMAILCheckerStatus && this.props.content.EMAILCheckerStatus.status === JobStatus.RUNNING) ||
              (!this.props.content.EMAILCheckerStatus && this.state.started)
                ? "Wir arbeiten noch dran..."
                : this.props.content.EMAILCheckerStatus?.status === JobStatus.CANCELD
                ? "Der Job wurde abgebrochen"
                : "Job erledigt"}
              <br />
              <br />
              <ProgressBar
                value={
                  this.props.content.EMAILCheckerStatus
                    ? Math.round((this.props.content.EMAILCheckerStatus.current / this.props.content.EMAILCheckerStatus.max) * 100)
                    : 0
                }
              />
              {this.props.content.EMAILCheckerStatus ? (
                <div>
                  <p>SOFTBOUNCE: {this.props.content.EMAILCheckerStatus.softbounce}</p>
                  <p>HARDBOUNCE: {this.props.content.EMAILCheckerStatus.hardbounce}</p>
                  <p>UNKNOWN: {this.props.content.EMAILCheckerStatus.unbekannt}</p>
                  <p>SPAM: {this.props.content.EMAILCheckerStatus.spam}</p>
                  <p>NO CATEGORIE: {this.props.content.EMAILCheckerStatus.uncategorizes}</p>
                  <p>OVERALL: {this.props.content.EMAILCheckerStatus.overall}</p>
                  <p>TODELETE: {this.props.content.EMAILCheckerStatus.deletable}</p>
                  <p>DELETED: {this.props.content.EMAILCheckerStatus.deleted}</p>
                </div>
              ) : null}
            </div>
          ) : (
            "Bitte starten Sie"
          )}
        </div>
      </Dialog>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const EmailcheckerDialog = connect(mapStateToProps, {
  startEmailcheckerJob,
  cancelEmailcheckerJob,
  getEmailcheckerJob,
  resetEmailCheckerStatus,
})(_EmailcheckerDialog);
