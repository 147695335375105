import { checkEmail, checkInternet, checkPhone, checkTextLen } from "../../../helper/validate";
import { ValidEnum } from "../../../types/local/ValidEnum";
import { Dataset } from "../../../types/remote";
import { Anrede } from "../../../types/remote/Anrede";

export function isDatasetFormValid(dataset: Dataset): boolean {
  if (checkTextLen(dataset.Firmenname, 100, 2) !== ValidEnum.VALID) {
    return false;
  }

  if (checkEmail(dataset.Email) !== ValidEnum.VALID) {
    return false;
  }

  if (checkInternet(dataset.Internet) === ValidEnum.INVALID) {
    return false;
  }

  if (checkPhone(dataset.Fax) === ValidEnum.INVALID) {
    return false;
  }

  if (checkTextLen(dataset.Vorname, 50, 2) === ValidEnum.INVALID) {
    return false;
  }

  if (checkTextLen(dataset.Nachname, 50, 2) === ValidEnum.INVALID) {
    return false;
  }

  if (checkPhone(dataset.Tel) === ValidEnum.INVALID) {
    return false;
  }

  if (checkTextLen(dataset.strasse, 50, 2) === ValidEnum.INVALID) {
    return false;
  }

  if (checkTextLen(dataset.plz, 10, 3) === ValidEnum.INVALID) {
    return false;
  }

  if (checkTextLen(dataset.Ort, 110, 2) === ValidEnum.INVALID) {
    return false;
  }

  if (
    checkTextLen(dataset.Nachname, 50, 2) !== ValidEnum.NOT_SET &&
    !(dataset.Anrede === Anrede.FRAU || dataset.Anrede === Anrede.HERR || dataset.Anrede === Anrede.KEINE_ANGABE)
  ) {
    return false;
  }

  return true;
}
