import React from "react";

import { Button } from "primereact/button";

import "./style.css";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { LoginState } from "../../../reducers/login";
import { changeLoadScreen, logoutAction, changePage, showMessage } from "../../../actions";
import { Dialog } from "primereact/dialog";
import { Page } from "../../../types/local/pages";

interface AvatarProps {
  logoutAction: Function;
  route: typeof changePage;
  changeLoadScreen: typeof changeLoadScreen;
  showMessage: Function;
  login: LoginState;
}

interface AvatarState {
  open: boolean;
}

class _Avatar extends React.Component<AvatarProps, AvatarState> {
  constructor(props: AvatarProps) {
    super(props);
    this.state = { open: false };
  }
  logoutUser() {
    this.props.logoutAction();
  }

  getAvatarCredentials(): string {
    if (
      this.props.login.userData &&
      this.props.login.userData.Vorname &&
      this.props.login.userData.Vorname.length > 0 &&
      this.props.login.userData.Nachname &&
      this.props.login.userData.Nachname.length > 0
    ) {
      return this.props.login.userData.Vorname[0].toUpperCase() + this.props.login.userData.Nachname[0].toUpperCase();
    }
    return "??";
  }

  componentDidUpdate(prevProps: AvatarProps) {
    if (prevProps.login.userData && !this.props.login.userData) {
      this.props.route(Page.LOGIN);
    }
  }

  render() {
    return (
      <div className="avatar-container">
        <Button icon="pi pi-info" onClick={() => this.setState({ ...this.state, open: true })} className="p-button-rounded p-button-info" />
        <div className="avatar-devider"></div>
        <span className="p-badge p-badge-xl p-badge-xl-custom p-badge-warning">{this.getAvatarCredentials()}</span>
        <div className="avatar-devider"></div>
        <Button
          icon="pi pi-power-off"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            this.logoutUser();
          }}
        />
        <Dialog
          style={{ minWidth: "250px", minHeight: "100px" }}
          header="Version-Dialog"
          onHide={() => {
            this.setState({ ...this.state, open: false });
          }}
          visible={this.state.open}
        >
          {process.env.REACT_APP_NAME}:{process.env.REACT_APP_VERSION} <br />
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ login }: StoreState): { login: LoginState } => {
  return { login };
};

export const Avatar = connect(mapStateToProps, {
  logoutAction,
  route: changePage,
  changeLoadScreen,
  showMessage,
})(_Avatar);
