export const dashboardData = {
  amountOfAllRecords: 100432,
  data: [
    {
      KID: 1,
      Email: "rpohl@mh-schleswig.de",
      Firmenname: "Ralph Pohl Testempfänger",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495251555555",
      Ort: "Rheinfelden Baden",
      Tel: "'+495251555555",
      Anrede: "",
      Vorname: "Hermann ",
      Nachname: "Josef",
      UID: 1,
      linkid: "JAGGYAUMYBDWST",
      strasse: "",
      plz: "",
      land: "UNBEKANNT",
      Internet: "https://test.de",
      Bemerkung: "",
      Faxstatus: "INAKTIV",
    },
    {
      KID: 2,
      Email: "info@cvs-eng.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4976237174190",
      Ort: "Rheinfelden Baden",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "QTIBIUPNJSWRCJ",
      strasse: null,
      plz: null,
      land: null,
      Internet: "www.google.de",
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 3,
      Email: "info@haeusler.com",
      Firmenname: "Häusler GmbH",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4976239090749",
      Ort: "Rheinfelden Baden",
      Tel: null,
      Anrede: "keine_Angabe",
      Vorname: "",
      Nachname: "",
      UID: 1,
      linkid: "OGHIUAABXYMOMV",
      strasse: null,
      plz: null,
      land: null,
      Internet: "",
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 5,
      Email: "info@eberle-gross.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+497227979850",
      Ort: "Lichtenau Baden",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "OQSWJAJRDEWVJP",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 6,
      Email: "info@js-kanalrobotik.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+497242933345",
      Ort: "Rheinstetten",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "FJCWGNCQPPQWGI",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 7,
      Email: "tectron-waagen@t-online.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4928336396",
      Ort: "Kerken",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "MTQQUOQPGUEJHR",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 10,
      Email: "info@ostler-maschinen.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+498346982027",
      Ort: "Pforzen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "OWEBAYTLGLASOM",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 11,
      Email: "info@patterer.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4983629308730",
      Ort: "Fï¿½ssen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "ONISYUJVWJWGEC",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 12,
      Email: "info@prometall-fertigungstechnik.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4983629390760",
      Ort: "Fï¿½ssen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "DHFDBDVMPWDIOU",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 13,
      Email: "info@rich-praezision.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4971239305599",
      Ort: "Metzingen Wï¿½rttemberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "PPXVVELXQIIQJE",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 14,
      Email: "info@formenbau-blank.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+497371182840",
      Ort: "Riedlingen Wï¿½rttemberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "KEWFRAWJAYSEAU",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 15,
      Email: "info@srk-systemtechnik.de",
      Firmenname: "SRK Systemtechnik GmbH Anlagen- und Maschinenbau",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "6158920230",
      Ort: "Riedstadt-Goddelau",
      Tel: "615892020",
      Anrede: "Frau",
      Vorname: "Anita",
      Nachname: "Hofmann",
      UID: 2,
      linkid: "HMVNSXJTHLIDAF",
      strasse: "SRK Systemtechnik GmbH Anlagen-  und Maschinenbau",
      plz: "64560",
      land: "DEUTSCHLAND",
      Internet: "www.srk-systemtechnik.com",
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 16,
      Email: "info@saar-mosel-baumaschinen.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4968069877610",
      Ort: "Heusweiler",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "VGRJQDEXWUPRDD",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 17,
      Email: "gericke.de@gericke.net",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+497731929312",
      Ort: "Singen Hohentwiel",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "BRFYFXKTMQFSCK",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 18,
      Email: "info@veit-soehne.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+49773148234",
      Ort: "Singen Hohentwiel",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "SNULCYYIGYREKD",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 19,
      Email: "info@christian-koenen.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+498966561830",
      Ort: "Mï¿½nchen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "GXQONKNUSVANNP",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 20,
      Email: "info@brandbau.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+499354605",
      Ort: "Rieneck",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "GFFWCBEXGRHCGX",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 21,
      Email: "info@altmann-gruppe.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4935255188719",
      Ort: "Riesa",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "NIMSLHPFYTQIJY",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 22,
      Email: "info@feralpi.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493525749281",
      Ort: "Riesa",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "EIWOAGSRIIJYQA",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 23,
      Email: "info@elimo.org",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493525725928",
      Ort: "Riesa",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "VCDQWJAAACWYIK",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 24,
      Email: "idg@idg-riesa.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493525723120",
      Ort: "Riesa",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "JSBIBRSJVKFTAQ",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 25,
      Email: "info@kopf-riesa.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493525897125",
      Ort: "Riesa",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "AITPQRTBAKTFSU",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 26,
      Email: "info@heine-stahlbau.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493525714699",
      Ort: "Riesa",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "OSYWLSWWGOBPOI",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 27,
      Email: "info@stratos-gmbh.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493525684949",
      Ort: "Riesa",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "NJFARBKJGIQFFH",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 28,
      Email: "info@suedmo.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+499081803158",
      Ort: "Nï¿½rdlingen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "MHFAVSBNXXLNYF",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 29,
      Email: "info@crg-Automation.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496336839728",
      Ort: "Dellfeld",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "EICTJYTKINXVGW",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 30,
      Email: "maschinenbau-g.weidler@gmx.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4963365304",
      Ort: "Dellfeld",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "KDIWIXOHLJVQUW",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 31,
      Email: "info@mohrbach.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4963365894",
      Ort: "Dellfeld",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "UUHBAJLKWEHYSF",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 32,
      Email: "info@growi.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495244930138",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "GALENBJHIXVNFW",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 33,
      Email: "info@kathoefer-rietberg.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495244930030",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "VMEMVTYNPHIGIP",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 34,
      Email: "verwaltung@horstkemper.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+492944970233",
      Ort: "Rietberg-Mastholte",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "RVGFHRBLBBCKPC",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 35,
      Email: "info@iwa-maschinenbau.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4952449730590",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "KVVISHVCWGJAWU",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 36,
      Email: "info@stuewa.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4952441670",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "TPKKBAIBLRSUSY",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 37,
      Email: "info@lsystem.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495244972422",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "DPDQASKGLKEDPD",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 38,
      Email: "info@mhf-gmbh.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+492944972399",
      Ort: "Rietberg-Mastholte",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "AWDGTWUEPFNHKM",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 39,
      Email: "info@proske-schattke.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495244906969",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "MJPIBYCCOVIXWV",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 40,
      Email: "info@sintron.net",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495244926629",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "FRXPIXDVRYWVFC",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 41,
      Email: "allgemein@stuecker-landtechnik.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495244970730",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "JPIOWQHNONIHUL",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 42,
      Email: "info@teampack.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495244970629",
      Ort: "Rietberg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "HCFBDICVPNLXXT",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 43,
      Email: "ag-daubitz@gmx.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493577240001",
      Ort: "Rietschen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "BNFDUACAXCGSSI",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 44,
      Email: "info@sih-sa.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+49336620739",
      Ort: "Beeskow",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "IVENNTEKFTOWVX",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 45,
      Email: "info@werkzeugbau-fischer.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+49805196435599",
      Ort: "Prien am Chiemsee",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "ABPARLMSUHHBCC",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 46,
      Email: "info@serra.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+498051964005",
      Ort: "Prien am Chiemsee",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "QYMWGTDULNONJS",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 47,
      Email: "pauli@pauli-metallbau.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+498555961650",
      Ort: "Perlesreut",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "WUTLLSSONODWRH",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 48,
      Email: "info@hagesana.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4951524230",
      Ort: "Hessisch Oldendorf",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "KIPCIPFQOTCUMO",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 49,
      Email: "info@rkr.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495751400430",
      Ort: "Rinteln",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "IWKLYRLEOMCKBD",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 50,
      Email: "info@hoefs-maschinenbau.de",
      Firmenname: "Hoefs Maschinenbau",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "",
      Ort: "Ritterhude",
      Tel: "",
      Anrede: "",
      Vorname: "",
      Nachname: "",
      UID: 1,
      linkid: "RBPTAAFQKLNLIF",
      strasse: "",
      plz: "",
      land: "",
      Internet: "",
      Bemerkung: "",
      Faxstatus: "INAKTIV",
    },
    {
      KID: 51,
      Email: "info@kaeltetechnik-jacobsen.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+494216367771",
      Ort: "Bremen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "TSETVROINPCNLQ",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 52,
      Email: "info@servatius-ehlenz.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4965619710720",
      Ort: "Bitburg",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "OGCXDSTBBUIUWC",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 53,
      Email: "kies@wey-gruppe.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4965087470",
      Ort: "Hetzerath Mosel",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "IHTGNNXTKNYOJE",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 54,
      Email: "leipold@leipold-gmbh.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4963618773",
      Ort: "Rockenhausen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "PSRRFLBDFACAYB",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 55,
      Email: "brandtundco.gmbh@t-online.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493602690791",
      Ort: "Struth bei Mï¿½hlhausen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "SEQFTGFFMRXEDA",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 56,
      Email: "info@remo-ladeneinrichtung.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496184934020",
      Ort: "Langenselbold",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "JNJMBSAWUSBLGV",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 57,
      Email: "info@filterbau.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495723989929",
      Ort: "Bad Nenndorf",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "UGXDIWLVFAMILW",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 58,
      Email: "verkauf@ktrgmbh.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4957232074",
      Ort: "Bad Nenndorf",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "DYLGOEBUTAXEVM",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 59,
      Email: "bender@bender-industriebedarf.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493744364720",
      Ort: "Auerbach Vogtland",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "IMRLKIABMHLIOS",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 60,
      Email: "infogmbh@astromed.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496106771121",
      Ort: "Rodgau",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "NFHNFGDCKWXSOW",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 61,
      Email: "info@atlas-rhein-main.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496106600612",
      Ort: "Rodgau",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "EKOHFVSVRNSUCL",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 62,
      Email: "info@ktechnik.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496106850755",
      Ort: "Rodgau",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "QMRWQCIJJVKYYX",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 63,
      Email: "info@smarttec.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4961066670110",
      Ort: "Rodgau",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "GRPDIXHRCQVBBB",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 64,
      Email: "info@goebel-maschinenbau.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496106851355",
      Ort: "Rodgau",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "VUTOEXGNMVRERA",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 65,
      Email: "info@db-matik.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4994616388199",
      Ort: "Roding",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "XJSBDDSKORYPDI",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 66,
      Email: "info@hkroding.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+499461953246",
      Ort: "Roding",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "DSYSGBPFDCKGVN",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 67,
      Email: "stefan.kulzer@stangl-co.de",
      Firmenname: "Stangl & Co.GmbH",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "94614960",
      Ort: "Roding",
      Tel: "9461911060",
      Anrede: "Herr",
      Vorname: "Stefan",
      Nachname: "Kulzer",
      UID: 2,
      linkid: "WWFEIHQKTDOLEX",
      strasse: "Wernher-von-Braun-Str. 4",
      plz: "93426",
      land: "DEUTSCHLAND",
      Internet: "www.stangl-co.de",
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 68,
      Email: "info@ltc-roebel.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493993157644",
      Ort: "Rï¿½bel Mï¿½ritz",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "EINACUXRHFWTVX",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 69,
      Email: "technologik@t-online.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493993187221",
      Ort: "Rï¿½bel Mï¿½ritz",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "CYXFFYDGSKFFOA",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 70,
      Email: "info@betonhotline.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+499563723422",
      Ort: "Rï¿½dental",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "LQUUJUIHCNRDPL",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 71,
      Email: "dahle@dahle.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+49956375299333",
      Ort: "Rï¿½dental",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "RORGTUOCTJYRXG",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 72,
      Email: "info@lieb-sondermaschinenbau.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+499563723050",
      Ort: "Rï¿½dental",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "QIVVTWMGTNOAFX",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 73,
      Email: "info.dms@dmsfactory.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4960748654629",
      Ort: "Rï¿½dermark",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "OBIDICTORGEDUR",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 74,
      Email: "info@incoe.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4960748907310",
      Ort: "Rï¿½dermark",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "XNSDLJBKITYXMN",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 75,
      Email: "info@sinotec-gmbh.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496074852226",
      Ort: "Rï¿½dermark",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "JMMESSRYKTUQTM",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 76,
      Email: "info@ulma-gmbh.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4960749294101",
      Ort: "Rï¿½dermark",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "ODPSKNNUKISSAL",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 77,
      Email: "info@hassheider.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4952236854528",
      Ort: "Bï¿½nde",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "QCLSSBMOGIVRTB",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 78,
      Email: "mail@irb-rohrbau.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+49522365379555",
      Ort: "Bï¿½nde",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "YAIYXKERQXFWWK",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 79,
      Email: "info@fiegekg.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+49937294839190",
      Ort: "Klingenberg am Main",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "VYMDONIRAEASXJ",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 80,
      Email: "info@gutekunst-tieflochbohren.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+497382937050",
      Ort: "Rï¿½merstein",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "VWGWTYCICKKMUY",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 81,
      Email: "info@rueckle.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+497382937349",
      Ort: "Rï¿½merstein",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "JXFABXBBVXMKGY",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 82,
      Email: "d.meller@gapi.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4922059049433",
      Ort: "Rï¿½srath",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "CVDBLLEJEYBYWD",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 83,
      Email: "info@rk-siebdruck.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4922059499777",
      Ort: "Rï¿½srath",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "YKIYODEJGPKAPJ",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 84,
      Email: "info@remshagen.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+492205926150",
      Ort: "Rï¿½srath",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "LQBUHLQJIXMJQH",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 85,
      Email: "info@cfm-schiller.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+492471124620",
      Ort: "Roetgen Eifel",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "HLIARMOGHWQTWR",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 86,
      Email: "info@kuttig.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4924719209090",
      Ort: "Roetgen Eifel",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "LNYIFPNOCPGFEY",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 87,
      Email: "info@myrenne.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+492471121212",
      Ort: "Roetgen Eifel",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "FEIFEFCUXCHJME",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 88,
      Email: "info@esal-gmbh.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4997239346850",
      Ort: "Rï¿½thlein",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "PQYPKGOGYFYMQH",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 89,
      Email: "info@fuchs-beton.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4991727007676",
      Ort: "Georgensgmï¿½nd",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "HSCUQXYMDHAKAB",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 90,
      Email: "office@winklerdesign.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+499338972080",
      Ort: "Rï¿½ttingen Unterfranken",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "ICINHTHKANJIGT",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 91,
      Email: "info@meyer-machines.com",
      Firmenname: "Meyer Machines",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "",
      Ort: "Rï¿½tz",
      Tel: "",
      Anrede: "",
      Vorname: "",
      Nachname: "",
      UID: 1,
      linkid: "NFNDJQPGLARVQR",
      strasse: "",
      plz: "",
      land: "",
      Internet: "",
      Bemerkung: "",
      Faxstatus: "INAKTIV",
    },
    {
      KID: 92,
      Email: "info@kbronline.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493882484017",
      Ort: "Mallentin",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "YIDCJBSDVRDTYR",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 93,
      Email: "info@ezm-mark.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493684444620",
      Ort: "Rohr Thï¿½ringen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "BFNHOCHRGBQYEC",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 94,
      Email: "info@kempfgmbh.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4984427401",
      Ort: "Wolnzach",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "PVAONBKBKDUACG",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 95,
      Email: "kls.duczek@t-online.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493495421009",
      Ort: "Roitzsch bei Bitterfeld",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "MJKORCVHFJJKTF",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 96,
      Email: "info-rollwitz@agram.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+493973209340",
      Ort: "Pasewalk",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "VFMVYSEEBAYFRH",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 97,
      Email: "gsb@gsb-gruppe.de",
      Firmenname: "GSB-Gesellschaft für elektrische Ausrüstungen mbH & Co. KG",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "021834215100",
      Ort: "Rommerskirchen",
      Tel: "(02183) 4 21-100",
      Anrede: "keine_Angabe",
      Vorname: "",
      Nachname: "",
      UID: 9,
      linkid: "YXNKPYDDONKVWO",
      strasse: "Albert-Einstein-Str. 6",
      plz: "41569",
      land: "DEUTSCHLAND",
      Internet: "www.gsb-gruppe.de",
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 98,
      Email: "info@utr-umformtechnik.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+49366025008941",
      Ort: "Ronneburg Thï¿½ringen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "EBOCJDWADAMNKF",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 99,
      Email: "info@beem.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+496003911340",
      Ort: "Rosbach von der Hï¿½he",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "LCMMUITNNSJJQL",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 100,
      Email: "office@denis.de",
      Firmenname: "DENIS PRIVE GmbH",
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "06003911150",
      Ort: "Rosbach von der Hï¿½he",
      Tel: "(06003) 91 11-0",
      Anrede: "keine_Angabe",
      Vorname: "",
      Nachname: "",
      UID: 9,
      linkid: "XVEWRHOJDJLJUM",
      strasse: "Nieder-Rosbacher-Str. 27-29",
      plz: "61191",
      land: "UNBEKANNT",
      Internet: "www.denis-prive.de",
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 101,
      Email: "kebegmbh@t-online.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+4960071216",
      Ort: "Rosbach-Rodheim",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "WGNNRWLTIHWXHQ",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
    {
      KID: 102,
      Email: "info@wille-geotechnik.com",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+495513075220",
      Ort: "Gï¿½ttingen",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "XGVHCDDUGRMAIA",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "INAKTIV",
    },
    {
      KID: 103,
      Email: "info@anton-huelsken.de",
      Firmenname: null,
      letzteAenderung: "2015-06-23T12:25:32.000Z",
      Fax: "'+492547390",
      Ort: "Rosendahl-Osterwick",
      Tel: null,
      Anrede: null,
      Vorname: null,
      Nachname: null,
      UID: null,
      linkid: "TTTFMSCHACWIWL",
      strasse: null,
      plz: null,
      land: null,
      Internet: null,
      Bemerkung: null,
      Faxstatus: "AKTIV",
    },
  ],
};
