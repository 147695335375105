import { Actions } from "../../actions";
import { LoginAction } from "../../actions/login";
import { Benutzer } from "../../types/remote";

export interface LoginState {
  userData?: Benutzer;
  result?: number;
}

export const LoginReducer = (state: LoginState = {}, action: LoginAction): LoginState => {
  if (action.type === Actions.LOGIN || action.type === Actions.LOGOUT) {
    return action.data;
  } 
  return state;
};
