export const emailvorlagen = [
  {
    EVID: 40,
    filepath: "files/emailvorlagen/OWOGLJP32BXB.eml",
    Bezeichnung: "Rundschreibenvorlage 3",
    Created: "0",
    Creator: 0,
    FID: 14,
  },
  {
    EVID: 41,
    filepath: "files/emailvorlagen/0Q4HOFRNMJI5.eml",
    Bezeichnung: "Rundschreibenvorlage 4",
    Created: "0",
    Creator: 0,
    FID: 14,
  },
  {
    EVID: 42,
    filepath: "files/emailvorlagen/VG9HCAY2XW7H.eml",
    Bezeichnung: "Rundschreibenvorlage 5",
    Created: "0",
    Creator: 0,
    FID: 14,
  },
];
