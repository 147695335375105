import React from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import "./style.css";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadMainContent, sucheSetCollectionAction, sucheSetSearchTextAction } from "../../../actions";
import { checkCopied } from "../../../helper/checkCopied";

interface SearchProps {
  content: ContentState;
  sucheSetCollectionAction: typeof sucheSetCollectionAction;
  sucheSetSearchTextAction: typeof sucheSetSearchTextAction;
  loadMainContent: Function;
}

interface SearchState {
  value: string;
  searchActive: boolean;
}

export class _Search extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);
    this.state = { value: this.props.content.suche.search ? this.props.content.suche.search : "", searchActive: false };
  }

  componentDidMount() {
    if (this.props.content.suche.search) {
      this.setState({ ...this.state, searchActive: true });
    }
  }

  startSearch = (val: string) => {
    this.props.sucheSetSearchTextAction(val);
    this.props.loadMainContent(this.props.content.suche);
    this.setState({ ...this.state, searchActive: true });
  };

  resetSearch = () => {
    this.setState({ value: "", searchActive: false });
    if (this.props.content.suche.search && this.props.content.suche.search.length > 0) {
      this.props.sucheSetSearchTextAction();
      this.props.loadMainContent(this.props.content.suche);
    }
  };

  render() {
    return (
      <div className="search-container">
        <div>
          <span className="p-input">
            <InputText
              value={this.state.value}
              disabled={this.state.searchActive}
              onChange={(e: any) => {
                if (this.props.content.suche.search) {
                  if (this.props.content.suche.search.length === 0) {
                    this.setState({ value: checkCopied(this.state.value, e.target.value) });
                  }
                } else {
                  this.setState({ value: checkCopied(this.state.value, e.target.value) });
                }
              }}
            />
            &nbsp;
          </span>
          {this.state.searchActive ? (
            <Button
              onClick={() => {
                this.resetSearch();
              }}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger search-button"
            />
          ) : (
            <Button
              onClick={() => {
                this.startSearch(this.state.value);
              }}
              disabled={this.state.value.length === 0}
              icon="pi pi-search"
              className="p-button-rounded p-button-info search-button"
            />
          )}
        </div>
        <div>
          <Dropdown
            value={this.props.content.suche.collection}
            options={this.props.content.collections}
            optionLabel="name"
            onChange={(e: any) => {
              this.props.sucheSetCollectionAction(e.value);
              this.props.loadMainContent(this.props.content.suche);
            }}
            placeholder="Collection auswählen"
            className="search-collection-selector"
          />{" "}
          {this.props.content.suche.collection ? (
            <Button
              onClick={() => {
                this.props.sucheSetCollectionAction(undefined);
                this.props.loadMainContent(this.props.content.suche);
              }}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger"
            />
          ) : null}
        </div>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const Search = connect(mapStateToProps, {
  sucheSetCollectionAction,
  sucheSetSearchTextAction,
  loadMainContent,
})(_Search);
