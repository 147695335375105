import { Dispatch } from "redux";
import { changeLoadScreen, changePage, showMessage } from "../actions";
import { Page } from "../types/local/pages";
import { MessageType } from "../types/local/messageData";

export const resolveFailure = (dispatch: Dispatch, exception: any, action?: any) => {
  dispatch(changeLoadScreen(false));

  if (typeof exception === "string") {
    dispatch(
      showMessage({
        detail: exception,
        summary: "Fehler: ",
        severity: MessageType.ERROR,
      })
    );
  } else if (exception.message.includes("403")) {
    dispatch(
      showMessage({
        detail: "Ihre Sitzung ist nicht mehr aktiv, bitte loggen Sie sich neu ein.",
        summary: "Fehler:",
        severity: MessageType.ERROR,
      })
    );
    dispatch(changePage(Page.LOGIN));
  } else if (action) {
    dispatch(
      showMessage({
        detail: "Die Abfrage konnte nicht durchgeführt werden.",
        summary: "Fehler: ",
        severity: MessageType.ERROR,
      })
    );
    dispatch(action);
  } else {
    dispatch(
      showMessage({
        detail: "Die Abfrage konnte nicht durchgeführt werden.",
        summary: "Fehler: ",
        severity: MessageType.ERROR,
      })
    );
  }
};
