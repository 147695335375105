import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadBenutzer, deleteBenutzer } from "../../../actions";
import { Benutzer } from "../../../types/remote";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import datetime from "date-and-time";
import { BenutzerDialog } from "../../parts/BenutzerDialog/BenutzerDialog";
import { generateEmptyBenutzer } from "../../../helper/benutzerHelper";
import { PasswordDialog } from "../../parts/BenutzerDialog/PasswordDialog";
import { ResultToast } from "../../parts/ResultToast/ResultToast";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { LoginState } from "../../../reducers/login";

interface BenutzerProps {
  content: ContentState;
  loadBenutzer: Function;
  deleteBenutzer: Function;
  login: LoginState;
}

interface BenutzerState {
  userDialog?: Benutzer;
  passwordDialog?: Benutzer;
}

class _BenutzerUebersicht extends React.Component<BenutzerProps, BenutzerState> {
  constructor(props: BenutzerProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadBenutzer();
  }

  render() {
    return (
      <div className="card">
        <HeaderMenu forceAdd={true} action={() => this.setState({ ...this.state, userDialog: generateEmptyBenutzer() })} />
        <ResultToast successAction={() => this.props.loadBenutzer()} />
        <PasswordDialog benutzer={this.state.passwordDialog} close={() => this.setState({ ...this.state, passwordDialog: undefined })} />
        <BenutzerDialog benutzer={this.state.userDialog} close={() => this.setState({ ...this.state, userDialog: undefined })} />
        <ConfirmDialog />
        <DataTable
          value={this.props.content.benutzer.map((val: Benutzer) => {
            let temp: any = val;

            temp.lastlogin = val.lastlogin ? datetime.format(new Date(parseInt(val.lastlogin)), "DD.MM.YYYY-HH:mm") : "unbekannt";

            temp.func = (
              <div className="buttonSpacer">
                {this.props.login.userData && this.props.login.userData.admin ? (
                  <div className="p-1">
                    {" "}
                    <Button
                      onClick={() => {
                        this.setState({ ...this.state, userDialog: temp });
                      }}
                      tooltip="Edit Benutzer"
                      icon="pi pi-user-edit"
                      className="p-button-rounded p-button-info"
                    />
                  </div>
                ) : null}

                {this.props.login.userData && (this.props.login.userData.admin || val.lid === this.props.login.userData.lid) ? (
                  <div className="p-1">
                    {" "}
                    <Button
                      onClick={() => {
                        this.setState({ ...this.state, passwordDialog: temp });
                      }}
                      tooltip="Change Password"
                      icon="pi pi-cog"
                      className="p-button-rounded p-button-warning"
                    />
                  </div>
                ) : null}

                {this.props.login.userData && this.props.login.userData.admin ? (
                  <div className="p-1">
                    <Button
                      onClick={() => {
                        confirmDialog({
                          message: `Soll der Benutzer ${val.Vorname} ${val.Nachname} wirklich gelöscht werden?`,
                          header: "Delete Confirmation",
                          icon: "pi pi-info-circle",
                          acceptClassName: "p-button-danger",
                          accept: () => {
                            this.props.deleteBenutzer(temp);
                          },
                        });
                      }}
                      tooltip="DELETE Benutzer"
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger"
                    />
                  </div>
                ) : null}
              </div>
            );

            return temp;
          })}
        >
          <Column style={{ width: "10%" }} field="lid" header="LID"></Column>
          <Column field="Vorname" header="Vorname"></Column>
          <Column field="Nachname" header="Nachname"></Column>
          <Column field="Email" header="Email"></Column>

          <Column field="func" header="" style={{ width: "100px" }}></Column>
        </DataTable>

        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content, login }: StoreState): { content: ContentState; login: LoginState } => {
  return { content, login };
};

export const BenutzerUebersicht = connect(mapStateToProps, {
  loadBenutzer,
  deleteBenutzer,
})(_BenutzerUebersicht);
