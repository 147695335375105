export const dataset = {
  KID: 1,
  Email: "rpohl@mh-schleswig.de",
  Firmenname: "Ralph Pohl Testempfänger",
  letzteAenderung: "2015-06-23T12:25:32.000Z",
  Fax: "",
  Ort: "Rheinfelden Baden",
  Tel: "",
  Anrede: 0,
  Vorname: "Hermann ",
  Nachname: "Josef",
  UID: {
    lid: 1,
    Vorname: "Ralph",
    Nachname: "Pohl",
    Email: "rpohl@mh-schleswig.de",
    admin: 1,
    creator: 0,
    created: "",
    lastlogin: "1694769527037",
  },
  linkid: "JAGGYAUMYBDWST",
  strasse: "",
  plz: "",
  land: "UNBEKANNT",
  Internet: "https://test.de",
  Bemerkung: "",
  Faxstatus: "INAKTIV",
  vorgaenge: 0,
  action: {
    type: "div",
    key: null,
    ref: null,
    props: {
      className: "buttonSpacer",
      children: [
        {
          type: {},
          key: null,
          ref: null,
          props: { icon: "pi pi-id-card", className: "p-button-rounded p-button-info", tooltip: "Kundendaten" },
          _owner: null,
        },
        {
          type: {},
          key: null,
          ref: null,
          props: { icon: "pi pi-envelope", tooltip: "Status des Kontaktes", className: "p-button-rounded p-button-success" },
          _owner: null,
        },
      ],
    },
    _owner: null,
  },
  LetzteAenderung: 1694774981275,
};
