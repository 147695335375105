import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";

import { changeLoadScreen } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { ResultObj } from "../../types/remote";
import { Emailvorlage } from "../../types/remote/Emailvorlage";
import { updateResultCode } from ".";
import { Emailzugang } from "../../types/remote/Emailzugang";
import { EMAILCheckerStatus } from "../../types/remote/emailCheckerStatus";

export interface CheckEmailCheckerStatusAction {
  type: Actions.CONTENT_LOAD_EMAILCHECKERSTATUS;
  data: EMAILCheckerStatus | undefined;
}

export const startEmailcheckerJob = (emailzugang: Emailzugang, del: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<Emailvorlage[]>>(
        `${config.backendUrl}/v1/emailchecker${del ? "?del=true" : ""}`,
        emailzugang
      );
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const cancelEmailcheckerJob = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.delete<ResultObj<Emailvorlage[]>>(`${config.backendUrl}/v1/emailchecker`);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const getEmailcheckerJob = () => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await mainaxios.get<ResultObj<EMAILCheckerStatus | undefined>>(`${config.backendUrl}/v1/emailchecker`);

      dispatch<CheckEmailCheckerStatusAction>({ type: Actions.CONTENT_LOAD_EMAILCHECKERSTATUS, data: response.data.data });
    } catch (exx) {
      resolveFailure(dispatch, exx);
    }
  };
};

export const resetEmailCheckerStatus = (): CheckEmailCheckerStatusAction => {
  return { type: Actions.CONTENT_LOAD_EMAILCHECKERSTATUS, data: undefined };
};
