import { Benutzer } from "./Benutzer";
import { Firma } from "./Firma";

export interface NLStatus {
  NLSID: number;
  FID: Firma;
  letzteAenderung: string;
  UID: Benutzer;
  linkid: string;
  status: NLStatusEnum;
}

export enum NLStatusEnum {
  ACTIVE,
  GELOESCHT,
  HARDBOUNCE,
  SOFTBOUNCE,
  SPAM,
  UNBEKANNT,
  GELOESCHT_OPTOUT,
}
