import { Uebersicht } from "../../types/remote";
import { config } from "../../config/config";
import { ResultObj } from "../../types/remote";
import { Actions, mainaxios } from "../index";
import { changeLoadScreen } from "../router";
import { Suche } from "../../types/remote/Suche";
import { Dispatch } from "redux";
import { resolveFailure } from "../../helper/failureProcedure";

export interface LoadContentAction {
  type: Actions.CONTENT_LOAD_CONTENT;
  data: Uebersicht | undefined;
}

export interface UpdateResultCodeAction {
  type: Actions.UPDATE_RESULTCODE;
  data: { rc: number };
}

export const updateResultCode = (res: number): UpdateResultCodeAction => {
  return { type: Actions.UPDATE_RESULTCODE, data: { rc: res } };
};

export const loadMainContent = (search: Suche) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      let result = await mainaxios.post<ResultObj<Uebersicht>>(config.backendUrl + "/v1/loadMainContent", search);
      dispatch(changeLoadScreen(false));
      dispatch({ type: Actions.CONTENT_LOAD_CONTENT, data: result.data.data });
    } catch (exx) {
      resolveFailure(dispatch, exx, { type: Actions.CONTENT_LOAD_CONTENT, data: undefined });
    }
  };
};
