import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadFirmen, deleteEmailzugang, updateEmailzugang, testEmailzugang } from "../../../actions";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ResultToast } from "../../parts/ResultToast/ResultToast";
import { Emailzugang } from "../../../types/remote/Emailzugang";
import { generateCompany, generateEmailzugang } from "../../../helper/firmenHelper";
import { EmailzugangDialog } from "../../parts/EmailzugangDialog/EmailzugangDialog";
import { isPop3Active } from "../../../helper/helper";
import { EmailcheckerDialog } from "../../parts/emailcheckerdialog/emailcheckerdialog";
import { ProgressBar } from "primereact/progressbar";

interface EmailzugaengeProps {
  content: ContentState;
  loadFirmen: Function;
  deleteEmailzugang: Function;
  updateEmailzugang: Function;
  testEmailzugang: Function;
}

interface EmailzugaengeState {
  emailzugang?: Emailzugang;
  emailzugangCleanup?: Emailzugang;
}

class _Emailzugaenge extends React.Component<EmailzugaengeProps, EmailzugaengeState> {
  constructor(props: EmailzugaengeProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadFirmen(undefined, true);
  }

  render() {
    return (
      <div className="card">
        <ConfirmDialog />
        <ResultToast successAction={() => this.props.loadFirmen(undefined, true)} />
        <EmailcheckerDialog
          emailzugang={this.state.emailzugangCleanup}
          onHide={() => this.setState({ ...this.state, emailzugangCleanup: undefined })}
        />
        <EmailzugangDialog close={() => this.setState({ ...this.state, emailzugang: undefined })} emailzugang={this.state.emailzugang} />
        <HeaderMenu
          changeCompanyAction={() => {}}
          action={() =>
            this.setState({
              ...this.state,
              emailzugang: generateEmailzugang(this.props.content.selectedCompany ? this.props.content.selectedCompany : generateCompany()),
            })
          }
        />
        <DataTable
          sortField="EVID"
          sortOrder={-1}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={
            this.props.content.selectedCompany && this.props.content.selectedCompany.emailzugaenge
              ? this.props.content.selectedCompany.emailzugaenge.map((element: Emailzugang) => {
                  return {
                    Email: element.Email,
                    Anzeigename: element.Anzeigename,
                    Hostname: element.Hostadresse,
                    Quota: element.quota ? <ProgressBar value={Math.round(element.quota * 100)}></ProgressBar> : "unknown",
                    active: (
                      <InputSwitch
                        checked={element.active > 0 ? true : false}
                        onChange={(e) => {
                          const temp = { ...element, active: e.value ? 1 : 0 };
                          this.props.updateEmailzugang(temp);
                        }}
                      />
                    ),
                    func: (
                      <div className="buttonSpacer">
                        <div className="p-1">
                          <Button
                            onClick={() => {
                              this.props.testEmailzugang(element);
                            }}
                            tooltip="Test Emailzugang"
                            icon="pi pi-question"
                            className="p-button-rounded p-button-helper"
                          />
                        </div>
                        <div className="p-1">
                          <Button
                            onClick={() => {
                              this.setState({ ...this.state, emailzugang: element });
                            }}
                            tooltip="Edit Emailzugang"
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info"
                          />
                        </div>
                        <div className="p-1">
                          <Button
                            onClick={() => {
                              this.props.updateEmailzugang(element);
                            }}
                            tooltip="Send Testmail"
                            icon="pi pi-send"
                            className="p-button-rounded p-button-warning"
                          />
                        </div>
                        {isPop3Active(element) ? (
                          <div className="p-1">
                            <Button
                              onClick={() => {
                                this.setState({ ...this.state, emailzugangCleanup: element });
                              }}
                              tooltip="Cleanup Maildelivery"
                              icon="pi pi-eraser"
                              className="p-button-rounded p-button-warning"
                            />
                          </div>
                        ) : undefined}
                        <div className="p-1">
                          <Button
                            onClick={() => {
                              confirmDialog({
                                message: `Soll ${element.Email} wirklich gelöscht werden?`,
                                header: "Delete Emailzugang",
                                icon: "pi pi-info-circle",
                                acceptClassName: "p-button-danger",
                                accept: () => {
                                  this.props.deleteEmailzugang(element);
                                },
                              });
                            }}
                            tooltip="DELETE Emailzugang"
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-error"
                          />
                        </div>
                      </div>
                    ),
                  };
                })
              : []
          }
        >
          <Column style={{ width: "10%" }} field="Email" header="Email"></Column>
          <Column field="Anzeigename" header="Anzeigename"></Column>
          <Column field="Hostname" header="Hostname"></Column>
          <Column field="Quota" header="Quota"></Column>
          <Column field="active" header="active"></Column>
          <Column style={{ width: "100px" }} field="func" header="Aktionen"></Column>
        </DataTable>
        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const Emailzugaenge = connect(mapStateToProps, {
  loadFirmen,
  deleteEmailzugang,
  updateEmailzugang,
  testEmailzugang,
})(_Emailzugaenge);
