import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { uploadEmailvorlagen, updateResultCode } from "../../../actions";
import { Button } from "primereact/button";
import { Firma } from "../../../types/remote/Firma";

interface EmailvorlagenDialogProps {
  open: boolean;
  close: Function;
  content: ContentState;
  updateResultCode: typeof updateResultCode;
  uploadEmailvorlagen: Function;
}

interface EmailvorlagenDialogState {
  name: string;
  filepath: string;
}

export class _EmailvorlagenDialog extends React.Component<EmailvorlagenDialogProps, EmailvorlagenDialogState> {
  constructor(props: EmailvorlagenDialogProps) {
    super(props);

    this.state = { name: "", filepath: "" };
  }

  isValid() {
    if (this.state.name.length > 0 && this.state.filepath.length > 0) {
      return true;
    }
    return false;
  }

  uploadForm(company: Firma) {
    var formData = new FormData();
    var imagefile: any = document.querySelector("#filename");
    if (imagefile) formData.append("template", imagefile.files[0]);
    formData.append("firma", `{"FID":${company.FID}}`);
    formData.append("Bezeichnung", this.state.name);
    this.props.uploadEmailvorlagen(formData);
  }

  componentDidUpdate(prevProps: EmailvorlagenDialogProps) {
    if (prevProps.open && !this.props.open) {
      this.setState({ name: "", filepath: "" });
    }
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />
              <Button
                disabled={!this.isValid()}
                label="Hochladen"
                icon="pi pi-check"
                type="submit"
                onClick={() => {
                  this.props.content.selectedCompany
                    ? this.uploadForm(this.props.content.selectedCompany)
                    : this.props.updateResultCode(-1);
                  this.props.close();
                }}
              />
            </div>
          }
          header="Neue Emailvorlagen hinzufügen"
          visible={this.props.open}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="emailvorlage-name" className="field col-12 md:col-2 labelcenter">
                Name
              </label>

              <div className="field col-12 md:col-10">
                <InputText
                  value={this.state.name}
                  type="text"
                  onChange={(e: any) => {
                    this.setState({ ...this.state, name: e.target.value });
                  }}
                  className={`${this.state.name.length <= 1 ? "p-invalid" : ""} w-full`}
                  id="emailvorlage-name"
                  maxLength={50}
                  name="Bezeichnung"
                  placeholder="Name der Emailvorlage"
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="filename" className="field col-12 md:col-2 labelcenter">
                Emaildatei
              </label>
              <div className="field col-12 md:col-10">
                <input
                  className={`${this.state.filepath.length <= 1 ? "p-invalid" : ""} w-full`}
                  onChange={(e) => this.setState({ ...this.state, filepath: e.target.value })}
                  value={this.state.filepath}
                  id="filename"
                  type="file"
                  name="template"
                  accept=".eml,.msg"
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const EmailvorlagenDialog = connect(mapStateToProps, { uploadEmailvorlagen, updateResultCode })(_EmailvorlagenDialog);
