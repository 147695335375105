import React from "react";

import { Card } from "primereact/card";
import { Paginator } from "primereact/paginator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Search } from "../../parts/search";
import { Button } from "primereact/button";
import {
  loadMainContent,
  sucheSetPageAction,
  sucheSetLimitAction,
  loadCollections,
  changePage,
  getDataset,
  loadFirmen,
} from "../../../actions";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { connect } from "react-redux";
import { Dataset } from "../../../types/remote/Dataset";
import { Page } from "../../../types/local/pages";
import { StatusDialog } from "../../parts/nlStatusDialog";

import "./style.css";

interface UebersichtProps {
  content: ContentState;
  loadMainContent: Function;
  sucheSetPageAction: typeof sucheSetPageAction;
  sucheSetLimitAction: typeof sucheSetLimitAction;
  route: typeof changePage;
  loadCollections: Function;
  getDataset: Function;
  loadFirmen: Function;
}

interface UebersichtState {
  currentPage: number;
  rowsPerPage: number;
  data: ExtendedDataset[];
  dataset?: Dataset;
}

interface ExtendedDataset extends Dataset {
  dataset?: Dataset;
  action?: JSX.Element;
}

class _Uebersicht extends React.Component<UebersichtProps, UebersichtState> {
  constructor(props: UebersichtProps) {
    super(props);
    this.state = { currentPage: 0, rowsPerPage: 100, data: [] };
  }

  componentDidMount() {
    this.props.loadCollections();
    this.props.loadFirmen();
    this.props.loadMainContent(this.props.content.suche);
  }

  componentDidUpdate(prevProps: UebersichtProps) {
    if (prevProps.content.uebersicht !== this.props.content.uebersicht) {
      let newData: ExtendedDataset[] = [];

      this.props.content.uebersicht.data.forEach((dataset) => {
        let temp: ExtendedDataset = Object.assign({}, dataset);

        temp.action = (
          <div className="buttonSpacer">
            <div className="p-1">
              <Button
                onClick={async () => {
                  await this.props.getDataset(temp);
                  this.props.route(Page.DATASET);
                }}
                icon="pi pi-id-card"
                className="p-button-rounded p-button-info"
                tooltip="Kundendaten"
              />
            </div>
            <div className="p-1">
              <Button
                className="p-button-rounded p-button-warning"
                icon="pi pi-comments"
                tooltip="Vorgänge anzeigen"
                onClick={async () => {
                  await this.props.getDataset(temp);
                  this.props.route(Page.VORGAENGE);
                }}
              />
            </div>
            <div className="p-1">
              <Button
                onClick={() => {
                  this.setState({ ...this.state, dataset: dataset });
                }}
                icon="pi pi-envelope"
                tooltip="Status des Kontaktes"
                className="p-button-rounded p-button-success"
              />
            </div>
          </div>
        );

        newData.push(temp);
      });

      this.setState({
        currentPage: this.state.currentPage,
        rowsPerPage: this.state.rowsPerPage,
        data: newData,
      });
    }
  }

  render() {
    return (
      <div className="card">
        <Search></Search>
        <StatusDialog dataset={this.state.dataset} close={() => this.setState({ ...this.state, dataset: undefined })} />
        <div className="uebersicht-tablecontrol">Anzahl Datensätze: {this.props.content.uebersicht.amountOfAllRecords}</div>
        {this.state.rowsPerPage < this.props.content.uebersicht.amountOfAllRecords ? (
          <Paginator
            first={this.props.content.suche.currentPage}
            rows={this.props.content.suche.rowsPerView}
            totalRecords={this.props.content.uebersicht.amountOfAllRecords}
            rowsPerPageOptions={[5, 50, 100, 200, 1000]}
            onPageChange={(e) => {
              this.props.sucheSetPageAction(e.first);
              this.props.sucheSetLimitAction(e.rows);
              this.props.loadMainContent(this.props.content.suche);
            }}
          ></Paginator>
        ) : null}

        {this.props.content.uebersicht.amountOfAllRecords === 0 ? (
          <Card title="Hinweis" style={{ width: "100%", marginBottom: "2em" }}>
            <p className="p-m-0" style={{ lineHeight: "1.5" }}>
              Für Ihre aktuelle Suche konnte keine Datensätze gefunden werden.
            </p>
          </Card>
        ) : (
          <DataTable emptyMessage={"Wird geladen, einen Moment bitte..."} value={this.state.data}>
            <Column field="KID" header="KID" style={{ width: "10%" }}></Column>
            <Column field="Firmenname" header="Firma" sortable></Column>
            <Column field="Email" header="Email" sortable></Column>
            <Column field="action" style={{ width: "100px" }}></Column>
          </DataTable>
        )}
        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const Uebersicht = connect(mapStateToProps, {
  loadMainContent,
  sucheSetPageAction,
  sucheSetLimitAction,
  loadCollections,
  getDataset,
  route: changePage,
  loadFirmen,
})(_Uebersicht);
