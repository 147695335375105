export interface VorgangSearchOptions {
  KAPID?: number;
  start?: number;
  vorgangstyp?: VORGANG_TYPES;
  limit: number;
}

export enum VORGANG_TYPES {
  MAILDELIVERY_SORTER = "MAILDELIVERY_SORTER",
  MAILDELIVERY_SORTER_UNKNOWN = "MAILDELIVERY_SORTER_UNKNOWN",
  EMAIL_SEND = "EMAIL_SEND",
  EMAIL_SEND_FAILED = "EMAIL_SEND_FAILED",
}
