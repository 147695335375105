export enum Page {
  LOGIN = "Login",
  DATASET = "Datensatz",
  OVERVIEW = "Übersicht",
  COLLECTIONS = "Kollektionen-Verwaltung",
  COMPANIES = "Firmenübersicht",
  BENUTZER = "Benutzerverwaltung",
  CAMPAIN = "Kampagne",
  EMAILS = "Emailvorlagen",
  EMAILZUGAENGE = "Emailzugänge",
  IMPORT = "Import",
  EXPORT = "Export",
  VORGAENGE = "Vorgänge",
  NEW_DATASET = "Neuer Datensatz",
}
