import React from "react";
import { connect } from "react-redux";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import datetime from "date-and-time";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadImports, startImport, setSelectedCompany } from "../../../actions";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { Import } from "../../../types/remote/import";
import { getStatusName } from "../../../helper/helper";
import { ProgressBar } from "primereact/progressbar";
import { JobStatus } from "../../../types/remote/JobStatus";
import { ResultToast } from "../../parts/ResultToast/ResultToast";
import { ImportDialog } from "../../parts/ImportDialog/ImportDialog";

interface ImportProps {
  content: ContentState;
  loadImports: Function;
  startImport: Function;
  setSelectedCompany: Function;
}

interface ImportState {
  import: boolean;
}

class _Imports extends React.Component<ImportProps, ImportState> {
  constructor(props: ImportProps) {
    super(props);
    this.state = { import: false };
  }

  componentDidMount() {
    this.props.loadImports(this.props.content.selectedCompany);
  }

  componentDidUpdate(prevProps: ImportProps) {
    if (prevProps.content.selectedCompany !== this.props.content.selectedCompany) {
      this.props.loadImports(this.props.content.selectedCompany);
    }
  }

  componentWillUnmount() {
    if (this.props.content.firmen.length > 0 && !this.props.content.selectedCompany)
      this.props.setSelectedCompany(this.props.content.firmen[0]);
  }

  render() {
    return (
      <div className="card">
        <HeaderMenu
          resetPossible
          changeCompanyAction={() => {}}
          action={() => {
            this.setState({ ...this.state, import: true });
          }}
        />

        <ResultToast successAction={() => this.props.loadImports(this.props.content.selectedCompany)} />

        <ImportDialog close={() => this.setState({ ...this.state, import: false })} open={this.state.import} />

        <DataTable
          sortField="ImportID"
          sortOrder={-1}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={this.props.content.imports.map((val: Import) => {
            const temp: any = { ...val };

            //{"emailIndex":1,"firmennameIndex":2,"statusIndex":-1,"lineAmount":10,"splitter":",","fieldMarker":"\""}
            temp.data = (
              <div>
                <b>Spalte Emailspalte: </b>{" "}
                {typeof val.lineDescriptor.emailIndex !== "undefined" ? val.lineDescriptor.emailIndex + 1 : "nicht vorhanden"}
                <br />
                <b>Spalte Firmenindex: </b>{" "}
                {val.lineDescriptor.firmennameIndex > 0 ? val.lineDescriptor.firmennameIndex + 1 : "nicht vorhanden"}
                <br />
                <b>Spalte NLStatus: </b>
                {val.lineDescriptor.statusIndex && val.lineDescriptor.statusIndex > 0
                  ? val.lineDescriptor.statusIndex + 1
                  : "nicht vorhanden"}
                <br />
                <b> Spalte CollectionIndex: </b>
                {val.lineDescriptor.collectionIndex && val.lineDescriptor.collectionIndex > 0
                  ? val.lineDescriptor.collectionIndex + 1
                  : "nicht vorhanden"}
                <br />
                <b>Anzahl Zeilen: </b>
                {val.lineDescriptor.lineAmount && val.lineDescriptor.lineAmount > 0 ? val.lineDescriptor.lineAmount : "nicht vorhanden"}
                <br />
                <b>Splitter / Field Marker: </b>
                {val.lineDescriptor.splitter} / {val.lineDescriptor.fieldMarker ? val.lineDescriptor.fieldMarker : "nicht vorhanden"}
                <br />
                <b>Change Existing: </b>
                {val.lineDescriptor.changeExisting ? "yes" : "no"}
              </div>
            );

            temp.statistic = (
              <div>
                <b>Hinzugefügt: </b> {val.added}
                <br />
                <b>Geändert: </b> {val.changed}
                <br />
                <b>Duplicates: </b> {val.duplicates}
                <br />
                <b>Fehler: </b> {val.errors}
                <br />
              </div>
            );

            let progress = 0;

            if (val.lineDescriptor.lineAmount) {
              progress = Math.round((val.currentDataset / val.lineDescriptor.lineAmount) * 100);

              if (progress > 100 || val.status === JobStatus.DONE) {
                progress = 100;
              }
            }

            temp.progress = <ProgressBar value={progress} />;

            temp.created = val.created ? datetime.format(new Date(parseInt(val.created)), "DD.MM.YYYY HH:mm") : "unbekannt";
            temp.finished =
              val.finished && val.finished !== "0" ? datetime.format(new Date(parseInt(val.finished)), "DD.MM.YYYY HH:mm") : "unbekannt";

            temp.action = (
              <div>
                {temp.status === JobStatus.RUNNING ? (
                  <div className="buttonSpacer">
                    <div className="p-1">
                      <Button
                        onClick={() => {
                          this.props.loadImports(this.props.content.selectedCompany);
                        }}
                        tooltip="Refresh"
                        icon="pi pi-refresh"
                        className="p-button-rounded p-button-info"
                      />
                    </div>
                    <div className="p-1">
                      <Button
                        onClick={() => {
                          this.props.startImport(temp);
                        }}
                        tooltip="Stop Import"
                        icon="pi pi-stop"
                        className="p-button-rounded p-button-warning"
                      />
                    </div>
                  </div>
                ) : undefined}
                {temp.status === JobStatus.ADDED ? (
                  <div className="p-1">
                    <Button
                      onClick={() => {
                        this.props.startImport(temp);
                      }}
                      tooltip="Start Import"
                      icon="pi pi-play"
                      className="p-button-rounded p-button-warning"
                    />
                  </div>
                ) : undefined}
              </div>
            );

            temp.stat = getStatusName(val.status);

            return temp;
          })}
        >
          <Column style={{ width: "5%" }} field="ImportID" header="ImportID"></Column>
          <Column field="data" header="Informationen"></Column>
          <Column field="statistic" header="Statistik"></Column>
          <Column field="progress" header="Fortschritt"></Column>
          <Column style={{ width: "10%" }} field="created" header="Erstellt"></Column>
          <Column style={{ width: "10%" }} field="finished" header="Fertig"></Column>
          <Column style={{ width: "10%" }} field="stat" header="Status"></Column>
          <Column style={{ width: "5%" }} field="action" header=""></Column>
        </DataTable>

        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const Imports = connect(mapStateToProps, {
  loadImports,
  startImport,
  setSelectedCompany,
})(_Imports);
