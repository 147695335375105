import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { Collection, ResponseCode, ResultObj } from "../../types/remote";
import { MessageType } from "../../types/local/messageData";
import { changeLoadScreen, showMessage } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";

export interface LoadCollectionAction {
  type: Actions.CONTENT_LOAD_COLLECTIONS;
  data: Collection[] | undefined;
}

export interface AddCollectionAction {
  type: Actions.CONTENT_ADD_COLLECTION;
  data: Collection | undefined;
}

export interface DeleteCollectionAction {
  type: Actions.CONTENT_DELETE_COLLECTION;
  data: Collection | undefined;
}

export interface ResetCollectionAction {
  type: Actions.RESET_COLLECTION;
}

export const loadCollections = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));
      let response = await mainaxios.get<ResultObj<Collection[]>>(config.backendUrl + "/v1/collections", {
        withCredentials: true,
      });
      dispatch(changeLoadScreen(false));
      dispatch<LoadCollectionAction>({
        data: response.data.data,
        type: Actions.CONTENT_LOAD_COLLECTIONS,
      });
    } catch (exx) {
      resolveFailure(dispatch, exx);
    }
  };
};

export const addCollection = (collection: Collection) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));
      let response = await mainaxios.post<ResultObj<Collection>>(config.backendUrl + "/v1/collections", collection, {
        withCredentials: true,
      });
      dispatch(changeLoadScreen(false));

      if (response.data.rc === ResponseCode.FAILED_DUPLICATE_ASSET) {
        dispatch(
          showMessage({
            detail: "Die Collection konnte nicht hinzugefügt werden, der gleiche Name ist bereits vorhanden.",
            summary: "Fehler: ",
            severity: MessageType.ERROR,
          })
        );
      } else if (response.data.rc === ResponseCode.SUCCESS) {
        dispatch(showMessage({ detail: "Collection hinzugefügt", summary: "Erfolg: ", severity: MessageType.SUCCESS }));
      } else {
        dispatch(
          showMessage({
            detail: "Die Collection konnte nicht hinzugefügt werden, bitte prüfen Sie die Eingaben.",
            summary: "Fehler: ",
            severity: MessageType.ERROR,
          })
        );
      }

      dispatch<AddCollectionAction>({
        data: response.data.data,
        type: Actions.CONTENT_ADD_COLLECTION,
      });
    } catch (exx) {
      resolveFailure(dispatch, exx, {
        data: undefined,
        type: Actions.CONTENT_ADD_COLLECTION,
      });
    }
  };
};

export const deleteCollection = (collection: Collection) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));
      let response = await mainaxios.delete<ResultObj<Collection>>(config.backendUrl + `/v1/collections/${collection.cid}`, {
        withCredentials: true,
      });
      dispatch(changeLoadScreen(false));

      if (response.data.rc === ResponseCode.SUCCESS) {
        dispatch(
          showMessage({
            detail: `Collection ${collection.name} gelöscht`,
            summary: "Erfolg: ",
            severity: MessageType.SUCCESS,
          })
        );

        collection.cid = -1;
        dispatch<DeleteCollectionAction>({
          data: collection,
          type: Actions.CONTENT_DELETE_COLLECTION,
        });
      } else {
        dispatch(
          showMessage({
            detail: "Die Collection konnte aufgrund eines technischen Fehlers nicht gelöscht werden.",
            summary: "Fehler: ",
            severity: MessageType.ERROR,
          })
        );
      }
    } catch (exx) {
      resolveFailure(dispatch, exx, {
        data: undefined,
        type: Actions.CONTENT_DELETE_COLLECTION,
      });
    }
  };
};

export const resetCollection = (): ResetCollectionAction => {
  return { type: Actions.RESET_COLLECTION };
};
