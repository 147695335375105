import React from "react";

import { Menubar } from "primereact/menubar";
import { Messages } from "primereact/messages";

import { StoreState } from "../../reducers";
import Logo from "../../logo.png";
import { Avatar } from "../parts/Avatar";
import { Uebersicht } from "./uebersicht";
import { DataEditor } from "./addData";
import { Collections } from "./collections";
import { Login } from "./login";
import { Page } from "../../types/local/pages";
import { connect } from "react-redux";
import { LoadingDialog } from "../parts/loadingScreen";
import { RouteState } from "../../reducers/router";
import { changePage, resetDataset } from "../../actions";
import { ConfirmationDialog } from "../parts/dialog";
import { BenutzerUebersicht } from "./benutzer";
import { FirmenUebersicht } from "./firmen";
import { KampagneUebersicht } from "./kampagne/kampagne";
import { EmailVorlage } from "./emails";

import { Emailzugaenge } from "./emailzugaenge";
import { Imports } from "./import";
import { Export } from "./export";
import { VorgaengeOverview } from "./vorgaenge/vorgaenge";

import "./styles.css";

interface AppProps {
  routeState: RouteState;
  route: typeof changePage;
  resetDataset: typeof resetDataset;
}

interface AppState {
  items: any[];
  showConfirmationDialog: boolean;
  message: any;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      message: "",
      showConfirmationDialog: false,
      items: [
        {
          page: Page.OVERVIEW,
          label: "Übersicht",
          icon: "pi pi-fw pi-microsoft",
          command: () => {
            this.props.route(Page.OVERVIEW);
          },
          disabled: false,
        },
        {
          label: "Daten",
          disabled: false,
          icon: "pi pi-fw pi-database",
          items: [
            {
              page: Page.DATASET,
              label: "Neuer Datensatz",
              icon: "pi pi-fw pi-plus",
              command: () => {
                this.props.resetDataset();
                this.props.route(Page.NEW_DATASET);
              },
              disabled: false,
            },
            {
              page: Page.IMPORT,
              label: "Imports",
              icon: "pi pi-fw pi-file-import",
              command: () => {
                this.props.route(Page.IMPORT);
              },
              disabled: false,
            },
            {
              page: Page.EXPORT,
              label: "Exports",
              icon: "pi pi-fw pi-file-export",
              command: () => {
                this.props.route(Page.EXPORT);
              },
              disabled: false,
            },
            {
              page: Page.VORGAENGE,
              label: "Vorgaenge",
              icon: "pi pi-fw pi-comments",
              command: () => {
                this.props.resetDataset();
                this.props.route(Page.VORGAENGE);
              },
              disabled: false,
            },
          ],
        },
        {
          page: Page.CAMPAIN,
          label: "Kampagne starten",
          icon: "pi pi-fw pi-caret-right",
          disabled: false,
          command: () => {
            //this.setState({
            //  showConfirmationDialog: true,
            //  items: this.state.items,
            //});
            this.props.route(Page.CAMPAIN);
          },
        },
        {
          page: Page.EMAILS,
          label: "Emailvorlagen",
          icon: "pi pi-fw pi-envelope",
          disabled: false,
          command: () => {
            this.props.route(Page.EMAILS);
          },
        },
        {
          label: "Strukturen",
          disabled: false,
          icon: "pi pi-fw pi-slack",
          items: [
            {
              page: Page.COLLECTIONS,
              label: "Collections",
              disabled: false,
              icon: "pi pi-fw pi-th-large",
              command: () => {
                this.props.route(Page.COLLECTIONS);
              },
            },
            {
              page: Page.COMPANIES,
              label: "Firmen",
              icon: "pi pi-fw pi-briefcase",
              disabled: false,
              command: () => {
                this.props.route(Page.COMPANIES);
              },
            },
            {
              page: Page.EMAILZUGAENGE,
              label: "Emailzugänge",
              icon: "pi pi-fw pi-id-card",
              disabled: false,
              command: () => {
                this.props.route(Page.EMAILZUGAENGE);
              },
            },
            {
              page: Page.BENUTZER,
              label: "Benutzer",
              icon: "pi pi-fw pi-users",
              disabled: false,
              command: () => {
                this.props.route(Page.BENUTZER);
              },
            },
          ],
        },
      ],
    };
  }

  componentDidUpdate(prevProps: AppProps) {
    if (prevProps.routeState.page !== this.props.routeState.page) {
      let newState = Object.assign({}, this.state);
      newState.items.forEach((val) => {
        if (val.items) {
          val.items.forEach((val2: any) => {
            if (val2.page === this.props.routeState.page) {
              val2.disabled = true;
            } else {
              val2.disabled = false;
            }
          });
        } else {
          if (val.page === this.props.routeState.page && val.page !== Page.DATASET) {
            val.disabled = true;
          } else {
            val.disabled = false;
          }
        }
      });
      this.setState(newState);
    } else if (this.props.routeState.message && this.props.routeState.message !== prevProps.routeState.message) {
      let temp = Object.assign({ life: 5000 }, this.props.routeState.message);
      this.state.message.show(temp);
    }
  }

  render() {
    return (
      <div>
        <Messages
          ref={(el) => {
            if (this.state.message === "") {
              this.setState({
                items: this.state.items,
                showConfirmationDialog: this.state.showConfirmationDialog,
                message: el,
              });
            }
          }}
          className="bring-to-front"
        />
        {this.props.routeState.page !== Page.LOGIN ? (
          <div className="app-menubar-background">
            <Menubar className="app-menubar" model={this.state.items} end={() => <Avatar />} />
          </div>
        ) : null}

        <div className="app-page-container">
          <div className="app-page-container-inner">
            {this.props.routeState.page === Page.LOGIN ? (
              <div className="app-logo-container">
                <img className="app-logo" src={Logo} alt="nl-tool logo" />
              </div>
            ) : null}
            {this.props.routeState.page === Page.OVERVIEW ? <Uebersicht></Uebersicht> : null}
            {this.props.routeState.page === Page.DATASET || this.props.routeState.page === Page.NEW_DATASET ? (
              <DataEditor></DataEditor>
            ) : null}
            {this.props.routeState.page === Page.COLLECTIONS ? <Collections></Collections> : null}
            {this.props.routeState.page === Page.BENUTZER ? <BenutzerUebersicht /> : null}
            {this.props.routeState.page === Page.COMPANIES ? <FirmenUebersicht /> : null}
            {this.props.routeState.page === Page.CAMPAIN ? <KampagneUebersicht /> : null}
            {this.props.routeState.page === Page.EMAILZUGAENGE ? <Emailzugaenge /> : null}
            {this.props.routeState.page === Page.LOGIN ? <Login /> : null}
            {this.props.routeState.page === Page.EMAILS ? <EmailVorlage /> : null}
            {this.props.routeState.page === Page.IMPORT ? <Imports /> : null}
            {this.props.routeState.page === Page.EXPORT ? <Export /> : null}
            {this.props.routeState.page === Page.VORGAENGE ? <VorgaengeOverview /> : null}
          </div>
        </div>
        {this.props.routeState.showLoadingDialog ? <LoadingDialog /> : null}
        <ConfirmationDialog
          visible={this.state.showConfirmationDialog}
          content={"Diese Funktion steht im Moment noch nicht zur Verfügung."}
          yesCaption="OK"
          onClickYes={() => {
            this.setState({
              showConfirmationDialog: false,
              items: this.state.items,
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ route }: StoreState): { routeState: RouteState } => {
  return { routeState: route };
};

export const App = connect(mapStateToProps, { route: changePage, resetDataset })(_App);
