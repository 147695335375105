import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { ResultObj } from "../../types/remote";

import { changeLoadScreen } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { Import } from "../../types/remote/import";
import { ExportOptions } from "../../types/remote/ExportOptions";
import fileDownload from "js-file-download";

export interface LoadImportsAction {
  type: Actions.CONTENT_LOAD_IMPORTS;
  data: Import[] | undefined;
}

export const startExport = (exportOptions: ExportOptions) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<Import[]>>(`${config.backendUrl}/v1/export`, exportOptions);
      dispatch(changeLoadScreen(false));
      if (typeof response.data === "string") fileDownload(response.data, "report.csv");
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};
