import { Actions } from "../../actions";
import { LoadscreenAction, RouteAction, ShowMessageAction } from "../../actions/router";
import { Page } from "../../types/local/pages";
import { MessageData } from "../../types/local/messageData";

export interface RouteState {
  page: Page;
  showLoadingDialog: boolean;
  message?: MessageData;
}

export const RouteReducer = (
  state: RouteState = { page: Page.LOGIN, showLoadingDialog: false },
  action: RouteAction | LoadscreenAction | ShowMessageAction
): RouteState => {
  if (action.type === Actions.ROUTE) {
    let newState = Object.assign({}, state);
    newState.page = action.data;
    return newState;
  } else if (action.type === Actions.CHANGE_LOAD_SCREEN) {
    let newState = Object.assign({}, state);
    newState.showLoadingDialog = action.data;
    return newState;
  } else if (action.type === Actions.SHOW_MESSAGE) {
    let newState = Object.assign({}, state);
    newState.message = action.data;
    return newState;
  }
  return state;
};
