export enum ResponseCode {
  SUCCESS = 0,
  FAILED_MISSING_ATTRIBUTES = -1,
  FAILED_LOGIN_EMAIL_PASSWORD_INVALID = -2,
  FAILED_DATABASE_ERROR = -3,
  FAILED_ATTRIBUTES_WRONG_CONTENT = -4,
  FAILED_NO_SESSION = -5,
  FAILED_DUPLICATE_ASSET = -6,
  FAILED_GENERAL_ERROR = -18,
  FAILED_NO_CONNECT = -17,
  FAILED_NOT_CORRECT_RIGHTS = -16,
}
