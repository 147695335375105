export const userdata = {
  lid: 1,
  Vorname: "Ralph",
  Nachname: "Pohl",
  Email: "rpohl@mh-schleswig.de",
  admin: 1,
  creator: 0,
  created: "",
  lastlogin: "1694763299388",
};
