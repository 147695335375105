import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";

import { changeLoadScreen } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { Firma } from "../../types/remote/Firma";
import { ResultObj } from "../../types/remote";
import { Emailvorlage } from "../../types/remote/Emailvorlage";
import { updateResultCode } from ".";
import fileDownload from "js-file-download";

export interface LoadEmailvorlagenAction {
  type: Actions.CONTENT_LOAD_EMAILVORLAGEN;
  data: Emailvorlage[] | undefined;
}

export const loadEmailvorlagen = (firma: Firma) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Emailvorlage[]>>(`${config.backendUrl}/v1/emailvorlage/${firma.FID}`, {
        withCredentials: true,
      });
      dispatch(changeLoadScreen(false));
      dispatch<LoadEmailvorlagenAction>({
        data: response.data.data,
        type: Actions.CONTENT_LOAD_EMAILVORLAGEN,
      });
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const uploadEmailvorlagen = (formData: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<Emailvorlage>>(`${config.backendUrl}/v1/emailvorlage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
      dispatch(updateResultCode(-1));
    }
  };
};

export const getEmailPDF = (email: Emailvorlage) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Emailvorlage>>(`${config.backendUrl}/v1/emailvorlage/pdf/${email.EVID}`);
      dispatch(changeLoadScreen(false));
      if (typeof response.data === "string") fileDownload(response.data, `Email_${email.EVID}.pdf`);
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
      dispatch(updateResultCode(-1));
    }
  };
};

export const deleteEmailvorlagen = (emailvorlage: Emailvorlage) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.delete<ResultObj<Emailvorlage>>(`${config.backendUrl}/v1/emailvorlage/${emailvorlage.EVID}`);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
      dispatch(updateResultCode(-1));
    }
  };
};
