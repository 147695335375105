import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { sendTestmail, updateResultCode } from "../../../actions";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Emailzugang } from "../../../types/remote/Emailzugang";
import { checkEmail } from "../../../helper/validate";
import { ValidEnum } from "../../../types/local/ValidEnum";
import { Emailvorlage } from "../../../types/remote/Emailvorlage";

interface TestmailDialogProps {
  emailvorlage?: Emailvorlage;
  close: Function;
  content: ContentState;
  updateResultCode: typeof updateResultCode;
  sendTestmail: Function;
}

interface TestmailDialogState {
  name: string;
  emailzugang?: Emailzugang;
}

export class _TestmailDialog extends React.Component<TestmailDialogProps, TestmailDialogState> {
  constructor(props: TestmailDialogProps) {
    super(props);

    this.state = { name: "" };
  }

  componentDidUpdate(prevProps: TestmailDialogProps) {
    //Initial set emailaccount
    if (
      !prevProps.emailvorlage &&
      this.props.emailvorlage &&
      !this.state.emailzugang &&
      this.props.content.selectedCompany &&
      this.props.content.selectedCompany.emailzugaenge.length > 0
    ) {
      this.setState({ ...this.state, emailzugang: this.props.content.selectedCompany.emailzugaenge[0] });
    }

    //reset emailaccount during company change
    if (prevProps.content.selectedCompany !== this.props.content.selectedCompany) {
      this.setState({ ...this.state, emailzugang: undefined });
    }
  }

  isValid() {
    if (checkEmail(this.state.name) === ValidEnum.VALID && this.state.emailzugang) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />
              <Button
                disabled={!this.isValid()}
                label="Absenden"
                icon="pi pi-check"
                type="submit"
                onClick={() => {
                  if (this.isValid() && this.state.emailzugang && this.props.emailvorlage) {
                    this.props.sendTestmail(this.state.emailzugang, this.state.name, this.props.emailvorlage);
                  }
                  this.props.close();
                }}
              />
            </div>
          }
          header={`${this.props.emailvorlage ? this.props.emailvorlage.Bezeichnung : ""} als Testmail versenden`}
          visible={this.props.emailvorlage !== undefined}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-3 labelcenter">
                Empfänger-Email
              </label>

              <div className="field col-12 md:col-9">
                <InputText
                  value={this.state.name}
                  type="text"
                  onChange={(e: any) => {
                    this.setState({ ...this.state, name: e.target.value });
                  }}
                  className={`${checkEmail(this.state.name) === ValidEnum.VALID ? "" : "p-invalid"} w-full`}
                  id="kampagne-name"
                  maxLength={50}
                  name="Bezeichnung"
                  placeholder="Empfängeremail"
                />
              </div>
            </div>

            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-3 labelcenter">
                Emailpostfach
              </label>

              <div className="field col-12 md:col-9">
                <Dropdown
                  id="collection"
                  onChange={(e: any) => {
                    this.setState({ ...this.state, emailzugang: e.value });
                  }}
                  className={`${this.state.emailzugang ? "" : "p-invalid"} w-full`}
                  value={this.state.emailzugang}
                  optionLabel="Email"
                  placeholder="Emailpostfach auswählen"
                  options={this.props.content.selectedCompany ? this.props.content.selectedCompany.emailzugaenge : []}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const TestmailDialog = connect(mapStateToProps, { sendTestmail, updateResultCode })(_TestmailDialog);
