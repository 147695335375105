import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";

import { changeLoadScreen } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { Firma } from "../../types/remote/Firma";
import { ResultObj } from "../../types/remote";
import { updateResultCode } from ".";

export interface LoadFirmenAction {
  type: Actions.CONTENT_LOAD_FIRMEN;
  data: Firma[] | undefined;
}

export interface SelectedCompanyAction {
  type: Actions.LOCAL_SET_COMPANY;
  data: Firma | undefined;
}

export const loadFirmen = (withStats?: boolean, extended?: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Firma[]>>(
        `${config.backendUrl}/v1/firmen${withStats ? "?withStats=true" : ""}${extended ? "?extended=true" : ""}`
      );
      dispatch(changeLoadScreen(false));
      dispatch<LoadFirmenAction>({
        data: response.data.data,
        type: Actions.CONTENT_LOAD_FIRMEN,
      });
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const setSelectedCompany = (firma: Firma): SelectedCompanyAction => {
  return {
    data: firma,
    type: Actions.LOCAL_SET_COMPANY,
  };
};

export const addFirma = (newCompany: Firma, refCompany: Firma) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<Firma>>(`${config.backendUrl}/v1/firma`, { firma: newCompany, reference: refCompany });
      dispatch(changeLoadScreen(false));
      if (response.data.rc !== 0) {
        resolveFailure(dispatch, response.data.description);
      }
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const deleteFirma = (company: Firma) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.delete<ResultObj<Firma>>(`${config.backendUrl}/v1/firma/${company.FID}`);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const updateFirma = (company: Firma) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.put<ResultObj<Firma>>(`${config.backendUrl}/v1/firma`, company);
      dispatch(changeLoadScreen(false));
      if (response.data.rc !== 0) {
        resolveFailure(dispatch, response.data.description);
      }
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};
