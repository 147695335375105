import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { ResultObj } from "../../types/remote";

import { changeLoadScreen } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { updateResultCode } from ".";
import { Import } from "../../types/remote/import";
import { Firma } from "../../types/remote/Firma";

export interface LoadImportsAction {
  type: Actions.CONTENT_LOAD_IMPORTS;
  data: Import[] | undefined;
}

export const loadImports = (company?: Firma) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Import[]>>(`${config.backendUrl}/v1/imports${company ? "/" + company.FID : ""}`);
      dispatch(changeLoadScreen(false));
      dispatch<LoadImportsAction>({
        data: response.data.data,
        type: Actions.CONTENT_LOAD_IMPORTS,
      });
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
      dispatch<LoadImportsAction>({
        data: [],
        type: Actions.CONTENT_LOAD_IMPORTS,
      });
    }
  };
};

export const startImport = (imp: Import) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Import[]>>(`${config.backendUrl}/v1/import/start/${imp.ImportID}`);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const uploadImport = (formData: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<String>>(`${config.backendUrl}/v1/import/adresses`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
      dispatch(updateResultCode(-1));
    }
  };
};
