import { Dialog } from "primereact/dialog";
import React from "react";
import { loadNLStatus, changeLoadScreen, changeNLStatus } from "../../../actions/index";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { parseDate } from "../../../helper/helper";
import { Button } from "primereact/button";
import { NLStatusEnum } from "../../../types/remote/NLStatus";
import { Dataset } from "../../../types/remote";

interface StatusDialogProps {
  content: ContentState;

  close: Function;
  loadNLStatus: Function;
  changeLoadScreen: typeof changeLoadScreen;
  changeNLStatus: Function;
  dataset?: Dataset;
}

const stati = [
  { label: "aktiv", value: "0" },
  { label: "manuell gelöscht", value: "1" },
  { label: "Hardbounce", value: "2" },
  { label: "Softbounce", value: "3" },
  { label: "SPAM", value: "4" },
  { label: "unbekannt", value: "5" },
  { label: "gelöscht Abmeldelink", value: "6" },
];

export class RStatusDialog extends React.Component<StatusDialogProps> {
  componentDidUpdate(prevProps: StatusDialogProps) {
    if (!prevProps.dataset && this.props.dataset) {
      this.props.loadNLStatus(this.props.dataset);
    }
  }

  render() {
    let res: any[] = [];

    if (this.props.dataset && this.props.dataset.emailstatus) {
      res = this.props.dataset.emailstatus.map((element) => {
        return {
          ...element,
          inputfield: (
            <Dropdown
              value={element.status.toString()}
              options={stati}
              onChange={(e) => {
                this.props.changeNLStatus(element, e.value, this.props.content.dataset);
              }}
              placeholder="Emailstatus wählen"
              scrollHeight="300px"
            />
          ),
          lastdate: element.letzteAenderung ? parseDate(new Date(element.letzteAenderung)) : "unbekannt",
          contractor: element.UID ? `${element.UID.Vorname} ${element.UID.Nachname}` : "unbekannt",
        };
      });
    }

    return (
      <Dialog
        header={`Emailstatus für ${this.props.content.dataset?.Email}`}
        visible={this.props.dataset !== undefined}
        style={{ width: "60vw" }}
        onHide={() => {
          this.props.close();
        }}
        footer={
          <div>
            <Button
              label="Alle auf HARDBOUNCE"
              onClick={async () => {
                if (
                  this.props.content.dataset &&
                  this.props.content.dataset.emailstatus &&
                  this.props.content.dataset.emailstatus.length > 0
                ) {
                  for (let i = 0; i < this.props.content.dataset.emailstatus.length; i++) {
                    await this.props.changeNLStatus(
                      this.props.content.dataset.emailstatus[i],
                      NLStatusEnum.HARDBOUNCE,
                      this.props.content.dataset
                    );
                  }
                }
              }}
            />
            <Button
              label="Alle auf Gelöscht"
              onClick={async () => {
                if (
                  this.props.content.dataset &&
                  this.props.content.dataset.emailstatus &&
                  this.props.content.dataset.emailstatus.length > 0
                ) {
                  for (let i = 0; i < this.props.content.dataset.emailstatus.length; i++) {
                    await this.props.changeNLStatus(
                      this.props.content.dataset.emailstatus[i],
                      NLStatusEnum.GELOESCHT,
                      this.props.content.dataset
                    );
                  }
                }
              }}
            />
          </div>
        }
      >
        <DataTable value={res}>
          <Column field="NLSID" header="NLSID" style={{ width: "10%" }}></Column>
          <Column field="FID.Kurzform" header="Firma"></Column>
          <Column field="inputfield" header="Emailstatus"></Column>
          <Column field="lastdate" header="Zuletzt geändert"></Column>
          <Column field="contractor" header="geändert durch"></Column>
        </DataTable>
      </Dialog>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const StatusDialog = connect(mapStateToProps, {
  loadNLStatus,
  changeLoadScreen,
  changeNLStatus,
})(RStatusDialog);
