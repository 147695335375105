import { Dispatch } from "redux";
import { mainaxios } from "..";
import { config } from "../../config/config";

import { changeLoadScreen, showMessage } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { Firma } from "../../types/remote/Firma";
import { ResultObj } from "../../types/remote";
import { updateResultCode } from ".";
import { Emailzugang } from "../../types/remote/Emailzugang";
import { MessageType } from "../../types/local/messageData";

export const updateEmailzugang = (emailzugang: Emailzugang) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.put<ResultObj<Firma>>(`${config.backendUrl}/v1/firmen/emailzugang`, emailzugang);
      dispatch(changeLoadScreen(false));

      if (response.data.rc !== 0) {
        dispatch(
          showMessage({
            detail: response.data.description,
            summary: "Update Emailzugang fehlgeschlagen",
            severity: MessageType.ERROR,
          })
        );
      }
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const deleteEmailzugang = (emailzugang: Emailzugang) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.delete<ResultObj<String>>(`${config.backendUrl}/v1/firmen/emailzugang/${emailzugang.Email}`);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const testEmailzugang = (emailzugang: Emailzugang) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<String>>(`${config.backendUrl}/v1/firmen/emailzugang/test`, emailzugang);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const addEmailzugang = (emailzugang: Emailzugang) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<String>>(`${config.backendUrl}/v1/firmen/emailzugang`, emailzugang);
      dispatch(changeLoadScreen(false));
      if (response.data.rc !== 0) {
        dispatch(
          showMessage({
            detail: response.data.description,
            summary: "Update Emailzugang fehlgeschlagen",
            severity: MessageType.ERROR,
          })
        );
      }
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};
