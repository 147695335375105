import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { config } from "../config/config";
import { benutzer } from "../testdata/benutzer";
import { collections } from "../testdata/collections";
import { dashboardData } from "../testdata/dashboard";
import { dataset } from "../testdata/dataset";
import { emailvorlagen } from "../testdata/emailvorlagen";
import { firmen } from "../testdata/firmen";
import { kampagne } from "../testdata/kampagne";
import { userdata } from "../testdata/login";
import { deleteNlsid, nlstatus } from "../testdata/nlstatus";

export * from "./login";
export * from "./router";
export * from "./content";
export * from "./content/collections";
export * from "./content/emailvorlagen";
export * from "./content/suche";
export * from "./content/dataset";
export * from "./content/benutzer";
export * from "./content/firmen";
export * from "./content/nlstatus";
export * from "./content/emailzugaenge";
export * from "./content/imports";
export * from "./content/vorgaenge";
export * from "./kampagne/kampagne";
export * from "./content/emailchecker";

export enum Actions {
  SHOW_MESSAGE,
  ROUTE,
  LOGIN,
  LOGOUT,
  OPEN_DATASET,
  RESET_DATASET,
  RESET_COLLECTION,
  CHANGE_LOAD_SCREEN,
  CONTENT_LOAD_EMAILVORLAGEN,
  CONTENT_LOAD_COLLECTIONS,
  CONTENT_LOAD_BENUTZER,
  CONTENT_LOAD_CONTENT,
  CONTENT_LOAD_CAMPAGNE,
  CONTENT_LOAD_FIRMEN,
  CONTENT_LOAD_IMPORTS,
  CONTENT_LOAD_VORGAENGE,
  CONTENT_SEARCH_ITEM,
  CONTENT_ADD_DATASET,
  CONTENT_CHANGE_DATASET,
  CONTENT_DELETE_DATASET,
  CONTENT_ADD_COLLECTION,
  CONTENT_DELETE_COLLECTION,
  CONTENT_LOAD_EMAILCHECKERSTATUS,
  LOCAL_SET_COMPANY,
  SUCHE_SET_COLLECTION,
  SUCHE_SET_KEYWORD,
  SUCHE_SET_PAGE,
  SUCHE_SET_LIMIT,
  UPDATE_RESULTCODE,
}

export const mainaxios = axios.create({ timeout: 60000, withCredentials: true });

if (config.testdata) {
  const mock = new MockAdapter(mainaxios);

  //Mock Login
  mock.onPost(`${config.backendUrl}/login`).reply(200, { data: userdata, rc: 0, description: "success testdata" });

  //Mock Logout
  mock.onGet(`${config.backendUrl}/logout`).reply(200);

  //Mock dashboard endpoint
  mock.onPost(`${config.backendUrl}/v1/loadMainContent`).reply(200, { data: dashboardData, rc: 0, description: "success testdata" });

  //Mock collections
  mock.onGet(`${config.backendUrl}/v1/collections`).reply(200, { data: collections, rc: 0, description: "success testdata" });

  //Mock NLStatus
  mock
    .onGet(new RegExp(`${config.backendUrl}/v1/dataset/nlstatus/*`))
    .reply(200, { data: nlstatus, rc: 0, description: "success testdata" });

  //Mock NLStatus
  mock.onPut(`${config.backendUrl}/v1/dataset/nlstatus`).reply(200, { data: deleteNlsid, rc: 0, description: "success testdata" });

  //Mock change dataset
  mock.onPut(`${config.backendUrl}/v1/dataset`).reply(200, { data: dataset, rc: 0, description: "success testdata" });
  mock.onGet(new RegExp(`${config.backendUrl}/v1/dataset/*`)).reply(200, { data: dataset, rc: 0, description: "success testdata" });

  //Mock Kampage
  mock.onGet(new RegExp(`${config.backendUrl}/v1/kampagn*`)).reply(function (config: any) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        if (config.url.endsWith("/5")) {
          const ar = [];
          ar.push(kampagne[2]);

          resolve([200, { data: ar, rc: 0, description: "success testdata" }]);
        } else {
          resolve([200, { data: kampagne, rc: 0, description: "success testdata" }]);
        }
      }, 1000);
    });
  });

  mock.onPost(`${config.backendUrl}/v1/kampagne/status`).reply(function (config: any) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve([200, { data: kampagne[0], rc: 0, description: "success testdata" }]);
      }, 1000);
    });
  });

  mock.onPost(`${config.backendUrl}/v1/kampagne`).reply(200, { data: kampagne[0], rc: 0, description: "success testdata" });
  mock.onPut(`${config.backendUrl}/v1/kampagne`).reply(200, { data: kampagne[0], rc: 0, description: "success testdata" });

  //Mock emailvorlagen
  mock.onGet(new RegExp(`${config.backendUrl}/v1/emailvorlage/*`)).reply(function (config: any) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve([200, { data: emailvorlagen, rc: 0, description: "success testdata" }]);
      }, 1000);
    });
  });

  mock
    .onPost(new RegExp(`${config.backendUrl}/v1/emailvorlage`))
    .reply(200, { data: emailvorlagen[0], rc: 0, description: "success testdata" });

  //Mock users
  mock.onGet(`${config.backendUrl}/v1/user`).reply(function (config: any) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve([200, { data: benutzer, rc: 0, description: "success testdata" }]);
      }, 1000);
    });
  });

  mock.onPost(`${config.backendUrl}/v1/user`).reply(200, { data: benutzer[0], rc: 0, description: "success testdata" });
  mock.onPut(`${config.backendUrl}/v1/user`).reply(200, { data: benutzer[0], rc: 0, description: "success testdata" });
  mock.onPost(`${config.backendUrl}/v1/user/password`).reply(200, { data: "success", rc: 0, description: "success testdata" });
  mock.onDelete(new RegExp(`${config.backendUrl}/v1/user/*`)).reply(200, { data: "success", rc: 0, description: "success testdata" });

  //Mock firmen
  mock.onGet(`${config.backendUrl}/v1/firmen`).reply(function (config: any) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve([200, { data: firmen, rc: 0, description: "success testdata" }]);
      }, 1000);
    });
  });
  mock.onPost(`${config.backendUrl}/v1/firma`).reply(200, { data: firmen[0], rc: 0, description: "success testdata" });
  mock.onPut(`${config.backendUrl}/v1/firma`).reply(200, { data: firmen[0], rc: 0, description: "success testdata" });
  mock.onDelete(new RegExp(`${config.backendUrl}/v1/firma/*`)).reply(200, { data: "success", rc: 0, description: "success testdata" });

  //Mock emailzugang
  mock.onPut(`${config.backendUrl}/v1/firmen/emailzugang`).reply(200, { data: firmen[0], rc: 0, description: "success testdata" });
  mock
    .onDelete(new RegExp(`${config.backendUrl}/v1/firmen/emailzugang/*`))
    .reply(200, { data: "success", rc: 0, description: "success testdata" });
  mock.onPost(`${config.backendUrl}/v1/firmen/emailzugang`).reply(function (config: any) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        console.log(config.data);
        resolve([200, { data: firmen, rc: 0, description: "success testdata" }]);
      }, 1000);
    });
  });

  console.log("Mock loaded");
}
