import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadFirmen, deleteFirma } from "../../../actions";
import { Firma } from "../../../types/remote/Firma";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { ResultToast } from "../../parts/ResultToast/ResultToast";
import { FirmenDialog } from "../../parts/FirmenDialog/FirmenDialog";
import { generateCompany } from "../../../helper/firmenHelper";
import { NLStatusStats } from "../../../types/remote/NLStatusStats";
import { NLStatusEnum } from "../../../types/remote/NLStatus";
import { ToArray } from "../../../helper/helper";

interface FirmenProps {
  content: ContentState;
  loadFirmen: Function;
  deleteFirma: Function;
}

interface FirmenState {
  company?: Firma;
}

class _FirmenUebersicht extends React.Component<FirmenProps, FirmenState> {
  constructor(props: FirmenProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.loadFirmen(true);
  }

  onHide() {
    this.setState({ ...this.state, company: undefined });
  }

  parseNLStat(nlstat: NLStatusStats[] | undefined) {
    let res: any[] = [];
    if (!nlstat || nlstat.length === 0) {
      res.push(<div>Statistik nicht geladen</div>);
      return res;
    } else {
      const en = ToArray(NLStatusEnum);
      let i = 0;
      nlstat.forEach((element) => {
        i++;
        res.push(
          <span key={i}>
            <b>{en[element.status] ? en[element.status].label : "unknown"}:</b> {element.count}
            <br />
          </span>
        );
      });
    }
    return res;
  }

  render() {
    return (
      <div className="card">
        <HeaderMenu forceAdd={true} action={() => this.setState({ ...this.state, company: generateCompany() })} />
        <ConfirmDialog />
        <ResultToast
          successAction={() => {
            this.props.loadFirmen();
          }}
        />
        <FirmenDialog close={() => this.setState({ ...this.state, company: undefined })} firma={this.state.company} />
        <DataTable
          value={this.props.content.firmen.map((val: Firma) => {
            let temp: any = { ...val };

            temp.func = (
              <div className="buttonSpacer">
                <div className="p-1">
                  <Button
                    onClick={() => {
                      this.setState({ ...this.state, company: val });
                    }}
                    tooltip="Edit Firma"
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info"
                  />
                </div>
                <div className="p-1">
                  <Button
                    onClick={() => {
                      confirmDialog({
                        message: `Soll die Firma ${val.Anzeigename} wirklich gelöscht werden?`,
                        header: "Delete Confirmation",
                        icon: "pi pi-info-circle",
                        acceptClassName: "p-button-danger",
                        accept: () => {
                          this.props.deleteFirma(val);
                        },
                      });
                    }}
                    tooltip="DELETE Emailvorlage"
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-error"
                  />
                </div>
              </div>
            );

            temp.stat = this.parseNLStat(val.nlstats);

            return temp;
          })}
        >
          <Column field="FID" header="FID"></Column>
          <Column field="Anzeigename" header="Anzeigename"></Column>
          <Column field="Kurzform" header="Kurzform"></Column>
          <Column field="stat" header="Statistik"></Column>
          <Column field="func" style={{ width: "100px" }} header=""></Column>
        </DataTable>

        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const FirmenUebersicht = connect(mapStateToProps, {
  loadFirmen,
  deleteFirma,
})(_FirmenUebersicht);
