export const checkCopied = (oldString:string | undefined, newString: string):string => {
    if (oldString){
        let diff = oldString.length - newString.length;
        if (diff < 0){
            diff = diff * (-1);
        }   
        if (diff > 1){
            return newString.trim();
        }
    } else {
        if (newString.length > 1){
            return newString.trim();
        }
    }
    return newString;
}