import { Actions, mainaxios } from "..";
import { UserData } from "../../types/local/loginData";
import { Dispatch } from "redux";
import { LoginState } from "../../reducers/login";
import { config } from "../../config/config";
import { Benutzer, ResponseCode, ResultObj } from "../../types/remote";
import { changeLoadScreen, ShowMessageAction } from "../router";
import { MessageType } from "../../types/local/messageData";

export interface LoginAction {
  type: Actions.LOGIN;
  data: LoginState;
}

export interface LogoutAction {
  type: Actions.LOGOUT;
  data: LoginState;
}

export const loginAction = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    //Show loading screen
    dispatch(changeLoadScreen(true));

    //Reset login first
    dispatch<LoginAction>({
      data: {},
      type: Actions.LOGIN,
    });

    try {
      let response = await mainaxios.post<ResultObj<Benutzer>>(
        config.backendUrl + "/login",
        {
          lid: -1,
          Nachname: "",
          password: password,
          username: "",
          Vorname: "",
          Email: email,
        },
        { withCredentials: true }
      );
      dispatch(changeLoadScreen(false));

      if (response.data && response.data.rc !== ResponseCode.SUCCESS && email.length > 0) {
        dispatch<ShowMessageAction>({
          type: Actions.SHOW_MESSAGE,
          data: {
            severity: MessageType.WARN,
            summary: "Fehler:",
            detail: "Der Login konnte nicht durchgeführt werden, bitte prüfen Sie Ihre Emailadresse und das Passwort.",
          },
        });
      } else {
        dispatch<LoginAction>({
          data: {
            userData: response.data.data,
            result: response.data.rc,
          },
          type: Actions.LOGIN,
        });
      }
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      if (email.length > 0) {
        dispatch<ShowMessageAction>({
          type: Actions.SHOW_MESSAGE,
          data: {
            severity: MessageType.WARN,
            summary: "Fehler:",
            detail: "Der Login konnte nicht durchgeführt werden, bitte prüfen Sie die Internetverbindung.",
          },
        });
      }

      dispatch<LoginAction>({
        data: {
          userData: undefined,
          result: ResponseCode.FAILED_NO_CONNECT,
        },
        type: Actions.LOGIN,
      });
    }
  };
};

export const logoutAction = (data: UserData) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      await mainaxios.get<ResultObj<undefined>>(config.backendUrl + "/logout", { withCredentials: true });
    } catch (error) {}

    dispatch(changeLoadScreen(false));
    dispatch<ShowMessageAction>({
      type: Actions.SHOW_MESSAGE,
      data: {
        severity: MessageType.SUCCESS,
        summary: "Logout erfolgreich.",
        detail: "Sie wurden erfolgreich ausgeloggt.",
      },
    });
    dispatch<LogoutAction>({
      data: {},
      type: Actions.LOGOUT,
    });
  };
};
