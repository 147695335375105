import { Emailzugang } from "../types/remote/Emailzugang";
import { Firma } from "../types/remote/Firma";

export function generateCompany(): Firma {
  return { Anzeigename: "", FID: -1, Kurzform: "", emailzugaenge: [], abmeldelink: "" };
}

export function generateEmailzugang(firma: Firma): Emailzugang {
  return {
    Anzeigename: "",
    Email: "",
    FID: firma.FID,
    Hostadresse: "",
    Loginname: "",
    Password: "",
    Port: 465,
    active: 1,
    initial: true,
    Pophost: "",
    Popport: 995,
    Poplogin: "",
    Poppassword: "",
  };
}
