import { combineReducers } from "redux";
import { ContentReducer, ContentState } from "./content";
import { KampagneState, kampReducer } from "./kampagne/kampReducer";
import { LoginReducer, LoginState } from "./login";
import { RouteReducer, RouteState } from "./router";

export interface StoreState {
  route: RouteState;
  login: LoginState;
  content: ContentState;
  kampagne: KampagneState;
}

export const reducers = combineReducers<StoreState>({
  route: RouteReducer,
  login: LoginReducer,
  content: ContentReducer,
  kampagne: kampReducer,
});
