import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { setSelectedCompany, loadFirmen } from "../../../actions";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { RouteState } from "../../../reducers/router";

interface HeaderMenuProps {
  setSelectedCompany: Function;
  content: ContentState;
  action?: Function;
  backAction?: Function;
  changeCompanyAction?: Function;
  loadFirmen: Function;
  route: RouteState;
  resetPossible?: boolean;
  forceAdd?: boolean;
}

class _HeaderMenu extends React.Component<HeaderMenuProps> {
  componentDidMount() {
    if (this.props.content.firmen.length === 0) this.props.loadFirmen();
  }

  componentDidUpdate(prevProps: HeaderMenuProps) {
    //Load emailvorlagen on change only
    if (prevProps.content.selectedCompany !== this.props.content.selectedCompany && this.props.changeCompanyAction) {
      this.props.changeCompanyAction();
    }
  }

  render() {
    return (
      <div className="collections-topbutton">
        {this.props.backAction ? (
          <Button
            label={"Zurück"}
            severity="help"
            icon="pi pi-angle-left"
            onClick={() => {
              if (this.props.backAction) this.props.backAction();
            }}
          />
        ) : undefined}
        <div>
          {this.props.changeCompanyAction ? (
            <Dropdown
              value={this.props.content.selectedCompany}
              onChange={(e) => {
                this.props.setSelectedCompany(e.value);
              }}
              options={this.props.content.firmen}
              optionLabel="Kurzform"
              placeholder="Firma auswählen"
              className="w-full md:w-14rem"
            />
          ) : undefined}
          {this.props.resetPossible && this.props.content.selectedCompany ? (
            <Button
              severity="danger"
              icon="pi pi-times"
              onClick={() => {
                this.props.setSelectedCompany();
              }}
            />
          ) : undefined}
        </div>
        {!this.props.backAction && !this.props.changeCompanyAction ? <div /> : undefined}
        <h1>{this.props.route.page.toString()}</h1>
        {this.props.action && (this.props.content.firmen.length > 0 || this.props.forceAdd) ? (
          <Button
            label="Hinzufügen"
            icon="pi pi-plus"
            className="p-button-success"
            onClick={() => {
              if (this.props.action) this.props.action();
            }}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

let mapStateToProps = ({ content, route }: StoreState): { content: ContentState; route: RouteState } => {
  return { content, route };
};

export const HeaderMenu = connect(mapStateToProps, {
  setSelectedCompany,
  loadFirmen,
})(_HeaderMenu);
