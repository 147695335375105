import emailValidator from "email-validator";
import urlValidator from "valid-url";
import { ValidEnum } from "../types/local/ValidEnum";

export const checkTextLen = (text: string | undefined, maxLen: number, minLen: number = 0): ValidEnum => {
  if (!text || text.length === 0) {
    return ValidEnum.NOT_SET;
  }

  if (text.length > maxLen || text.length < minLen) {
    return ValidEnum.INVALID;
  }

  return ValidEnum.VALID;
};

export const checkEmail = (email: string | undefined): ValidEnum => {
  if (!email || email.length === 0) {
    return ValidEnum.NOT_SET;
  }

  if (emailValidator.validate(email)) {
    return ValidEnum.VALID;
  }
  return ValidEnum.INVALID;
};

export const checkInternet = (internet: string | undefined): ValidEnum => {
  if (!internet || internet.length === 0) {
    return ValidEnum.NOT_SET;
  }

  const newUrl = internet.replace("<", "").replace(">", "");

  if (urlValidator.isWebUri(newUrl) || urlValidator.isUri(newUrl)) {
    return ValidEnum.VALID;
  }

  return ValidEnum.INVALID;
};

export const checkHostName = (host: string | undefined): ValidEnum => {
  if (!host || host.length === 0) {
    return ValidEnum.NOT_SET;
  }

  if (urlValidator.isUri(`file://${host}`)) {
    return ValidEnum.VALID;
  }

  return ValidEnum.INVALID;
};

export const checkPhone = (phone: string | undefined): ValidEnum => {
  if (!phone || phone.length === 0) {
    return ValidEnum.NOT_SET;
  }
  let current = phone;
  if (current.startsWith("'+")) {
    current = current.substring(1, current.length);
  }
  if (CorrectPhoneNumber(current)) {
    return ValidEnum.VALID;
  }
  return ValidEnum.INVALID;
};

function CorrectPhoneNumber(phoneNumber: string) {
  if (phoneNumber.length > 6 && phoneNumber.startsWith("+")) {
    let tempNumber = phoneNumber.substring(1);
    const reg = /^\d+$/;
    return reg.test(tempNumber);
  }
  return false;
}
