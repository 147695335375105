import { Dialog } from "primereact/dialog";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { changePassword, updateResultCode } from "../../../actions";
import { Button } from "primereact/button";
import { Benutzer } from "../../../types/remote";
import { Password } from "primereact/password";

interface PasswordDialogProps {
  benutzer?: Benutzer;
  close: Function;
  content: ContentState;
  updateResultCode: typeof updateResultCode;
  changePassword: Function;
}

interface PasswordDialogState {
  password: string;
}

export class _PasswordDialog extends React.Component<PasswordDialogProps, PasswordDialogState> {
  constructor(props: PasswordDialogProps) {
    super(props);

    this.state = { password: "" };
  }

  componentDidUpdate(prevProps: PasswordDialogProps) {
    if (!prevProps.benutzer && this.props.benutzer) {
      this.setState({ ...this.state, password: "" });
    }
  }

  isValid() {
    if (this.state.password && this.state.password.length >= 8) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />

              <Button
                disabled={!this.isValid()}
                label="Speichern"
                icon="pi pi-check"
                type="submit"
                onClick={() => {
                  if (this.props.benutzer) this.props.changePassword({ lid: this.props.benutzer.lid, password: this.state.password });
                  this.props.close();
                }}
              />
            </div>
          }
          header={`Passwort für ${this.props.benutzer?.Vorname} ${this.props.benutzer?.Nachname} ändern`}
          visible={typeof this.props.benutzer !== "undefined"}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className="field grid">
              <label htmlFor="kampagne-name" className="field col-12 md:col-2 labelcenter">
                Passwort
              </label>

              <div className="field col-12 md:col-10">
                <Password
                  value={this.state.password}
                  maxLength={50}
                  onChange={(e: any) => {
                    this.setState({ ...this.state, password: e.target.value });
                  }}
                  className={`${this.state.password.length < 8 ? "p-invalid" : ""}`}
                  toggleMask
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const PasswordDialog = connect(mapStateToProps, { changePassword, updateResultCode })(_PasswordDialog);
