import { Actions } from "../../actions";
import { LoadKampagneAction } from "../../actions/kampagne/kampagne";
import { Kampagne } from "../../types/remote/Kampagne";

export interface KampagneState {
  kampagnen: Kampagne[] | undefined;
}

export const kampReducer = (state: KampagneState = { kampagnen: [] }, action: LoadKampagneAction): KampagneState => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Actions.CONTENT_LOAD_CAMPAGNE:
      newState.kampagnen = action.data;
      return newState;
    default:
      return state;
  }
};
