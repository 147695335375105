import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { resolveFailure } from "../../helper/failureProcedure";
import { Dataset, ResultObj } from "../../types/remote";
import { NLStatus } from "../../types/remote/NLStatus";
import { changeLoadScreen } from "../router";

export const loadNLStatus = (d: Dataset) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      let response = await mainaxios.get<ResultObj<NLStatus[]>>(`${config.backendUrl}/v1/dataset/nlstatus/${d.KID}`, {
        withCredentials: true,
      });
      d.emailstatus = response.data.data;
      dispatch({ type: Actions.OPEN_DATASET, data: d });
      dispatch(changeLoadScreen(false));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx, { type: Actions.CONTENT_LOAD_CONTENT, data: undefined });
    }
  };
};

export const changeNLStatus = (nlstatus: NLStatus, value: string, dataset: Dataset) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    const statusBefore = nlstatus.status;
    try {
      nlstatus.status = parseInt(value);
      let response = await mainaxios.put<ResultObj<NLStatus>>(`${config.backendUrl}/v1/dataset/nlstatus`, nlstatus, {
        withCredentials: true,
      });
      if (dataset.emailstatus && response.data.data) {
        for (let i = 0; i < dataset.emailstatus.length; i++) {
          if (dataset.emailstatus[i].NLSID === nlstatus.NLSID) {
            dataset.emailstatus[i] = response.data.data;
          }
        }
      }
      dispatch({ type: Actions.OPEN_DATASET, data: dataset });
      dispatch(changeLoadScreen(false));
    } catch (exx) {
      nlstatus.status = statusBefore;
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx, { type: Actions.CONTENT_LOAD_CONTENT, data: undefined });
    }
  };
};
