export const firmen = [
  {
    FID: 4,
    Anzeigename: "info@mh-schleswig.de",
    Kurzform: "MHS_Hamburg",
    UID: 1,
    Created: "0",
    abmeldelink: "http://unsubscribe.maschinenhandel-schleswig-holstein.de/<LINKID>",
    emailzugaenge: [
      {
        Email: "info@maschinenhandel-schleswig-holstein.de",
        FID: 4,
        Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
        Loginname: "info@maschinenhandel-schleswig-holstein.de",
        Password: "MA4xh);_\\Lyr",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "news@maschinenhandel-schleswig-holstein.de",
        FID: 4,
        Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
        Loginname: "news@maschinenhandel-schleswig-holstein.de",
        Password: "2mwy8ttN$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl1@maschinenhandel-schleswig-holstein.de",
        FID: 4,
        Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
        Loginname: "nl1@maschinenhandel-schleswig-holstein.de",
        Password: "PjawZ$M2$$$22",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl@maschinenhandel-schleswig-holstein.de",
        FID: 4,
        Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
        Loginname: "nl@maschinenhandel-schleswig-holstein.de",
        Password: "9#*hXbco",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "ps@maschinenhandel-schleswig-holstein.de",
        FID: 4,
        Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
        Loginname: "ps@maschinenhandel-schleswig-holstein.de",
        Password: "YX53wKTs111$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "sammler@maschinenhandel-schleswig-holstein.de",
        FID: 4,
        Hostadresse: "mailserver.maschinenhandel-schleswig-holstein.de",
        Loginname: "sammler@maschinenhandel-schleswig-holstein.de",
        Password: "<3{F$(-SwqfZ",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Schleswig Holstein GmbH",
        Pophost: "",
        Popport: 0,
      },
    ],
  },
  {
    FID: 5,
    Anzeigename: "info@mhs-bayern.de",
    Kurzform: "MHS_Bayern",
    UID: 587,
    Created: "0",
    abmeldelink: "http://unsubscribe.schulte-maschinen.de/<LINKID>",
    emailzugaenge: [
      {
        Email: "mhs@schulte-maschinen.de",
        FID: 5,
        Hostadresse: "smtp.ionos.de",
        Loginname: "mhs@schulte-maschinen.de",
        Password: "nv$V*3s2$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Süd-Bayern GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl1@schulte-maschinen.de",
        FID: 5,
        Hostadresse: "smtp.ionos.de",
        Loginname: "nl1@schulte-maschinen.de",
        Password: "1kyn@ypO$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Süd-Bayern GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl@schulte-maschinen.de",
        FID: 5,
        Hostadresse: "smtp.ionos.de",
        Loginname: "nl@schulte-maschinen.de",
        Password: "*yWt7kVD$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Süd-Bayern GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "pb@schulte-maschinen.de",
        FID: 5,
        Hostadresse: "smtp.ionos.de",
        Loginname: "pb@schulte-maschinen.de",
        Password: "lGUCaAFP$$1",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Süd-Bayern GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "ps@schulte-maschinen.de",
        FID: 5,
        Hostadresse: "smtp.ionos.de",
        Loginname: "ps@schulte-maschinen.de",
        Password: "aVhP!Q5V$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Süd-Bayern GmbH",
        Pophost: "",
        Popport: 0,
      },
    ],
  },
  {
    FID: 6,
    Anzeigename: "info@mhs-siek.de",
    Kurzform: "MHS Siek",
    UID: 587,
    Created: "0",
    abmeldelink: "http://unsubscribe.mhs-siek.de/<LINKID>",
    emailzugaenge: [
      {
        Email: "info@maschinenankauf-siek.de",
        FID: 6,
        Hostadresse: "mailserver.maschinenankauf-bayern.de",
        Loginname: "info@maschinenankauf-siek.de",
        Password: "03?o@[Y7=U|;",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Maschinenhandels-GmbH Siek",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "mw@maschinenankauf-siek.de",
        FID: 6,
        Hostadresse: "mailserver.maschinenankauf-bayern.de",
        Loginname: "mw@maschinenankauf-siek.de",
        Password: "wuiU-h)ZNP=m",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Maschinenhandels-GmbH Siek",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "news@maschinenankauf-siek.de",
        FID: 6,
        Hostadresse: "mailserver.maschinenankauf-bayern.de",
        Loginname: "news@maschinenankauf-siek.de",
        Password: "6PF]o@*)C;np",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Maschinenhandels-GmbH Siek",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl@maschinenankauf-siek.de",
        FID: 6,
        Hostadresse: "mailserver.maschinenankauf-bayern.de",
        Loginname: "nl@maschinenankauf-siek.de",
        Password: "2H!wvlS7m^>{",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Maschinenhandels-GmbH Siek",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "ps@maschinenankauf-siek.de",
        FID: 6,
        Hostadresse: "mailserver.maschinenankauf-bayern.de",
        Loginname: "ps@maschinenankauf-siek.de",
        Password: "&rj@#$7ER5-)",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Maschinenhandels-GmbH Siek",
        Pophost: "",
        Popport: 0,
      },
    ],
  },
  {
    FID: 7,
    Anzeigename: "info@maschinen-pmv.de",
    Kurzform: "PMV_Maschinen",
    UID: 587,
    Created: "0",
    abmeldelink: "http://abmelden.maschinen-pmv.de/<LINKID>",
    emailzugaenge: [
      {
        Email: "mw@maschinen-pmv.de",
        FID: 7,
        Hostadresse: "smtp.strato.de",
        Loginname: "mw@maschinen-pmv.de",
        Password: "BDZeDZiUZsf0fwv@",
        Port: 465,
        Anzeigename: "PMV Maschinen Vertriebs GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl2@maschinen-pmv.de",
        FID: 7,
        Hostadresse: "smtp.strato.de",
        Loginname: "nl2@maschinen-pmv.de",
        Password: "FeyD7F6@yfBEYf$z",
        Port: 465,
        Anzeigename: "PMV Maschinen Vertriebs GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl@maschinen-pmv.de",
        FID: 7,
        Hostadresse: "smtp.strato.de",
        Loginname: "nl@maschinen-pmv.de",
        Password: "GPGQVi*l73ELRxUU",
        Port: 465,
        Anzeigename: "PMV Maschinen Vertriebs GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "ps@maschinen-pmv.de",
        FID: 7,
        Hostadresse: "smtp.strato.de",
        Loginname: "ps@maschinen-pmv.de",
        Password: "49Z26ghsiCH&*#km",
        Port: 465,
        Anzeigename: "PMV Maschinen Vertriebs GmbH",
        Pophost: "",
        Popport: 0,
      },
    ],
  },
  {
    FID: 8,
    Anzeigename: "info@mhs-group-germany.com",
    Kurzform: "MHS_Group_Germany",
    UID: 587,
    Created: "0",
    abmeldelink: "http://unsubscribe.mhs-group-germany.de/<LINKID>",
    emailzugaenge: [
      {
        Email: "ankauf@mhs-group-germany.de",
        FID: 8,
        Hostadresse: "smtp.ionos.de",
        Loginname: "ankauf@mhs-group-germany.de",
        Password: "TuI2*TDx$$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "news@mhs-group-germany.de",
        FID: 8,
        Hostadresse: "smtp.ionos.de",
        Loginname: "news@mhs-group-germany.de",
        Password: "Jf%DDm1p$$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl1@mhs-group-germany.de",
        FID: 8,
        Hostadresse: "smtp.ionos.de",
        Loginname: "nl1@mhs-group-germany.de",
        Password: "iP*Smx3L$$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl2@mhs-group-germany.de",
        FID: 8,
        Hostadresse: "smtp.ionos.de",
        Loginname: "nl2@mhs-group-germany.de",
        Password: "KSJ51Qp1$$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl@mhs-group-germany.de",
        FID: 8,
        Hostadresse: "smtp.ionos.de",
        Loginname: "nl@mhs-group-germany.de",
        Password: "9K!6vGl!aa",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "ps@mhs-group-germany.de",
        FID: 8,
        Hostadresse: "smtp.ionos.de",
        Loginname: "ps@mhs-group-germany.de",
        Password: "IZLLbT8f$$$",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Germany GmbH",
        Pophost: "",
        Popport: 0,
      },
    ],
  },
  {
    FID: 13,
    Anzeigename: "info@mhs-group.at",
    Kurzform: "AT - MHS Group AT",
    UID: 1,
    Created: "0",
    abmeldelink: "http://unsubscribe.mhsgroup.at/<LINKID>",
    emailzugaenge: [
      {
        Email: "nl1@mhsgroup.at",
        FID: 13,
        Hostadresse: "smtp.ionos.de",
        Loginname: "nl1@mhsgroup.at",
        Password: "3%3Y8X9G13123",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Austria GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl@mhsgroup.at",
        FID: 13,
        Hostadresse: "smtp.ionos.de",
        Loginname: "nl@mhsgroup.at",
        Password: "#Gh)h56V23424",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Austria GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "ps@mhsgroup.at",
        FID: 13,
        Hostadresse: "smtp.ionos.de",
        Loginname: "ps@mhsgroup.at",
        Password: "55xBga#Knd",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Austria GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "tm@mhsgroup.at",
        FID: 13,
        Hostadresse: "smtp.ionos.de",
        Loginname: "tm@mhsgroup.at",
        Password: "&6Pdb2ER12313",
        Port: 465,
        Anzeigename: "Peter Schulte | MHS Group Austria GmbH",
        Pophost: "",
        Popport: 0,
      },
    ],
  },
  {
    FID: 14,
    Anzeigename: "HWG Maschinen Handels GmbH",
    Kurzform: "HWG",
    UID: 0,
    Created: "0",
    abmeldelink: "http://unsubscribe.maschinenhandel-pb.de/<LINKID>",
    emailzugaenge: [
      {
        Email: "ak@maschinenhandel-pb.de",
        FID: 14,
        Hostadresse: "smtp.strato.de",
        Loginname: "ak@maschinenhandel-pb.de",
        Password: "eGMKTZJW$$$",
        Port: 465,
        Anzeigename: "Peter Schulte | HWG Maschinen Handels GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl1@maschinenhandel-pb.de",
        FID: 14,
        Hostadresse: "smtp.strato.de",
        Loginname: "nl1@maschinenhandel-pb.de",
        Password: "c!Hgn7Es$$",
        Port: 465,
        Anzeigename: "Peter Schulte | HWG Maschinen Handels GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "nl@maschinenhandel-pb.de",
        FID: 14,
        Hostadresse: "smtp.strato.de",
        Loginname: "nl@maschinenhandel-pb.de",
        Password: "sefk!bn($$$",
        Port: 465,
        Anzeigename: "Peter Schulte | HWG Maschinen Handels GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "po@maschinenhandel-pb.de",
        FID: 14,
        Hostadresse: "smtp.strato.de",
        Loginname: "po@maschinenhandel-pb.de",
        Password: "&t1cnHQT$$$",
        Port: 465,
        Anzeigename: "Peter Schulte | HWG Maschinen Handels GmbH",
        Pophost: "",
        Popport: 0,
      },
      {
        Email: "ps@maschinenhandel-pb.de",
        FID: 14,
        Hostadresse: "smtp.strato.de",
        Loginname: "ps@maschinenhandel-pb.de",
        Password: "u2b0Ua$Q111",
        Port: 465,
        Anzeigename: "Peter Schulte | HWG Maschinen Handels GmbH",
        Pophost: "",
        Popport: 0,
      },
    ],
  },
];
