import React from "react";

import datetime from "date-and-time";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { getDataset, resetDataset, resetVorgaenge, loadVorgaengeKAPID, changePage, deleteVorgang, cleanupVorgange } from "../../../actions";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { connect } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Page } from "../../../types/local/pages";
import { SendResult } from "../../../types/remote/sendResult";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { getName } from "../../../helper/benutzerHelper";
import { ResultToast } from "../../parts/ResultToast/ResultToast";
import { VorgangDialog } from "../../parts/VorgangDialog/VorgangDialog";
import { Vorgang } from "../../../types/remote/Vorgang";
import { generateVorgang } from "../../../helper/vorgangHelper";
import { Fieldset } from "primereact/fieldset";
import { VORGANG_TYPES } from "../../../types/remote/VorgangSearchOptions";
import { Dropdown } from "primereact/dropdown";

interface VorgaengeOverviewProp {
  content: ContentState;
  route: typeof changePage;
  deleteVorgang: Function;
  getDataset: Function;
  resetDataset: typeof resetDataset;
  resetVorgaenge: typeof resetVorgaenge;
  loadVorgaengeKAPID: Function;
  cleanupVorgange: Function;
}

interface VorgangOverviewState {
  vorgang?: Vorgang;
  date: Date | undefined;
  removalTime: Date | undefined;
  vorgangstyp: VORGANG_TYPES;
  limit: number;
}

export class _VorgaengeOverview extends React.Component<VorgaengeOverviewProp, VorgangOverviewState> {
  constructor(props: VorgaengeOverviewProp) {
    super(props);
    this.state = { date: new Date(), removalTime: new Date(), vorgangstyp: VORGANG_TYPES.EMAIL_SEND, limit: 10 };
  }

  componentDidMount() {
    this.setState({ date: new Date() });
  }

  componentWillUnmount() {
    this.props.resetDataset();
    this.props.resetVorgaenge();
  }

  render() {
    return (
      <div>
        <ResultToast
          successAction={() => {
            if (this.props.content.dataset) this.props.getDataset(this.props.content.dataset);
          }}
        />
        {this.props.content.dataset ? (
          <HeaderMenu
            action={() => {
              this.setState({ ...this.state, vorgang: generateVorgang() });
            }}
            backAction={() => {
              this.props.route(Page.OVERVIEW);
            }}
          />
        ) : null}

        {!this.props.content.dataset ? (
          <div>
            <Fieldset legend="Vorgangs - Suche">
              <p className="m-0">
                <b>In welchem Zeitraum wollen Sie Vorgänge suchen?</b>
              </p>
              <br />
              <Calendar
                value={this.state.date}
                onChange={(e) => this.setState({ ...this.state, date: e.value instanceof Date ? e.value : undefined })}
                showTime
                showIcon={true}
                hourFormat="24"
                dateFormat="dd.mm.yy"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Dropdown
                options={[
                  { label: "MAILDELIVERY_SORTER", value: "MAILDELIVERY_SORTER" },
                  { label: "MAILDELIVERY_SORTER_UNKNOWN", value: "MAILDELIVERY_SORTER_UNKNOWN" },
                  { label: "EMAIL_SEND_FAILED", value: "EMAIL_SEND_FAILED" },
                  { label: "EMAIL_SEND", value: "EMAIL_SEND" },
                ]}
                value={this.state.vorgangstyp}
                onChange={(e: any) => {
                  this.setState({ ...this.state, vorgangstyp: e.value });
                }}
                placeholder="Bitte wählen"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Dropdown
                options={[
                  { label: "Limit 10", value: 10 },
                  { label: "Limit 20", value: 20 },
                  { label: "Limit 100", value: 100 },
                  { label: "Limit 500", value: 500 },
                ]}
                value={this.state.limit}
                onChange={(e: any) => {
                  this.setState({ ...this.state, limit: e.value });
                }}
                placeholder="Limit"
              />
              <br />
              <br />
              <Button
                onClick={() => {
                  this.props.loadVorgaengeKAPID(this.state.date, this.state.limit, this.state.vorgangstyp);
                }}
                label="Suche starten"
                icon="pi pi-search"
                className="p-button-rounded p-button-info"
              />
            </Fieldset>
            <div className="uebersicht-spacer"></div>
          </div>
        ) : undefined}

        <DataTable
          sortOrder={-1}
          sortField="VID"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={this.props.content.vorgaenge.map((vorgang) => {
            const temp: any = { ...vorgang };

            if (vorgang.vorgangstyp === "EMAIL_SEND") {
              try {
                const t: SendResult = JSON.parse(vorgang.Text);
                temp.text = (
                  <div>
                    <b>Ergebnis: </b> {t.emailResult}
                    <br />
                    <b>Gesendet von: </b> {t.senderEmail}
                    <br />
                    <b>Emailvorlage: </b> {t.emailVorlage}
                    <br />
                    <b>Details: </b> {t.resultDetails}
                    <br />
                    <b>KAPID: </b> {vorgang.KAPID}
                    <br />
                  </div>
                );
              } catch (exx) {
                console.error("Cannot parse json stuff: " + exx);
                temp.text = vorgang.Text;
              }
            } else {
              temp.text = vorgang.Text;
            }

            temp.meta = (
              <div>
                <br />
                <b>Erstellt: </b> {vorgang.aenderung ? datetime.format(new Date(vorgang.aenderung), "DD.MM.YYYY HH:mm") : "unbekannt"}
                <br />
                <b>Verfasser: </b>
                {getName(vorgang.Verfasser)}
              </div>
            );

            temp.func = (
              <div className="buttonSpacer">
                {!this.props.content.dataset ? (
                  <div className="p-1">
                    <Button
                      onClick={async () => {
                        await this.props.getDataset(temp);
                        this.props.route(Page.DATASET);
                      }}
                      icon="pi pi-id-card"
                      className="p-button-rounded p-button-info"
                      tooltip="Kundendaten"
                    />
                  </div>
                ) : undefined}

                {vorgang.vorgangstyp !== "EMAIL_SEND" && vorgang.vorgangstyp !== "NLSTATUS_CHANGE" ? (
                  <div className="p-1">
                    <Button
                      onClick={() => {
                        this.setState({ ...this.state, vorgang: temp });
                      }}
                      tooltip="Edit Vorgang"
                      icon="pi pi-file-edit"
                      className="p-button-rounded p-button-info"
                    />
                  </div>
                ) : undefined}

                <div className="p-1">
                  <Button
                    onClick={() => {
                      this.props.deleteVorgang(temp);
                    }}
                    tooltip="Delete Vorgang"
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning"
                  />
                </div>
              </div>
            );

            return temp;
          })}
        >
          <Column style={{ width: "10%" }} field="VID" header="VID"></Column>
          <Column field="vorgangstyp" header="Vorgangstyp"></Column>
          <Column field="text" header="Details"></Column>
          <Column field="meta" header="Info"></Column>
          <Column field="func" header="" style={{ width: "100px" }}></Column>
        </DataTable>
        <VorgangDialog close={() => this.setState({ ...this.state, vorgang: undefined })} vorgang={this.state.vorgang} />
        <div className="uebersicht-spacer"></div>
        {!this.props.content.dataset ? (
          <div>
            <Fieldset legend="Datenbank aufräumen">
              <p className="m-0">
                <b>
                  Alle Vorgänge vom Typ "EMAIL_SEND" können automatisch bis zur angegebenen Uhrzeit gelöscht werden, um Speicherplatz in der
                  Datenbank freizugeben.
                </b>
              </p>
              <br />
              <Calendar
                value={this.state.removalTime}
                onChange={(e) => this.setState({ ...this.state, removalTime: e.value instanceof Date ? e.value : undefined })}
                showTime
                showIcon={true}
                hourFormat="24"
                dateFormat="dd.mm.yy"
              />
              <br />
              <br />
              <Button
                onClick={() => {
                  this.props.cleanupVorgange(this.state.removalTime);
                }}
                label="Vorgänge löschen"
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
              />
            </Fieldset>
            <div className="uebersicht-spacer"></div>
          </div>
        ) : undefined}
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const VorgaengeOverview = connect(mapStateToProps, {
  route: changePage,
  deleteVorgang,
  getDataset,
  resetDataset,
  resetVorgaenge,
  loadVorgaengeKAPID,
  cleanupVorgange,
})(_VorgaengeOverview);
