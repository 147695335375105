import { Benutzer } from "../types/remote";

export function generateEmptyBenutzer(): Benutzer {
  return { Nachname: "", Vorname: "", lid: -1, Email: "", password: "" };
}

export function getName(ben?: Benutzer): string {
  if (!ben) return "unbekannt";
  return ben.Vorname + " " + ben.Nachname;
}
