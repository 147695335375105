import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import { Password } from "primereact/password";
import { changeLoadScreen, loginAction, changePage } from "../../../actions";
import { LoginState } from "../../../reducers/login";
import { Page } from "../../../types/local/pages";

import "./style.css";

interface LoginProps {
  route: typeof changePage;
  changeLoadScreen: typeof changeLoadScreen;
  loginAction: Function;
  login: LoginState;
}

interface LocalLoginState {
  passwordRecovery: boolean;
  email: string;
  password: string;
}

export class _Login extends React.Component<LoginProps, LocalLoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = { passwordRecovery: false, email: "", password: "" };
  }

  changePasswordRecovery = () => {
    this.setState({
      passwordRecovery: !this.state.passwordRecovery,
      email: this.state.email,
      password: this.state.password,
    });
  };

  login = () => {
    this.props.loginAction(this.state.email, this.state.password);
  };

  componentDidMount() {
    this.login();
  }

  componentDidUpdate(prevProps: LoginProps) {
    //go to overview if login succeed
    if (!prevProps.login.userData && this.props.login.userData) {
      this.props.route(Page.OVERVIEW);
    }
  }

  render() {
    return (
      <div className="login-container">
        <div className="login-container-inner">
          <div className="p-grid p-fluid">
            <div className="p-col-12 p-md-12">
              <h3>{!this.state.passwordRecovery ? "Login" : "Passwort zurücksetzen"} </h3>
            </div>

            <div className="p-col-12 p-md-12">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-user"></i>
                </span>
                <InputText
                  placeholder="Emailadresse"
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({
                      passwordRecovery: this.state.passwordRecovery,
                      email: (e.target as any).value,
                      password: this.state.password,
                    });
                  }}
                />
              </div>
            </div>

            {!this.state.passwordRecovery ? (
              <>
                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-key"></i>
                    </span>
                    <Password
                      toggleMask={true}
                      placeholder="Password"
                      feedback={false}
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                          this.login();
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          passwordRecovery: this.state.passwordRecovery,
                          email: this.state.email,
                          password: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="p-col-12 p-md-4">
                  <Button
                    disabled={this.state.email.length === 0 || this.state.password.length === 0}
                    label="Login"
                    onClick={() => this.login()}
                    className="p-button-info"
                  />
                </div>

                <div className="p-col-12 p-md-12"></div>
                {false ? (
                  <div className="p-col-12 p-md-12">
                    Klicken Sie{" "}
                    <button
                      className="ahref-button"
                      onClick={() => {
                        this.changePasswordRecovery();
                      }}
                    >
                      hier
                    </button>{" "}
                    wenn Sie Ihr Passwort vergessen haben.
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <div className="p-col-12 p-md-12">Bitte geben Sie Ihre Emailadresse ein, dann senden wir Ihnen ein neues Passwort zu.</div>
                <div className="p-col-12 p-md-12">
                  <Button label="Passwort zusenden" className="p-button-success" icon="pi pi-check-circle" onClick={() => {}} />
                </div>
                <div className="p-col-12 p-md-12">
                  <Button
                    label="Abbrechen"
                    className="p-button-danger"
                    icon="pi pi-times"
                    onClick={() => {
                      this.changePasswordRecovery();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ({ login }: StoreState): { login: LoginState } => {
  return { login };
};

export const Login = connect(mapStateToProps, {
  changeLoadScreen,
  loginAction,
  route: changePage,
})(_Login);
