import React from "react";
import { connect } from "react-redux";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import "./style.css";
import { InputText } from "primereact/inputtext";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadCollections, resetCollection, addCollection, changePage, sucheSetCollectionAction, deleteCollection } from "../../../actions";
import { Collection } from "../../../types/remote";
import { Page } from "../../../types/local/pages";
import { checkTextLen } from "../../../helper/validate";
import { ValidEnum } from "../../../types/local/ValidEnum";
import { ConfirmationDialog } from "../../parts/dialog";
import { HeaderMenu } from "../../parts/HeaderMenu";

interface CollectionsProps {
  content: ContentState;
  loadCollections: Function;
  route: typeof changePage;
  sucheSetCollectionAction: typeof sucheSetCollectionAction;
  addCollection: Function;
  resetCollection: typeof resetCollection;
  deleteCollection: Function;
}

interface CollectionsState {
  collection: Collection;
  showDialog: boolean;
  showDeleteDialog: boolean;
  collections: ExtendedCollection[];
}

interface ExtendedCollection extends Collection {
  action?: JSX.Element;
}

class _Collections extends React.Component<CollectionsProps, CollectionsState> {
  constructor(props: CollectionsProps) {
    super(props);
    this.state = { showDialog: false, collections: [], collection: { cid: 0, name: "" }, showDeleteDialog: false };
  }

  componentDidMount() {
    this.props.loadCollections();
  }

  //<Button icon='pi pi-pencil' className='p-button-rounded p-button-warn' />
  componentDidUpdate(prevProps: CollectionsProps) {
    if (prevProps.content.collections !== this.props.content.collections) {
      let ext: ExtendedCollection[] = [];
      this.props.content.collections.forEach((collection) => {
        let temp = Object.assign({}, collection) as ExtendedCollection;
        temp.action = (
          <div className="collection-button-container">
            <div className="p-1">
              <Button
                icon="pi pi-eye"
                className="p-button-rounded p-button-info"
                onClick={() => {
                  this.props.sucheSetCollectionAction(collection);
                  this.props.route(Page.OVERVIEW);
                }}
              />
            </div>
            <div className="p-1">
              <Button
                icon="pi pi-trash"
                onClick={() => {
                  this.deleteCollection(collection);
                }}
                className="p-button-rounded p-button-danger"
              />
            </div>
          </div>
        );
        ext.push(temp);
      });
      this.setState({ showDialog: this.state.showDialog, collections: ext, collection: { cid: 0, name: "" }, showDeleteDialog: false });
    } else if (prevProps.content.collection !== this.props.content.collection) {
      this.props.resetCollection();
      this.props.loadCollections();
    }
  }

  deleteCollection = (collection: Collection) => {
    this.setState({
      showDialog: this.state.showDialog,
      collections: this.state.collections,
      collection: collection,
      showDeleteDialog: true,
    });
  };

  onHide = (val: boolean = false) => {
    this.setState({ showDialog: val, collections: this.state.collections, collection: this.state.collection, showDeleteDialog: false });
  };

  addNewCollection = () => {
    let newCollection = this.state.collection;
    this.props.addCollection(newCollection);
    this.onHide(false);
  };

  render() {
    return (
      <div className="card">
        <HeaderMenu action={() => this.onHide(true)} />

        <DataTable value={this.state.collections}>
          <Column style={{ width: "10%" }} field="cid" header="CID"></Column>
          <Column field="name" header="Name"></Column>
          <Column style={{ width: "10%" }} field="counter" header="Anz. Datensätze"></Column>
          <Column style={{ width: "100px" }} field="action" header=""></Column>
        </DataTable>

        <Dialog
          header="Collection hinzufügen"
          visible={this.state.showDialog}
          style={{ width: "50vw" }}
          onHide={this.onHide}
          footer={
            <div className="dataeditor-button-container">
              <Button
                label="Speichern"
                className="p-button-success"
                icon="pi pi-save"
                onClick={() => this.addNewCollection()}
                disabled={checkTextLen(this.state.collection.name, 50, 2) !== ValidEnum.VALID}
              />
              <Button label="Abbrechen" className="p-button-danger" icon="pi pi-times" onClick={() => this.onHide()} />
            </div>
          }
        >
          <p>Bitte geben Sie die Namen der neuen Collection ein:</p>
          <div className="p-inputgroup">
            <span
              className={
                checkTextLen(this.state.collection.name, 50, 2) === ValidEnum.VALID
                  ? "p-inputgroup-addon valid-addon "
                  : "p-inputgroup-addon invalid-addon"
              }
            >
              <i className="pi pi-pencil"></i>
            </span>
            <span className="p-float-label">
              <InputText
                id="inputtext"
                value={this.state.collection.name}
                onChange={(e: any) => {
                  let col = this.state.collection;
                  col.name = e.target.value;
                  this.setState({ showDialog: this.state.showDialog, collections: this.state.collections, collection: col });
                }}
                className={checkTextLen(this.state.collection.name, 50, 2) === ValidEnum.VALID ? "valid-textfield" : "invalid-textfield"}
              />
            </span>
          </div>
        </Dialog>
        <ConfirmationDialog
          header="Datensatz wirklich löschen?"
          content={`Soll die Collection "${this.state.collection.name}" gelöscht werden?`}
          onClickYes={() => {
            this.props.deleteCollection(this.state.collection);
            this.setState({
              collections: this.state.collections,
              collection: this.state.collection,
              showDialog: this.state.showDeleteDialog,
              showDeleteDialog: false,
            });
          }}
          onClickNo={() => {
            this.setState({
              collections: this.state.collections,
              collection: this.state.collection,
              showDialog: this.state.showDeleteDialog,
              showDeleteDialog: false,
            });
          }}
          yesCaption="Ja"
          noCaption="Nein"
          visible={this.state.showDeleteDialog}
        />
        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const Collections = connect(mapStateToProps, {
  loadCollections,
  sucheSetCollectionAction,
  route: changePage,
  addCollection,
  resetCollection,
  deleteCollection,
})(_Collections);
