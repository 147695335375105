import { Actions } from "..";
import { Collection } from "../../types/remote";

export interface SucheSetCollectionAction {
  type: Actions.SUCHE_SET_COLLECTION;
  data?: Collection;
}

export interface SucheSetSearchTextAction {
  type: Actions.SUCHE_SET_KEYWORD;
  data?: string;
}

export interface SucheSetPageAction {
  type: Actions.SUCHE_SET_PAGE;
  data: number;
}
export interface SucheSetLimitAction {
  type: Actions.SUCHE_SET_LIMIT;
  data: number;
}

export const sucheSetCollectionAction = (collection: Collection | undefined): SucheSetCollectionAction => {
  return { type: Actions.SUCHE_SET_COLLECTION, data: collection };
};

export const sucheSetSearchTextAction = (searchPattern?: string): SucheSetSearchTextAction => {
  return { type: Actions.SUCHE_SET_KEYWORD, data: searchPattern };
};

export const sucheSetPageAction = (page: number): SucheSetPageAction => {
  return { type: Actions.SUCHE_SET_PAGE, data: page };
};

export const sucheSetLimitAction = (limit: number): SucheSetLimitAction => {
  return { type: Actions.SUCHE_SET_LIMIT, data: limit };
};
