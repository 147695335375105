import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { Benutzer, ResultObj } from "../../types/remote";

import { changeLoadScreen } from "../router";
import { resolveFailure } from "../../helper/failureProcedure";
import { updateResultCode } from ".";

export interface LoadBenutzerAction {
  type: Actions.CONTENT_LOAD_BENUTZER;
  data: Benutzer[] | undefined;
}

export const loadBenutzer = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Benutzer[]>>(`${config.backendUrl}/v1/user`);
      dispatch(changeLoadScreen(false));
      dispatch<LoadBenutzerAction>({
        data: response.data.data,
        type: Actions.CONTENT_LOAD_BENUTZER,
      });
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const addChangeUser = (ben: Benutzer) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response =
        ben.lid >= 0
          ? await mainaxios.put<ResultObj<Benutzer>>(`${config.backendUrl}/v1/user`, ben)
          : await mainaxios.post<ResultObj<Benutzer>>(`${config.backendUrl}/v1/user`, ben);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const changePassword = (ben: Benutzer) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.post<ResultObj<Benutzer>>(`${config.backendUrl}/v1/user/password`, ben);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      console.log(exx);
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const deleteBenutzer = (ben: Benutzer) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.delete<ResultObj<Benutzer>>(`${config.backendUrl}/v1/user/${ben.lid}`);
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      console.log(exx);
      dispatch(updateResultCode(-1));
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};
