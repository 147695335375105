import { Dispatch } from "redux";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { resolveFailure } from "../../helper/failureProcedure";
import { getEmptyDataset } from "../../helper/helper";
import { MessageType } from "../../types/local/messageData";
import { Dataset, ResponseCode, ResultObj } from "../../types/remote";
import { changeLoadScreen, showMessage } from "../router";

export interface AddDatasetAction {
  type: Actions.CONTENT_ADD_DATASET;
  data: Dataset | undefined;
}

export interface ChangeDatasetAction {
  type: Actions.CONTENT_CHANGE_DATASET;
  data: Dataset | undefined;
}

export interface DeleteDatasetAction {
  type: Actions.CONTENT_DELETE_DATASET;
  data: Dataset | undefined;
}

export interface OpenDatasetAction {
  type: Actions.OPEN_DATASET;
  data: Dataset | undefined;
}

export interface ResetDatasetAction {
  type: Actions.RESET_DATASET;
}

export const resetDataset = (): ResetDatasetAction => {
  return { type: Actions.RESET_DATASET };
};

export const getDataset = (dataset?: Dataset) => {
  if (!dataset) {
    return { type: Actions.OPEN_DATASET, data: getEmptyDataset() };
  }
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.get<ResultObj<Dataset>>(`${config.backendUrl}/v1/dataset/${dataset.KID}`, {
        withCredentials: true,
      });
      dispatch<OpenDatasetAction>({
        data: response.data.data,
        type: Actions.OPEN_DATASET,
      });
      dispatch(changeLoadScreen(false));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const addDataset = (dataset: Dataset) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));
      let response = await mainaxios.post<ResultObj<Dataset>>(config.backendUrl + "/v1/dataset", dataset, {
        withCredentials: true,
      });
      dispatch(changeLoadScreen(false));

      if (response.data.rc === ResponseCode.SUCCESS) {
        dispatch(showMessage({ detail: "", summary: "Datensatz hinzugefügt", severity: MessageType.SUCCESS }));
        dispatch<AddDatasetAction>({
          data: response.data.data,
          type: Actions.CONTENT_ADD_DATASET,
        });
      } else if (response.data.rc === ResponseCode.FAILED_DUPLICATE_ASSET) {
        dispatch<AddDatasetAction>({
          data: undefined,
          type: Actions.CONTENT_ADD_DATASET,
        });
        dispatch(
          showMessage({
            detail: "Datensatz mit der Emailadresse ist bereits vorhanden",
            summary: "Fehler:",
            severity: MessageType.ERROR,
          })
        );
      } else {
        dispatch<AddDatasetAction>({
          data: undefined,
          type: Actions.CONTENT_ADD_DATASET,
        });
        dispatch(
          showMessage({
            detail: "Datensatz konnte nicht hinzugefügt werden.",
            summary: "Fehler:",
            severity: MessageType.ERROR,
          })
        );
      }
    } catch (exx) {
      resolveFailure(dispatch, exx, {
        data: undefined,
        type: Actions.CONTENT_ADD_DATASET,
      });
    }
  };
};

export const changeDataset = (dataset: Dataset) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));

      let response = await mainaxios.put<ResultObj<Dataset>>(config.backendUrl + "/v1/dataset", dataset, {
        withCredentials: true,
      });
      dispatch(changeLoadScreen(false));

      if (response.data.rc === ResponseCode.SUCCESS) {
        dispatch(showMessage({ detail: "", summary: "Änderungen gespeichert", severity: MessageType.SUCCESS }));
      } else {
        dispatch(
          showMessage({
            detail: "Datensatz konnte nicht geändert werden.",
            summary: "Fehler:",
            severity: MessageType.ERROR,
          })
        );
      }
      dispatch<ChangeDatasetAction>({
        data: response.data.data,
        type: Actions.CONTENT_CHANGE_DATASET,
      });
    } catch (exx) {
      resolveFailure(dispatch, exx, {
        data: undefined,
        type: Actions.CONTENT_CHANGE_DATASET,
      });
    }
  };
};

export const deleteDataset = (dataset: Dataset) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeLoadScreen(true));
      let response = await mainaxios.delete<ResultObj<Dataset>>(config.backendUrl + `/v1/dataset/${dataset.KID}`, {
        withCredentials: true,
      });
      dispatch(changeLoadScreen(false));

      if (response.data.rc === ResponseCode.SUCCESS) {
        dispatch(showMessage({ detail: "", summary: `Datensatz ${dataset.Email} gelöscht.`, severity: MessageType.SUCCESS }));
        dataset.KID = -1;
        dispatch<DeleteDatasetAction>({
          data: dataset,
          type: Actions.CONTENT_DELETE_DATASET,
        });
      } else {
        dispatch(
          showMessage({
            detail: `Datensatz ${dataset.Email} gelöscht.`,
            summary: "Fehler:",
            severity: MessageType.ERROR,
          })
        );
      }
    } catch (exx) {
      resolveFailure(dispatch, exx, {
        data: undefined,
        type: Actions.CONTENT_DELETE_DATASET,
      });
    }
  };
};
