export const benutzer = [
  {
    lid: 1,
    Vorname: "Ralph",
    Nachname: "Pohl",
    Email: "rpohl@mh-schleswig.de",
    admin: 1,
    creator: 0,
    created: "",
    lastlogin: "1695988944877",
  },
  { lid: 2, Vorname: "Ulrike", Nachname: "Cazacu", Email: "uc@mh-schleswig.de", admin: 0, creator: 0, created: "", lastlogin: "" },
  { lid: 3, Vorname: "Automatische", Nachname: "Abmeldung", Email: "bulk@bulk.de", admin: 0, creator: 0, created: "", lastlogin: "" },
  { lid: 4, Vorname: "Programmsortierung", Nachname: "Programmsortierung", Email: "", admin: 0, creator: 0, created: "", lastlogin: "" },
  {
    lid: 8,
    Vorname: "Marion",
    Nachname: "Winkler",
    Email: "mw@mh-schleswig.de",
    admin: 0,
    creator: 0,
    created: "",
    lastlogin: "1628526272749",
  },
  {
    lid: 13,
    Vorname: "Systembenutzer",
    Nachname: "Maschinensucher",
    Email: "maschinensucher-ergebnis@mh-schleswig.de",
    admin: 0,
    creator: 0,
    created: "",
    lastlogin: "",
  },
  { lid: 16, Vorname: "Jana", Nachname: "Cazacu", Email: "jc@mh-schleswig.de", admin: 0, creator: 0, created: "", lastlogin: "" },
  { lid: 17, Vorname: "Susanne", Nachname: "Fuchs", Email: "sf@mh-schleswig.de", admin: 0, creator: 0, created: "", lastlogin: "" },
  {
    lid: 21,
    Vorname: "Larissa",
    Nachname: "Wiesent",
    Email: "lw@mh-schleswig.de",
    admin: 0,
    creator: 0,
    created: "",
    lastlogin: "1689703463909",
  },
];
