import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { loadEmailvorlagen, deleteEmailvorlagen, getEmailPDF } from "../../../actions";
import { HeaderMenu } from "../../parts/HeaderMenu";
import { DataTable } from "primereact/datatable";
import { Emailvorlage } from "../../../types/remote/Emailvorlage";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { EmailvorlagenDialog } from "../../parts/EmailvorlagenDialog/EmailvorlagenDialog";
import { TestmailDialog } from "../../parts/TestmailDialog/TestmailDialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ResultToast } from "../../parts/ResultToast/ResultToast";

interface EmailVorlageProps {
  content: ContentState;
  loadEmailvorlagen: Function;
  deleteEmailvorlagen: Function;
  getEmailPDF: Function;
}

interface EmailVorlageState {
  addDialogOpen: boolean;
  testmail?: Emailvorlage;
}

class _EmailVorlage extends React.Component<EmailVorlageProps, EmailVorlageState> {
  constructor(props: EmailVorlageProps) {
    super(props);
    this.state = { addDialogOpen: false };
  }

  componentDidMount() {
    if (this.props.content.selectedCompany) {
      this.props.loadEmailvorlagen(this.props.content.selectedCompany);
    }
  }

  async getEmailPDF(element: Emailvorlage) {
    try {
      await this.props.getEmailPDF(element);
    } catch (ex) {
      console.log(ex);
    }
  }

  render() {
    return (
      <div className="card">
        <ConfirmDialog />
        <ResultToast successAction={() => this.props.loadEmailvorlagen(this.props.content.selectedCompany)} />
        <TestmailDialog close={() => this.setState({ ...this.state, testmail: undefined })} emailvorlage={this.state.testmail} />
        <EmailvorlagenDialog close={() => this.setState({ ...this.state, addDialogOpen: false })} open={this.state.addDialogOpen} />
        <HeaderMenu
          changeCompanyAction={() => this.props.loadEmailvorlagen(this.props.content.selectedCompany)}
          action={this.props.content.selectedCompany ? () => this.setState({ ...this.state, addDialogOpen: true }) : undefined}
        />
        <DataTable
          sortField="EVID"
          sortOrder={-1}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={
            this.props.content && this.props.content.emailvorlagen
              ? this.props.content.emailvorlagen.map((element: Emailvorlage) => {
                  return {
                    EVID: element.EVID,
                    name: element.Bezeichnung,
                    created: element.Created ? element.Created : "unbekannt",
                    creator: element.Creator ? `${element.Creator.Vorname} | ${element.Creator.Nachname}` : "unbekannt",
                    func: (
                      <div className="buttonSpacer">
                        <div className="p-1">
                          <Button
                            onClick={() => this.setState({ ...this.state, testmail: element })}
                            tooltip="Send Testmail"
                            icon="pi pi-send"
                            className="p-button-rounded p-button-warning"
                          />
                        </div>
                        <div className="p-1">
                          <Button
                            onClick={() => {
                              confirmDialog({
                                message: `Soll die Vorlage ${element.Bezeichnung} wirklich gelöscht werden?`,
                                header: "Delete Confirmation",
                                icon: "pi pi-info-circle",
                                acceptClassName: "p-button-danger",
                                accept: () => {
                                  this.props.deleteEmailvorlagen(element);
                                },
                              });
                            }}
                            tooltip="DELETE Emailvorlage"
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-error"
                          />
                        </div>
                      </div>
                    ),
                  };
                })
              : []
          }
        >
          <Column style={{ width: "10%" }} field="EVID" header="EVID"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="created" header="erstellt"></Column>
          <Column field="creator" header="Ersteller"></Column>
          <Column style={{ width: "100px" }} field="func" header="Aktionen"></Column>
        </DataTable>
        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

/*
<div className="p-1">
                          <Button
                            onClick={() => {
                              this.getEmailPDF(element);
                            }}
                            tooltip="Show Emailzugang"
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-info"
                          />
                        </div>
*/

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const EmailVorlage = connect(mapStateToProps, {
  loadEmailvorlagen,
  deleteEmailvorlagen,
  getEmailPDF,
})(_EmailVorlage);
