import React from "react";
import { connect } from "react-redux";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Anrede } from "../../../types/remote/Anrede";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import {
  addDataset,
  resetDataset,
  showMessage,
  changeDataset,
  deleteDataset,
  getDataset,
  changePage,
  resetVorgaenge,
} from "../../../actions";
import { Dataset } from "../../../types/remote/Dataset";
import { getEmptyDataset } from "../../../helper/helper";
import { Laenderliste } from "../../../types/local/Laenderliste";
import { CTextarea } from "../../parts/CustomTextarea";
import { ValidEnum } from "../../../types/local/ValidEnum";
import { checkTextLen, checkEmail, checkInternet, checkPhone } from "../../../helper/validate";
import { checkCopied } from "../../../helper/checkCopied";
import { ConfirmationDialog } from "../../parts/dialog/index";
import { Card } from "primereact/card";
import { isDatasetFormValid } from "./validationDataset";
import { Page } from "../../../types/local/pages";
import { RouteState } from "../../../reducers/router";
import { Fieldset } from "primereact/fieldset";

import "./style.css";
import { LoginState } from "../../../reducers/login";

interface DataEditorProps {
  route: RouteState;
  content: ContentState;
  showMessage: typeof showMessage;
  addDataset: Function;
  resetDataset: typeof resetDataset;
  changeDataset: Function;
  deleteDataset: Function;
  getDataset: Function;
  changePage: typeof changePage;
  resetVorgaenge: typeof resetVorgaenge;
  login: LoginState;
}

interface DataEditorState {
  dataset: Dataset;
  bearbeiten: boolean;
  showDeleteDialog: boolean;
}

class _DataEditor extends React.Component<DataEditorProps, DataEditorState> {
  constructor(props: DataEditorProps) {
    super(props);
    this.state = {
      bearbeiten: true,
      showDeleteDialog: false,
      dataset: getEmptyDataset(),
    };
  }

  saveRecord() {
    if (this.state.dataset.KID > 0) {
      this.props.changeDataset(this.state.dataset);
    } else {
      this.props.addDataset(this.state.dataset);
    }
    window.scrollTo(0, 0);
  }

  async componentDidMount() {
    if (!this.props.content.dataset) await this.props.getDataset();
    if (this.props.content.dataset) {
      //Fix some minor data issues
      if (this.props.content.dataset.Fax) {
        this.props.content.dataset.Fax = this.props.content.dataset.Fax.replace("'", "");
      }

      if (this.props.content.dataset.Tel) {
        this.props.content.dataset.Tel = this.props.content.dataset.Tel.replace("'", "");
      }

      if (this.props.content.dataset.Nachname && !this.props.content.dataset.Anrede) {
        this.props.content.dataset.Anrede = Anrede.KEINE_ANGABE;
      }

      if (this.props.content.dataset.Internet && !this.props.content.dataset.Internet.toLowerCase().startsWith("http")) {
        this.props.content.dataset.Internet = "https://" + this.props.content.dataset.Internet;
      }

      this.setState({ ...this.state, dataset: this.props.content.dataset });
    } else {
      this.setState({ ...this.state, dataset: getEmptyDataset() });
    }
  }

  componentDidUpdate(prevProps: DataEditorProps) {
    if (prevProps.content.dataset !== this.props.content.dataset && this.props.content.dataset) {
      this.setState({ ...this.state, dataset: this.props.content.dataset });
    }
  }

  componentWillUnmount() {
    this.props.resetDataset();
    this.props.resetVorgaenge();
  }

  reset = () => {
    if (this.state.dataset.KID > 0) {
      this.props.getDataset(this.state.dataset);
    } else {
      this.props.getDataset(getEmptyDataset());
    }
  };

  render() {
    return this.props.content.firmen.length === 0 ? (
      <Fieldset legend="Hinweis">Bitte fügen Sie erst eine Firma hinzu, bevor Sie Datensätze hinzufügen</Fieldset>
    ) : (
      <div>
        <div className="dataeditor-button-container">
          {this.props.route.page !== Page.NEW_DATASET && this.props.content.dataset && this.props.content.dataset.KID > 0 ? (
            <Button
              label={"Zurück"}
              severity="help"
              icon="pi pi-angle-left"
              onClick={() => {
                this.props.changePage(Page.OVERVIEW);
              }}
            />
          ) : undefined}
          {this.props.route.page !== Page.NEW_DATASET || this.state.dataset.KID === 0 ? (
            <Button
              label={this.state.dataset.KID === 0 ? "Datensatz hinfügen" : "Änderungen speichern"}
              className="p-button-success"
              icon="pi pi-save"
              disabled={!isDatasetFormValid(this.state.dataset)}
              onClick={() => {
                this.saveRecord();
              }}
            />
          ) : undefined}
          {this.props.content.dataset && this.props.route.page !== Page.NEW_DATASET && this.props.content.dataset.KID > 0 ? (
            <Button
              label={"Änderungen verwerfen"}
              className="p-button-danger"
              icon="pi pi-times"
              onClick={() => {
                this.reset();
              }}
            />
          ) : undefined}

          {this.props.route.page === Page.NEW_DATASET && this.state.dataset.KID !== 0 ? (
            <Button
              label={"Weiteren Datensatz hinzufügen"}
              severity="help"
              icon="pi pi-plus"
              onClick={() => {
                this.props.resetDataset();
                this.setState({
                  bearbeiten: true,
                  showDeleteDialog: false,
                  dataset: getEmptyDataset(),
                });
              }}
            />
          ) : undefined}

          {this.props.login.userData &&
          this.props.login.userData.admin &&
          this.props.content.dataset &&
          this.props.route.page !== Page.NEW_DATASET &&
          this.props.content.dataset.KID > 0 ? (
            <Button
              label={"Datasatz löschen"}
              className="p-button-danger"
              icon="pi pi-trash"
              onClick={() => {
                this.setState({ ...this.state, showDeleteDialog: true });
              }}
            />
          ) : undefined}
        </div>

        <ConfirmationDialog
          header="Datensatz wirklich löschen?"
          content={`Soll der Datensatz mit der Emailadresse ${this.state.dataset.Email} gelöscht werden?`}
          onClickYes={async () => {
            await this.props.deleteDataset(this.state.dataset);
            this.props.changePage(Page.OVERVIEW);
          }}
          onClickNo={() => {
            this.setState({
              ...this.state,
              showDeleteDialog: false,
            });
          }}
          yesCaption="Ja"
          noCaption="Nein"
          visible={this.state.showDeleteDialog}
        />
        <div className="grid">
          <div className="col-12 md:col-6">
            <Card>
              <div>
                <div className="grid">
                  <h3>Allgemeine Informationen</h3>
                </div>
                <div className="grid">
                  {" "}
                  <h4>
                    {this.state.dataset.KID === 0
                      ? "Neuer Datensatz (KID: wird neu vergeben"
                      : this.state.bearbeiten
                      ? `Bearbeite Datensatz (KID: ${this.state.dataset.KID}`
                      : `Datensatz anzeigen (KID: ${this.state.dataset.KID}`}
                    )
                  </h4>
                </div>

                <div className="grid">
                  <label htmlFor="inputtext" className="dataeditor-label">
                    Firma
                  </label>
                  <div className="p-inputgroup">
                    <span
                      className={
                        checkTextLen(this.state.dataset.Firmenname, 100, 0) === ValidEnum.VALID
                          ? "p-inputgroup-addon valid-addon "
                          : "p-inputgroup-addon invalid-addon"
                      }
                    >
                      <i className="pi pi-user"></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        value={this.state.dataset.Firmenname ? this.state.dataset.Firmenname : ""}
                        disabled={!this.state.bearbeiten}
                        onChange={(e: any) => {
                          let newState = Object.assign({}, this.state);
                          newState.dataset.Firmenname = checkCopied(newState.dataset.Firmenname, e.target.value);
                          this.setState(newState);
                        }}
                        className={
                          checkTextLen(this.state.dataset.Firmenname, 100, 0) === ValidEnum.VALID ? "valid-textfield" : "invalid-textfield"
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="grid">
                  <label htmlFor="inputtext" className="dataeditor-label">
                    Emailadresse
                  </label>
                  <div className="p-inputgroup">
                    <span
                      className={
                        checkEmail(this.state.dataset.Email) === ValidEnum.VALID
                          ? "p-inputgroup-addon valid-addon "
                          : "p-inputgroup-addon invalid-addon"
                      }
                    >
                      <i className="pi pi-envelope"></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        value={this.state.dataset.Email ? this.state.dataset.Email : ""}
                        disabled={!this.state.bearbeiten}
                        onChange={(e: any) => {
                          let newState = Object.assign({}, this.state);
                          newState.dataset.Email = checkCopied(this.state.dataset.Email, e.target.value);
                          this.setState(newState);
                        }}
                        className={checkEmail(this.state.dataset.Email) === ValidEnum.VALID ? "valid-textfield" : "invalid-textfield"}
                      />
                    </span>
                  </div>
                </div>
                <div className="grid">
                  <label className="dataeditor-label" htmlFor="inputtext">
                    Webseite (Format: http://www.test.de)
                  </label>
                  <div className="p-inputgroup">
                    <span
                      className={
                        checkInternet(this.state.dataset.Internet) !== ValidEnum.NOT_SET
                          ? checkInternet(this.state.dataset.Internet) !== ValidEnum.INVALID
                            ? "p-inputgroup-addon valid-addon "
                            : "p-inputgroup-addon invalid-addon"
                          : "p-inputgroup-addon"
                      }
                    >
                      <i className="pi pi-success pi-globe"></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        value={this.state.dataset.Internet ? this.state.dataset.Internet : ""}
                        disabled={!this.state.bearbeiten}
                        onChange={(e: any) => {
                          let newState = Object.assign({}, this.state);
                          newState.dataset.Internet = checkCopied(this.state.dataset.Internet, e.target.value);
                          this.setState(newState);
                        }}
                        className={
                          checkInternet(this.state.dataset.Internet) !== ValidEnum.NOT_SET
                            ? checkInternet(this.state.dataset.Internet) !== ValidEnum.INVALID
                              ? "valid-textfield"
                              : "invalid-textfield"
                            : ""
                        }
                      />
                    </span>
                  </div>
                </div>

                <div className="grid">
                  <label className="dataeditor-label" htmlFor="inputtext">
                    Faxnummer (Format: +4952511234)
                  </label>
                  <div className="p-inputgroup">
                    <span
                      className={
                        this.state.dataset.KID > 0 && checkPhone(this.state.dataset.Fax) !== ValidEnum.NOT_SET
                          ? checkPhone(this.state.dataset.Fax) !== ValidEnum.INVALID
                            ? "p-inputgroup-addon valid-addon "
                            : "p-inputgroup-addon invalid-addon"
                          : "p-inputgroup-addon"
                      }
                    >
                      <i className="pi pi-success pi-globe"></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        value={this.state.dataset.Fax ? this.state.dataset.Fax : ""}
                        disabled={!this.state.bearbeiten}
                        onChange={(e: any) => {
                          let newState = Object.assign({}, this.state);
                          newState.dataset.Fax = checkCopied(this.state.dataset.Fax, e.target.value);
                          this.setState(newState);
                        }}
                        className={
                          this.state.dataset.KID > 0 && checkPhone(this.state.dataset.Fax) !== ValidEnum.NOT_SET
                            ? checkPhone(this.state.dataset.Fax) !== ValidEnum.INVALID
                              ? "valid-textfield"
                              : "invalid-textfield"
                            : ""
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 md:col-6">
            <Card>
              <div className="grid">
                <h3>Ansprechpartner</h3>
              </div>

              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Anrede
                </label>
                <div className="p-inputgroup">
                  <Dropdown
                    options={[
                      { label: "Herr", value: "Herr" },
                      { label: "Frau", value: "Frau" },
                      { label: "Keine_Angabe", value: "Keine_Angabe" },
                    ]}
                    value={this.state.dataset.Anrede}
                    disabled={!this.state.bearbeiten}
                    onChange={(e: any) => {
                      let newState = Object.assign({}, this.state);
                      newState.dataset.Anrede = e.value;
                      this.setState(newState);
                    }}
                    placeholder="Bitte wählen"
                    className={
                      checkTextLen(this.state.dataset.Nachname, 50, 2) !== ValidEnum.NOT_SET
                        ? this.state.dataset.Anrede === Anrede.FRAU ||
                          this.state.dataset.Anrede === Anrede.HERR ||
                          this.state.dataset.Anrede === Anrede.KEINE_ANGABE
                          ? "valid-textfield"
                          : "invalid-textfield"
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Vorname
                </label>
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <InputText
                      value={this.state.dataset.Vorname ? this.state.dataset.Vorname : ""}
                      disabled={!this.state.bearbeiten}
                      onChange={(e: any) => {
                        let newState = Object.assign({}, this.state);
                        newState.dataset.Vorname = checkCopied(this.state.dataset.Vorname, e.target.value);
                        this.setState(newState);
                      }}
                      className={
                        checkTextLen(this.state.dataset.Vorname, 50, 2) !== ValidEnum.NOT_SET
                          ? checkTextLen(this.state.dataset.Vorname, 50, 2) !== ValidEnum.INVALID
                            ? "valid-textfield"
                            : "invalid-textfield"
                          : ""
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Nachname
                </label>
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <InputText
                      value={this.state.dataset.Nachname ? this.state.dataset.Nachname : ""}
                      disabled={!this.state.bearbeiten}
                      onChange={(e: any) => {
                        let newState = Object.assign({}, this.state);
                        newState.dataset.Nachname = checkCopied(this.state.dataset.Nachname, e.target.value);
                        this.setState(newState);
                      }}
                      className={
                        checkTextLen(this.state.dataset.Nachname, 50, 2) !== ValidEnum.NOT_SET
                          ? checkTextLen(this.state.dataset.Nachname, 50, 2) !== ValidEnum.INVALID
                            ? "valid-textfield"
                            : "invalid-textfield"
                          : ""
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Telefonnummer (Format: +4952511234)
                </label>
                <div className="p-inputgroup">
                  <span
                    className={
                      checkPhone(this.state.dataset.Tel) !== ValidEnum.NOT_SET
                        ? checkPhone(this.state.dataset.Tel) !== ValidEnum.INVALID
                          ? "p-inputgroup-addon valid-addon "
                          : "p-inputgroup-addon invalid-addon"
                        : "p-inputgroup-addon"
                    }
                  >
                    <i className="pi pi-success pi-mobile"></i>
                  </span>
                  <span className="p-float-label">
                    <InputText
                      value={this.state.dataset.Tel ? this.state.dataset.Tel : ""}
                      disabled={!this.state.bearbeiten}
                      onChange={(e: any) => {
                        let newState = Object.assign({}, this.state);
                        newState.dataset.Tel = checkCopied(this.state.dataset.Tel, e.target.value);
                        this.setState(newState);
                      }}
                      className={
                        checkPhone(this.state.dataset.Tel) !== ValidEnum.NOT_SET
                          ? checkPhone(this.state.dataset.Tel) !== ValidEnum.INVALID
                            ? "valid-textfield"
                            : "invalid-textfield"
                          : ""
                      }
                    />
                  </span>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="grid">
          <div className="col-12 md:col-6">
            <Card>
              <div className="grid">
                <h3>Anschrift</h3>
              </div>
              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Straße / Hausnummer{" "}
                </label>
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <InputText
                      value={this.state.dataset.strasse ? this.state.dataset.strasse : ""}
                      disabled={!this.state.bearbeiten}
                      onChange={(e: any) => {
                        let newState = Object.assign({}, this.state);
                        newState.dataset.strasse = checkCopied(this.state.dataset.strasse, e.target.value);
                        this.setState(newState);
                      }}
                      className={
                        checkTextLen(this.state.dataset.strasse, 50, 2) !== ValidEnum.NOT_SET
                          ? checkTextLen(this.state.dataset.strasse, 50, 2) !== ValidEnum.INVALID
                            ? "valid-textfield"
                            : "invalid-textfield"
                          : ""
                      }
                    />
                  </span>
                </div>
              </div>
              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Postleitzahl
                </label>
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <InputText
                      value={this.state.dataset.plz ? this.state.dataset.plz : ""}
                      disabled={!this.state.bearbeiten}
                      onChange={(e: any) => {
                        let newState = Object.assign({}, this.state);
                        newState.dataset.plz = checkCopied(this.state.dataset.plz, e.target.value);
                        this.setState(newState);
                      }}
                      className={
                        checkTextLen(this.state.dataset.plz, 10, 3) !== ValidEnum.NOT_SET
                          ? checkTextLen(this.state.dataset.plz, 10, 3) !== ValidEnum.INVALID
                            ? "valid-textfield"
                            : "invalid-textfield"
                          : ""
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Ort
                </label>
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <InputText
                      value={this.state.dataset.Ort ? this.state.dataset.Ort : ""}
                      disabled={!this.state.bearbeiten}
                      onChange={(e: any) => {
                        let newState = Object.assign({}, this.state);
                        newState.dataset.Ort = checkCopied(this.state.dataset.Ort, e.target.value);
                        this.setState(newState);
                      }}
                      className={
                        checkTextLen(this.state.dataset.Ort, 110, 2) !== ValidEnum.NOT_SET
                          ? checkTextLen(this.state.dataset.Ort, 150, 2) !== ValidEnum.INVALID
                            ? "valid-textfield"
                            : "invalid-textfield"
                          : ""
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="grid">
                <label className="dataeditor-label" htmlFor="inputtext">
                  Land
                </label>
                <div className="p-inputgroup">
                  <Dropdown
                    options={Laenderliste}
                    value={this.state.dataset.land ? this.state.dataset.land : ""}
                    disabled={!this.state.bearbeiten}
                    onChange={(e) => {
                      let newState = Object.assign({}, this.state);
                      newState.dataset.land = e.value;
                      this.setState(newState);
                    }}
                    placeholder="Bitte Land wählen"
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 md:col-6">
            <Card>
              <h3>Bemerkungen</h3>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-8">
                  <CTextarea
                    maxLength={300}
                    rows={5}
                    cols={30}
                    showLine={this.state.bearbeiten}
                    value={this.state.dataset.Bemerkung ? this.state.dataset.Bemerkung : ""}
                    disabled={!this.state.bearbeiten}
                    onChange={(result: string) => {
                      let newState = Object.assign({}, this.state);
                      newState.dataset.Bemerkung = checkCopied(this.state.dataset.Bemerkung, result);
                      this.setState(newState);
                    }}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="uebersicht-spacer"></div>
      </div>
    );
  }
}

let mapStateToProps = ({ content, route, login }: StoreState): { content: ContentState; route: RouteState; login: LoginState } => {
  return { content, route, login };
};

export const DataEditor = connect(mapStateToProps, {
  addDataset,
  resetDataset,
  changeDataset,
  showMessage,
  deleteDataset,
  getDataset,
  changePage,
  resetVorgaenge,
})(_DataEditor);
