import { Dispatch } from "redux";
import { updateResultCode } from ".";
import { Actions, mainaxios } from "..";
import { config } from "../../config/config";
import { resolveFailure } from "../../helper/failureProcedure";
import { Dataset, ResultObj } from "../../types/remote";
import { Vorgang } from "../../types/remote/Vorgang";
import { VorgangSearchOptions, VORGANG_TYPES } from "../../types/remote/VorgangSearchOptions";
import { changeLoadScreen } from "../router";

export interface LoadVorgaengeAction {
  type: Actions.CONTENT_LOAD_VORGAENGE;
  data?: Vorgang[];
}

export const resetVorgaenge = (): LoadVorgaengeAction => {
  return { type: Actions.CONTENT_LOAD_VORGAENGE };
};

export const loadVorgaengeKAPID = (start: Date, limit: number, vorgangstyp: VORGANG_TYPES) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      const query: VorgangSearchOptions = { start: start.getTime(), vorgangstyp: vorgangstyp, limit: limit };

      let response = await mainaxios.post<ResultObj<Vorgang[]>>(`${config.backendUrl}/v1/vorgaenge/kampagne`, query);

      dispatch({ type: Actions.CONTENT_LOAD_VORGAENGE, data: response.data.data });
      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const addVorgang = (dataset: Dataset, vorgang: Vorgang) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      let response = await mainaxios.post<ResultObj<Vorgang>>(`${config.backendUrl}/v1/vorgaenge`, { vorgang: vorgang, dataset: dataset });

      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const changeVorgang = (vorgang: Vorgang) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      let response = await mainaxios.put<ResultObj<Vorgang>>(`${config.backendUrl}/v1/vorgaenge`, vorgang);

      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const deleteVorgang = (vorgang: Vorgang) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      let response = await mainaxios.delete<ResultObj<string>>(`${config.backendUrl}/v1/vorgaenge/${vorgang.VID}`);

      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};

export const cleanupVorgange = (time: Date) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeLoadScreen(true));
    try {
      let response = await mainaxios.delete<ResultObj<string>>(`${config.backendUrl}/v1/vorgaenge/cleanup/${time.getTime()}`);

      dispatch(changeLoadScreen(false));
      dispatch(updateResultCode(response.data.rc));
    } catch (exx) {
      dispatch(changeLoadScreen(false));
      resolveFailure(dispatch, exx);
    }
  };
};
