import {
  Actions,
  SucheSetCollectionAction,
  SucheSetSearchTextAction,
  SucheSetPageAction,
  SucheSetLimitAction,
  LoadCollectionAction,
  AddCollectionAction,
  ResetCollectionAction,
  ChangeDatasetAction,
  DeleteDatasetAction,
  DeleteCollectionAction,
  LoadFirmenAction,
  LoadEmailvorlagenAction,
  UpdateResultCodeAction,
  SelectedCompanyAction,
  LoadImportsAction,
  LogoutAction,
  CheckEmailCheckerStatusAction,
} from "../../actions";
import { LoadContentAction, AddDatasetAction, OpenDatasetAction, ResetDatasetAction } from "../../actions";
import { LoadBenutzerAction } from "../../actions/content/benutzer";
import { LoadVorgaengeAction } from "../../actions/content/vorgaenge";
import { Benutzer, Collection, Dataset, Uebersicht } from "../../types/remote";
import { EMAILCheckerStatus } from "../../types/remote/emailCheckerStatus";
import { Emailvorlage } from "../../types/remote/Emailvorlage";
import { Emailzugang } from "../../types/remote/Emailzugang";
import { Firma } from "../../types/remote/Firma";
import { Import } from "../../types/remote/import";
import { Suche } from "../../types/remote/Suche";
import { Vorgang } from "../../types/remote/Vorgang";

export interface ContentState {
  selectedCompany?: Firma;
  uebersicht: Uebersicht;
  suche: Suche;
  collections: Collection[];
  dataset?: Dataset;
  collection?: Collection;
  benutzer: Benutzer[];
  firmen: Firma[];
  emailvorlagen: Emailvorlage[];
  emailzugaenge: Emailzugang[];
  imports: Import[];
  vorgaenge: Vorgang[];
  lastResultCode: { rc: number };
  EMAILCheckerStatus?: EMAILCheckerStatus;
}

function getDefaultState(): ContentState {
  return {
    uebersicht: { data: [], amountOfAllRecords: 0 },
    suche: { search: "", currentPage: 0, rowsPerView: 100 },
    collections: [],
    benutzer: [],
    firmen: [],
    emailvorlagen: [],
    emailzugaenge: [],
    imports: [],
    vorgaenge: [],
    lastResultCode: { rc: -1 },
  };
}

export const ContentReducer = (
  state: ContentState = getDefaultState(),
  action:
    | SelectedCompanyAction
    | LoadContentAction
    | SucheSetCollectionAction
    | SucheSetSearchTextAction
    | SucheSetPageAction
    | SucheSetLimitAction
    | LoadCollectionAction
    | LoadBenutzerAction
    | LoadFirmenAction
    | AddDatasetAction
    | ChangeDatasetAction
    | DeleteDatasetAction
    | OpenDatasetAction
    | ResetDatasetAction
    | AddCollectionAction
    | DeleteCollectionAction
    | LoadEmailvorlagenAction
    | ResetCollectionAction
    | UpdateResultCodeAction
    | LoadImportsAction
    | LoadVorgaengeAction
    | LogoutAction
    | CheckEmailCheckerStatusAction
): ContentState => {
  if (action.type === Actions.CONTENT_LOAD_CONTENT) {
    let newState = Object.assign({}, state);
    if (action.data) {
      newState.uebersicht = action.data;
    }
    return newState;
  } else if (action.type === Actions.CONTENT_LOAD_COLLECTIONS) {
    let newState = Object.assign({}, state);
    if (action.data && Array.isArray(action.data)) {
      newState.collections = action.data;
    }
    return newState;
  } else if (action.type === Actions.CONTENT_LOAD_EMAILVORLAGEN) {
    let newState = Object.assign({}, state);
    if (action.data && Array.isArray(action.data)) {
      newState.emailvorlagen = action.data;
    }
    return newState;
  } else if (action.type === Actions.CONTENT_LOAD_BENUTZER) {
    let newState = Object.assign({}, state);
    if (action.data) {
      newState.benutzer = action.data;
    }
    return newState;
  } else if (action.type === Actions.CONTENT_LOAD_FIRMEN) {
    let newState = Object.assign({}, state);
    if (action.data) {
      newState.firmen = action.data;

      if (!state.selectedCompany && action.data.length > 0) {
        newState.selectedCompany = action.data[0];
      } else if (state.selectedCompany && action.data.length > 0) {
        action.data.forEach((f) => {
          if (state.selectedCompany && f.FID === state.selectedCompany.FID) {
            newState.selectedCompany = f;
          }
        });
      }
    }
    return newState;
  } else if (
    action.type === Actions.CONTENT_ADD_DATASET ||
    action.type === Actions.CONTENT_CHANGE_DATASET ||
    action.type === Actions.CONTENT_DELETE_DATASET
  ) {
    let newState = Object.assign({}, state);
    if (action.data) {
      newState.dataset = action.data;
    }
    return newState;
  } else if (action.type === Actions.OPEN_DATASET) {
    let newState = Object.assign({}, state);
    if (action.data) {
      newState.dataset = action.data;
      newState.vorgaenge = action.data.vorgang ? action.data.vorgang : [];
    }
    return newState;
  } else if (action.type === Actions.RESET_DATASET) {
    let newState = Object.assign({}, state);

    newState.dataset = undefined;

    return newState;
  } else if (action.type === Actions.RESET_COLLECTION) {
    let newState = Object.assign({}, state);

    newState.collection = undefined;

    return newState;
  } else if (action.type === Actions.CONTENT_ADD_COLLECTION || action.type === Actions.CONTENT_DELETE_COLLECTION) {
    let newState = Object.assign({}, state);
    newState.collection = action.data;
    return newState;
  } else if (action.type === Actions.SUCHE_SET_COLLECTION) {
    let newState = Object.assign({}, state);
    newState.suche.currentPage = 0;
    if (action.data) {
      newState.suche.collection = action.data;
    } else {
      newState.suche.collection = undefined;
    }
    return newState;
  } else if (action.type === Actions.SUCHE_SET_KEYWORD) {
    let newState = Object.assign({}, state);
    newState.suche.search = action.data;
    newState.suche.currentPage = 0;
    return newState;
  } else if (action.type === Actions.SUCHE_SET_PAGE) {
    let newState = Object.assign({}, state);
    newState.suche.currentPage = action.data;
    return newState;
  } else if (action.type === Actions.SUCHE_SET_LIMIT) {
    let newState = Object.assign({}, state);
    newState.suche.rowsPerView = action.data;
    return newState;
  } else if (action.type === Actions.UPDATE_RESULTCODE) {
    let newState = Object.assign({}, state);
    newState.lastResultCode = action.data;
    return newState;
  } else if (action.type === Actions.LOCAL_SET_COMPANY) {
    let newState = Object.assign({}, state);
    newState.selectedCompany = action.data;
    return newState;
  } else if (action.type === Actions.CONTENT_LOAD_IMPORTS) {
    let newState = Object.assign({}, state);
    if (action.data) newState.imports = action.data;
    return newState;
  } else if (action.type === Actions.CONTENT_LOAD_VORGAENGE) {
    let newState = Object.assign({}, state);
    if (action.data) newState.vorgaenge = action.data;
    else newState.vorgaenge = [];
    return newState;
  } else if (action.type === Actions.CONTENT_LOAD_EMAILCHECKERSTATUS) {
    let newState = Object.assign({}, state);
    newState.EMAILCheckerStatus = action.data;
    return newState;
  } else if (action.type === Actions.LOGOUT) {
    return getDefaultState();
  }
  return state;
};
