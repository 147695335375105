import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { loadEmailvorlagen, mutateKampagne } from "../../../actions";
import { Button } from "primereact/button";
import { Kampagne } from "../../../types/remote/Kampagne";
import { createKampagne } from "../../../helper/kampagneHelper";

import "./KampagneDialog.css";

interface KampagneDialogProps {
  kampagne?: Kampagne;
  close: Function;
  content: ContentState;
  loadEmailvorlagen: Function;
  mutateKampagne: Function;
}

interface KampagneDialogState {
  kampagne: Kampagne;
}

export class _KampagneDialog extends React.Component<KampagneDialogProps, KampagneDialogState> {
  constructor(props: KampagneDialogProps) {
    super(props);

    this.state = { kampagne: createKampagne() };
  }

  componentDidUpdate(prevProps: KampagneDialogProps) {
    //On Open
    if (!prevProps.kampagne && this.props.kampagne) {
      this.props.loadEmailvorlagen(this.props.kampagne.firma);
      //Copy Object
      this.setState({ kampagne: { ...this.props.kampagne } });
    }

    //Reset on close
    if (prevProps.kampagne && !this.props.kampagne) {
      this.setState({
        kampagne: createKampagne(),
      });
    }
  }

  isValid() {
    if (this.state.kampagne.emailvorlagen.length > 0 && this.state.kampagne.name.length > 0) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <div className="card flex justify-content-center"></div>
        <Dialog
          position="top"
          footer={
            <div>
              <Button
                label="Abbrechen"
                icon="pi pi-times"
                onClick={() => {
                  this.props.close();
                }}
                className="p-button-text"
                autoFocus
              />
              <Button
                disabled={!this.isValid()}
                label={this.state.kampagne.KAPID >= 0 ? "Ändern" : "Anlegen"}
                icon="pi pi-check"
                onClick={() => {
                  this.props.mutateKampagne(this.state.kampagne);
                  this.props.close();
                }}
              />
            </div>
          }
          header={
            this.props.kampagne && this.props.kampagne.firma.FID > 0
              ? `Kampagne anlegen für ${this.props.kampagne.firma.Kurzform}`
              : undefined
          }
          visible={this.props.kampagne !== undefined}
          onHide={() => this.props.close()}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          {this.props.kampagne && this.props.kampagne.firma.FID > 0 ? (
            <div>
              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Name der Kampagne
                </label>

                <div className="field col-12 md:col-7">
                  <InputText
                    disabled={this.state.kampagne.KAPID >= 0}
                    value={this.state.kampagne.name}
                    type="text"
                    onChange={(e: any) => {
                      this.setState({ ...this.state, kampagne: { ...this.state.kampagne, name: e.target.value } });
                    }}
                    className={`${this.state.kampagne.name.length <= 1 ? "p-invalid" : ""} w-full`}
                    id="kampagne-name"
                    maxLength={50}
                    placeholder="Name der Kampagne"
                  />
                </div>
              </div>
              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Collection (optional)
                </label>

                <div className="field col-12 md:col-7">
                  <Dropdown
                    disabled={this.state.kampagne.KAPID >= 0}
                    id="collection"
                    className="w-full"
                    onChange={(e: any) => {
                      this.setState({ ...this.state, kampagne: { ...this.state.kampagne, collection: e.value } });
                    }}
                    value={this.state.kampagne.collection}
                    optionLabel="name"
                    placeholder="Collection auswählen"
                    options={this.props.content.collections}
                  />
                </div>
              </div>

              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Emailvorlagen
                </label>

                <div className="field col-12 md:col-7">
                  <MultiSelect
                    disabled={this.state.kampagne.KAPID >= 0}
                    value={this.state.kampagne.emailvorlagen}
                    options={this.props.content.emailvorlagen}
                    onChange={(e: any) => {
                      this.setState({ ...this.state, kampagne: { ...this.state.kampagne, emailvorlagen: e.value } });
                    }}
                    className={`${this.state.kampagne.emailvorlagen.length === 0 ? "p-invalid" : ""} w-full`}
                    maxSelectedLabels={3}
                    optionLabel="Bezeichnung"
                    placeholder="Emailvorlagen auswählen"
                  />
                </div>
              </div>

              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Mails pro Durchlauf{" "}
                </label>

                <div className="field col-12 md:col-7">
                  <InputNumber
                    className="w-full"
                    value={this.state.kampagne.amountOfMailsInOneRun}
                    onChange={(e: any) => {
                      this.setState({ ...this.state, kampagne: { ...this.state.kampagne, amountOfMailsInOneRun: e.value } });
                    }}
                    mode="decimal"
                    showButtons
                    min={1}
                    max={60}
                  />
                </div>
              </div>

              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Wartezeit pro Durchlauf (in s.)
                </label>

                <div className="field col-12 md:col-7">
                  <InputNumber
                    value={this.state.kampagne.delayBetweenMailsInSec}
                    onChange={(e: any) => {
                      this.setState({ ...this.state, kampagne: { ...this.state.kampagne, delayBetweenMailsInSec: e.value } });
                    }}
                    mode="decimal"
                    className="w-full"
                    showButtons
                    min={1}
                    max={10000}
                  />
                </div>
              </div>

              <div className="field grid">
                <label htmlFor="kampagne-name" className="field col-12 md:col-5 labelcenter">
                  Direkt starten?
                </label>

                <div className="field col-12 md:col-7">
                  <SelectButton
                    disabled={this.state.kampagne.KAPID >= 0}
                    value={this.state.kampagne.direktAnlegen ? "Ja" : "Nein"}
                    onChange={(e: any) => {
                      const res = e.value === "Ja" ? true : false;
                      this.setState({ ...this.state, kampagne: { ...this.state.kampagne, direktAnlegen: res } });
                    }}
                    options={["Ja", "Nein"]}
                  />
                </div>
              </div>
            </div>
          ) : (
            <p> Bitte erst eine Firma auswählen!</p>
          )}
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const KampagneDialog = connect(mapStateToProps, { loadEmailvorlagen, mutateKampagne })(_KampagneDialog);
