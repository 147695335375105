import { Toast } from "primereact/toast";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ContentState } from "../../../reducers/content";

interface ResultToastProps {
  content: ContentState;
  successAction?: Function;
}

export class _ResultToast extends React.Component<ResultToastProps> {
  private toast: any | undefined;

  constructor(props: ResultToastProps) {
    super(props);
    this.toast = React.createRef();
  }

  componentDidUpdate(prevProps: ResultToastProps) {
    //Check update result
    if (prevProps.content.lastResultCode !== this.props.content.lastResultCode) {
      if (this.props.successAction) {
        this.props.successAction();
      }

      if (this.props.content.lastResultCode.rc === 0) {
        this.toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Aktion erfolgreich durchgeführt",
          life: 5000,
        });
      }
    }
  }

  render() {
    return <Toast ref={this.toast} />;
  }
}

let mapStateToProps = ({ content }: StoreState): { content: ContentState } => {
  return { content };
};

export const ResultToast = connect(mapStateToProps, {})(_ResultToast);
